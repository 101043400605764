import moment from "moment";
export const getTotalPointUScore = (scores, scoringPosts, userId) => {
    let totalTemp = 0
    scoringPosts.forEach(p => {
        const date1 = moment().toDate();
        const date2 = moment(p?.scoreStartTime).toDate();
        if (date1 > date2) {
            if (p?.scoreBy?.includes("likes")) {
                totalTemp += p?.scorePoint
            }
            if (p?.scoreBy?.includes("verification")) {
                totalTemp += (p?.scorePoint1 * p?.scoreCount)
            }
            if (p?.scoreBy?.includes("geo")) {
                totalTemp += p?.scorePoint2
            }
            if (p?.postType1 == "quiz") {
                totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
            }
        }
    })
    scores.filter(x => x.user?._id == userId).forEach(x => {
        if (x?.type == 4) {
            totalTemp += x?.point
        }
    })
    console.log("nona", totalTemp, scores, scoringPosts)
    return totalTemp
}


export const dailyUserCanScorePoint = (scores, scoringPosts, userId) => {
    let filterOutScoringPost = scoringPosts.filter(x => {
        let date1 = moment().toDate()
        let date2 = moment(x?.scoreStartTime).toDate()
        let date3 = moment(x?.scoreEndTime).toDate()
        if (date1 >= date2 && date1 <= date3) {
            return true
        }
        return false
    })
    let manuals = scores.filter(x => {
        if (x?.type == 4 || x?.type == 5) {
            let date1 = moment().format("YYYY-MM-DD")
            let date2 = moment(x?.createdAt).format("YYYY-MM-DD")
            if (date1 == date2) {
                return true
            }
            return false
        }
    })
    let totalTemp = 0
    filterOutScoringPost.forEach(p => {
        if (moment(p?.scoreStartTime).toDate() > moment().toDate()) {

        } else {
            if (p?.scoreBy?.includes("likes")) {
                totalTemp += p?.scorePoint
            }
            if (p?.scoreBy?.includes("verification")) {
                totalTemp += (p?.scorePoint1 * p?.scoreCount)
            }
            if (p?.scoreBy?.includes("geo")) {
                totalTemp += p?.scorePoint2
            }
            if (p?.postType1 == "quiz") {
                totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
            }
        }
    })


    manuals.filter(x => x.user?._id == userId).forEach(x => {
        if (x?.type == 4) {
            totalTemp += x?.point
        }
    })
    return totalTemp
}


export const weeklyUserCanScorePoint = (scores, scoringPosts, userId) => {
    let filterOutScoringPost = scoringPosts.filter(x => {
        moment.updateLocale('en', {
            week: {
                dow: 1
            }
        });
        let weekStart = moment().startOf('week')
        let weekEnd = moment().endOf('week')
        let startDate = moment(x?.scoreStartTime)
        let endDate = moment(x?.scoreEndTime)
        if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
            return true
        }
        return false
    })
    let manuals = scores.filter(x => {
        if (x?.type == 4 || x?.type == 5) {
            let weekStart = moment().startOf('week')
            let weekEnd = moment().endOf('week')
            let startDate = moment(x?.createdAt)
            if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd))) {
                return true
            }
            return false
        }
    })
    let totalTemp = 0
    filterOutScoringPost.forEach(p => {
        if (moment(p?.scoreStartTime).toDate() > moment().toDate()) {

        } else {
            if (p?.scoreBy?.includes("likes")) {
                totalTemp += p?.scorePoint
            }
            if (p?.scoreBy?.includes("verification")) {
                totalTemp += (p?.scorePoint1 * p?.scoreCount)
            }
            if (p?.scoreBy?.includes("geo")) {
                totalTemp += p?.scorePoint2
            }
            if (p?.postType1 == "quiz") {
                totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
            }
        }
    })


    manuals.filter(x => x.user?._id == userId).forEach(x => {
        if (x?.type == 4) {
            totalTemp += x?.point
        }
    })
    return totalTemp
}


export const monthlyUserCanScorePoint = (scores, scoringPosts, userId) => {
    let filterOutScoringPost = scoringPosts.filter(x => {
        let weekStart = moment().startOf('month')
        let weekEnd = moment().endOf('month')
        let startDate = moment(x?.scoreStartTime)
        let endDate = moment(x?.scoreEndTime)
        if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
            return true
        }
        return false
    })
    let manuals = scores.filter(x => {
        if (x?.type == 4 || x?.type == 5) {
            let weekStart = moment().startOf('month')
            let weekEnd = moment().endOf('month')
            let startDate = moment(x?.createdAt)
            if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd))) {
                return true
            }
            return false
        }
    })
    let totalTemp = 0
    filterOutScoringPost.forEach(p => {
        if (moment(p?.scoreStartTime).toDate() > moment().toDate()) {

        } else {
            if (p?.scoreBy?.includes("likes")) {
                totalTemp += p?.scorePoint
            }
            if (p?.scoreBy?.includes("verification")) {
                totalTemp += (p?.scorePoint1 * p?.scoreCount)
            }
            if (p?.scoreBy?.includes("geo")) {
                totalTemp += p?.scorePoint2
            }
            if (p?.postType1 == "quiz") {
                totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
            }
        }
    })


    manuals.filter(x => x.user?._id == userId).forEach(x => {
        if (x?.type == 4) {
            totalTemp += x?.point
        }
    })
    return totalTemp
}


export const getMiscTotalCanPoint = (scores, scoringPosts, userId, sdate, edate) => {
    let filterOutScoringPost = scoringPosts.filter(x => {
        let weekStart = moment(sdate)
        let weekEnd = moment(edate)
        let startDate = moment(x?.scoreStartTime)
        let endDate = moment(x?.scoreEndTime)
        if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
            return true
        }
        return false
    })
    let manuals = scores.filter(x => {
        if (x?.type == 4 || x?.type == 5) {
            let date1 = moment().format("YYYYMMDD")
            let date2 = moment(x?.createdAt).format("YYYYMMDD")
            if (date1 == date2) {
                return true
            }
            return false
        }
    })
    let totalTemp = 0
    filterOutScoringPost.forEach(p => {
        if (moment(p?.scoreStartTime).toDate() > moment().toDate()) {

        } else {
            if (p?.scoreBy?.includes("likes")) {
                totalTemp += p?.scorePoint
            }
            if (p?.scoreBy?.includes("verification")) {
                totalTemp += (p?.scorePoint1 * p?.scoreCount)
            }
            if (p?.scoreBy?.includes("geo")) {
                totalTemp += p?.scorePoint2
            }
            if (p?.postType1 == "quiz") {
                totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
            }
        }
    })


    manuals.filter(x => x.user?._id == userId).forEach(x => {
        if (x?.type == 4) {
            totalTemp += x?.point
        }
    })
    return totalTemp
}