import { Box, Button, Typography } from "@mui/material";
import Input from "../components/Login/Input";
import { pxToRem } from "../theme/typography";
import '../css/signup.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import showToast from "../utils/showToast";
import hit from "../services/apiManager";
import { auth } from "../services/endpoints";
import { setLoading } from "../store/load";
import { useState } from "react";

export default function NewPassword() {
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const {token} = useLocation().state;
    const [pass, setPass] = useState("")
    const [cpass, setCpass] = useState("")
    const submit = async () => {
        if (pass != cpass) {
            showToast("Password does not match.","warning")
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(`${auth.resetpass}?token=${token}`, "post", { "password": pass })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                navigate("/success")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ width: "100vw", position: "relative", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box component={"img"} src="assets/login/fullback.png" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box component={"img"} src="assets/login/fullback1.svg" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box className="noScrollBar" sx={{ width: { xs: "90vw", md: "40vw" }, overflowY: "auto", scrollbarWidth: "none", minWidth: { md: "550px" }, borderRadius: "10px", position: "relative", backgroundImage: "linear-gradient(150deg,rgb(212,241,247), white ,rgb(254,243,214))" }}>
                <Box sx={{ height: "100%", width: "100%", paddingY: "7%", paddingX:{xs:"5%",md: "12%"}, position: "relative" }}>
                    <Box component={"img"} src="assets/logo.png" sx={{ width: { md: "200px", sm: "200px" }, mx: "auto", height: "60px", objectFit: "contain" }} />
                    <Typography sx={{ fontSize: pxToRem(26), fontWeight: "700", marginTop: "10px" }}>Verification</Typography>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "500", marginTop: "4px",  }}>Please enter your new password below.</Typography>

                    <br />
                    <Box sx={{ width: "90%", marginX: "auto" }}>
                        <Input isPass={true} value={pass} onChange={v=>setPass(v)} titleStyle={{ textAlign: "left" }} title={"Password"} img="assets/login/mail.svg" />
                        <br />
                        <Input isPass={true} value={cpass} onChange={v=>setCpass(v)} titleStyle={{ textAlign: "left" }} title={"Confirm Password"} img="assets/login/mail.svg" />
                        <br />
                        <br />
                        <Button onClick={() => {
                                submit()
                        }} sx={{ borderRadius: "5px", height: "50px", marginTop: "16px", width: "80%" }} variant="contained" >
                            Continue
                        </Button>
                        <br />
                    </Box>
                </Box>
                <Box onClick={() => {
                    navigate(-1)
                }} component={"img"} src="assets/arrow-left.png" style={{ height: "25px", width: "25px", position: "absolute", top: 20, left: 20 }} />
            </Box>
        </Box >
    )
}

