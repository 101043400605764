import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSelector } from "react-redux";
import { pxToRem } from "../../theme/typography";


export default function PointsComp({style,i, j, admin, adminView}) {
    const user = useSelector(state => state?.auth?.user);
    let point = i?.point
    let type = i?.type == 1 ? "like" : i?.type == 2 ? "geo" : i?.type == 4 ? "manually" : i?.type == 5 ? "quiz" : "verification"
    let txt = i?.postId?.txt
    // let userName = (admin ? i?.user?.firstName : "You")
    let userName = (admin ? (adminView == "User" ? "You" : `${i?.user?.firstName} ${i?.user?.lastName}`) : "You")
    if ((adminView == "User") && (i?.user?._id != user?._id)) {
        return (
            <></>
        )
    }
    if ((admin == false) && (i?.user?._id != user?._id)) {
        return (
            <></>
        )
    }
    if (i?.type != 4) {
        if (i?.postId?.txt == null) {
            return (
                <></>
            )
        }
    }
    if (i?.type == 3 && point == 0) {
        return (
            <></>
        )
    }
    let hasHave=userName=="You"?"have":"has"
    return (
        <Box onClick={()=>{
            console.log(i)
        }} sx={[{ height: "80px", textAlign: "left", padding: "10px",boxShadow: "0px 5px 20px #26262626", background: "white", display: "flex", alignItems: "center", borderRadius: "5px" },style]}>
            <Box component={"img"} src="assets/score/star.svg" />
            <Box overflow={"hidden"}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(12), flex: 1,maxHeight:"52px",overflow:"hidden", marginLeft: "10px" }}>{type == "like" ? `${userName} ${hasHave} earned ${point} Point${point>1?"s":""} for liking the post ${txt}` : (type == "verification" ? `${userName} ${hasHave} earned ${point} Point${point>1?"s":""} for commenting on the post ${txt}` : (type == "manually" ? `${userName} ${hasHave} earned ${point} Point${point>1?"s":""} for ${i?.manualId?.title}.` : type == "geo" ? `${userName} ${hasHave} earned ${point} Point${point>1?"s":""} for ${i?.postId?.txt}.` : type == "quiz" ? `${userName} ${hasHave} earned ${point} Point${point>1?"s":""} from quiz.` : null))}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(10), flex: 1, marginLeft: "10px" }}>{moment(i?.createdAt).format("MMM, DD, YYYY hh:mm a")}</Typography>
            </Box>
        </Box>
    )
}