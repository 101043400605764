import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/load';
import hit from '../services/apiManager';
import { image_url, scores, userDetail as udetail } from '../services/endpoints';
import showToast from "../utils/showToast";
// import { appcolors } from '../theme/';
// import { fonts } from '../../constants/AppFonts';
import moment from 'moment';
import { Button, Typography, Box, Avatar, useTheme } from '@mui/material';
import { DateRangePicker } from "rsuite";

import { useLocation } from 'react-router-dom';
import { pxToRem } from '../theme/typography';
import PointsComp from '../components/ScoreBoard/PointsComp';
import { firebaseDb, firebaseStorage } from "../utils/firebaseInit";
import { collection, doc, getDoc, onSnapshot, setDoc, updateDoc, query, where, orderBy, addDoc, increment, getDocs, deleteDoc } from "firebase/firestore";
import { getTotalPointUScore ,dailyUserCanScorePoint,weeklyUserCanScorePoint,monthlyUserCanScorePoint,getMiscTotalCanPoint} from '../utils/pointutil';

export default function UserScoreBoard({ }) {
    const location = useLocation()
    const uid = location?.state?.id;
    const gid = location?.state?.gId;
    const subid = location?.state?.subid;
    const dispatch = useDispatch()
    const [allPoints, setAllPoints] = useState([])
    const [pointsHis, setPointsHis] = useState([])
    const [userDetail, setUserDetail] = useState(null)
    const [totalPoints, setTotalPoints] = useState(0)
    const [totalCanScore,setTotalCanScore]=useState(0)
    const [dTotalCanScore,setDTotalCanScore]=useState(0)
    const [wTotalCanScore,setWTotalCanScore]=useState(0)
    const [mTotalCanScore,setMTotalCanScore]=useState(0)
    const [miscTotalCanScore,setMiscTotalCanScore]=useState(0)

    const [selectedQuarter, setSelectedQuarter] = useState("")
    const [categories, setCategories] = useState([])
    const [sDate, setSDate] = useState(null)
    const [eDate, setEDate] = useState(null)
    const [scoringPosts, setScoringPosts] = useState([])
    const quarter = ["Daily", "Weekly", "Monthly", "Miscellaneous"]

    useEffect(() => {
        if (uid != undefined) {
            getuidPoints(uid)
            getUserDetail(uid)
        }
    }, [uid])

    useEffect(() => {
        if (subid && pointsHis?.length > 0) {
            getSavedDate(subid)
        }
    }, [subid, pointsHis])

    const saveTofireBase = async (date1, date2) => {
        setDoc(doc(collection(firebaseDb, "SubDateRange"), subid), { sDate: date1, eDate: date2 })
    }
    const getSavedDate = async (id) => {
        let res = await getDoc(doc(collection(firebaseDb, "SubDateRange"), id))
        let data = await res?.data()
        if (data && data?.sDate && data?.eDate) {
            setSDate(moment(data?.sDate).toDate())
            setEDate(moment(data?.eDate).toDate())
            setSelectedQuarter("Miscellaneous")
            setMiscDataUsingRange(moment(data?.sDate).toDate(), moment(data?.eDate).toDate())
        } else {
            setSelectedQuarter("")
        }
    }

    const setMiscDataUsingRange = (s, e) => {
        let newData = pointsHis.filter(x => {
            if (x?.type == 3) {
                const dateIsAfter = moment(x?.updatedAt).isAfter(moment(s));
                const dateIsBefore = moment(x?.updatedAt).isBefore(moment(e));
                if (dateIsAfter && dateIsBefore) {
                    return x
                }
            } else {
                const dateIsAfter = moment(x?.createdAt).isAfter(moment(s));
                const dateIsBefore = moment(x?.createdAt).isBefore(moment(e));
                if (dateIsAfter && dateIsBefore) {
                    return x
                }
            }
        })
        setAllPoints(newData)
    }
    useEffect(()=>{
        getGroupScoringsPostAll()
    },[])

    const getGroupScoringsPostAll = async (group) => {
        try {
            let res = await hit("/posts/allScorePosts", "post", { groups: gid })
            if (!res.err) {
                setScoringPosts(res.data)
            }
        } catch (error) {

        } finally {

        }
    }

    useEffect(()=>{
        let value=getTotalPointUScore(pointsHis,scoringPosts,uid)
        setTotalCanScore(value)
        let dValue=dailyUserCanScorePoint(pointsHis,scoringPosts,uid)
        setDTotalCanScore(dValue)
        let wValue=weeklyUserCanScorePoint(pointsHis,scoringPosts,uid)
        setWTotalCanScore(wValue)
        let mValue=monthlyUserCanScorePoint(pointsHis,scoringPosts,uid)
        setMTotalCanScore(mValue)
        let miscValue=getMiscTotalCanPoint(pointsHis,scoringPosts,uid,sDate,eDate)
        setMiscTotalCanScore(miscValue)
    },[scoringPosts,uid,pointsHis,sDate,eDate])

    useEffect(() => {
        if (allPoints?.length > 0) {
            let temp = []
            allPoints?.forEach(e => {
                if (e?.type == 4) {
                    if (!temp?.includes(e?.manualId?.category)) {
                        temp.push(e?.manualId?.category)
                    }
                } else {
                    if (!temp?.includes(e?.postId?.category)) {
                        temp.push(e?.postId?.category)
                    }
                }
            })
            setCategories(temp)
        }
    }, [allPoints])


    const getUserDetail = async (id) => {
        try {
            let res = await hit(`${udetail}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setUserDetail(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        console.log(tmp)
        return tmp;
    }
    const getuidPoints = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${scores?.user}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let c = 0
                let temp = res?.data?.filter(x => x?.groups?.includes(gid) && (x?.postId != null || x?.type == 4))
                setAllPoints(temp)
                setPointsHis(temp)
                temp.forEach(e => {
                    c += e?.point
                });
                setTotalPoints(c)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const getUserTotalCanScore=(point,item)=>{
        if(item=="Daily"){
            return point>dTotalCanScore?point:dTotalCanScore
        }
        if(item=="Weekly"){
            return point>wTotalCanScore?point:wTotalCanScore
        }
        if(item=="Monthly"){
            return point>mTotalCanScore?point:mTotalCanScore
        }
        if(item=="Miscellaneous"){
            return point>miscTotalCanScore?point:miscTotalCanScore

        }
        return point
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <>
                    {/* <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600", marginBottom: "15px", textAlign: "left" }}>Profile</Typography>  */}
                    <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                        <Box sx={[{ display: "flex", flexDirection: "column", alignItems: "center", padding: "5px", flex: 1, }]}>
                            <Avatar src={image_url + userDetail?.profilePic} sx={{ height: "100px", border: "3px solid white", width: "100px", }} />
                            <Typography sx={{ marginTop: "10px", fontWeight: "600", fontSize: pxToRem(24), textAlign: "center" }}>{userDetail?.firstName}</Typography>
                            <Button sx={{ fontWeight: "400", width: "40%" }} variant="outlined">
                                <Box>
                                    {/* <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18) }}>{totalPoints}/{totalPoints>totalCanScore?totalPoints:totalCanScore}</Typography> */}
                                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18) }}>{totalPoints}</Typography>
                                    <Typography sx={{ color: "black", fontSize: pxToRem(10) }}>Total Points</Typography>
                                </Box>
                            </Button>
                        </Box>
                        <br />
                        <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Per Quarter Points</Typography>
                        {splitArray(quarter, 2).map(items => {
                            return (
                                <Box sx={{ display: "flex", marginTop: "10px" }}>
                                    {items?.map((item, index) => {
                                        let temp = 0
                                        let newa = []
                                        if (item == "Daily") {
                                            newa = pointsHis.filter(x => {
                                                let date1 = moment().format("YYYYMMDD");
                                                if (x?.type == 3) {
                                                    let date2 = moment(x?.updatedAt).format("YYYYMMDD");
                                                    if (date1 == date2) {
                                                        return x
                                                    }
                                                } else {
                                                    let date2 = moment(x?.createdAt).format("YYYYMMDD");
                                                    if (date1 == date2) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Weekly") {
                                            newa = pointsHis.filter(x => {
                                                moment.updateLocale('en', {
                                                    week: {
                                                        dow: 1
                                                    }
                                                });
                                                let weekStart = moment().startOf('week')
                                                let weekEnd = moment().endOf('week')
                                                if (x?.type == 3) {
                                                    const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                } else {
                                                    const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Monthly") {
                                            newa = pointsHis.filter(x => {
                                                let weekStart = moment().startOf('month')
                                                let weekEnd = moment().endOf('month')
                                                if (x?.type == 3) {
                                                    const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                } else {
                                                    const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Quarterly") {
                                            newa = pointsHis.filter(x => {
                                                let weekStart = moment().startOf('quarter')
                                                let weekEnd = moment().endOf('quarter')
                                                if (x?.type == 3) {
                                                    const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                } else {
                                                    const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                                    const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                                    if (dateIsAfter && dateIsBefore) {
                                                        return x
                                                    }
                                                }
                                            })
                                        } else if (item == "Miscellaneous") {
                                            if (sDate && eDate) {
                                                console.log(sDate, eDate)
                                                newa = pointsHis.filter(x => {
                                                    if (x?.type == 3) {
                                                        const dateIsAfter = moment(x?.updatedAt).isAfter(moment(sDate));
                                                        const dateIsBefore = moment(x?.updatedAt).isBefore(moment(eDate));
                                                        if (dateIsAfter && dateIsBefore) {
                                                            return x
                                                        }
                                                    } else {
                                                        const dateIsAfter = moment(x?.createdAt).isAfter(moment(sDate));
                                                        const dateIsBefore = moment(x?.createdAt).isBefore(moment(eDate));
                                                        if (dateIsAfter && dateIsBefore) {
                                                            return x
                                                        }
                                                    }
                                                })
                                            } else {
                                                newa = []
                                            }
                                        }
                                        newa.forEach(e => {
                                            temp += e?.point
                                        })
                                        return (
                                            <ScoreButtons
                                                style={{ marginLeft: index != 0 ? "20px" : "0px" }}
                                                item={item}
                                                index={index}
                                                selected={selectedQuarter}
                                                point={temp}
                                                sDate={sDate}
                                                eDate={eDate}
                                                totalCanScore={getUserTotalCanScore(temp,item)}
                                                onDateChange={(s, e) => {
                                                    setSDate(s)
                                                    setEDate(e)
                                                    if (s == null && e == null) {
                                                        setSelectedQuarter("")
                                                        setAllPoints(pointsHis)
                                                        saveTofireBase(null, null)
                                                    } else {
                                                        setSelectedQuarter(item)
                                                        let newData = pointsHis.filter(x => {
                                                            if (x?.type == 3) {
                                                                const dateIsAfter = moment(x?.updatedAt).isAfter(moment(s));
                                                                const dateIsBefore = moment(x?.updatedAt).isBefore(moment(e));
                                                                if (dateIsAfter && dateIsBefore) {
                                                                    return x
                                                                }
                                                            } else {
                                                                const dateIsAfter = moment(x?.createdAt).isAfter(moment(s));
                                                                const dateIsBefore = moment(x?.createdAt).isBefore(moment(e));
                                                                if (dateIsAfter && dateIsBefore) {
                                                                    return x
                                                                }
                                                            }
                                                        })
                                                        setAllPoints(newData)
                                                        saveTofireBase(s?.toString(), e?.toString())
                                                    }
                                                }}
                                                onPress={() => {
                                                    if (selectedQuarter == item) {
                                                        setSelectedQuarter("")
                                                        setAllPoints(pointsHis)
                                                    } else {
                                                        setSelectedQuarter(item)
                                                        setAllPoints(newa)
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                </Box>
                            )
                        })}
                        <br />
                        <Typography sx={{ textAlign: "left", fontWeight: "600", fontSize: pxToRem(18) }}>Points</Typography>
                        <br />
                        {allPoints?.filter(x => x?.point != 0)?.map((i, j) => {
                            return <PointsComp i={i} j={j} style={{ marginTop: j != 0 ? "15px" : 0 }} admin={true} key={i?._id} adminView={true} />
                        })}
                    </Box>
                </>
            </Box>
        </Box>

    )
}

const ScoreButtons = ({
    item, index, styleView, selected, onPress, point, style, sDate, eDate, onDateChange,totalCanScore
}) => {
    let isSelected = selected == item
    const theme = useTheme()
    return (
        <Box onClick={onPress} sx={[{ boxShadow: "0px 5px 20px #26262626", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", px: "10px", py: "20px", borderRadius: "5px", minHeight: "100px", flex: 1, background: isSelected ? theme.palette.primary.main : "white" }, style]}>
            <Typography sx={{ fontWeight: "600", color: isSelected ? "white" : "black" }}>{item}</Typography>
            <Box onClick={e => {
                e.stopPropagation()
            }} style={{ display: "flex", alignItems: "center" }}>
                {/* <Button variant="outlined" sx={{ minWidth: "150px", borderColor: isSelected ? "white" : theme.palette.primary.main, color: isSelected ? "white" : theme.palette.primary.main }}>{point}/{point>totalCanScore?point:totalCanScore}</Button> */}
                <Button variant="outlined" sx={{ minWidth: "150px", borderColor: isSelected ? "white" : theme.palette.primary.main, color: isSelected ? "white" : theme.palette.primary.main }}>{point}</Button>
                {item == "Miscellaneous" && <DateRangePicker placeholder="Select Date Range" format="MMM, dd, yyyy" value={[sDate, eDate]} style={{ color: theme.palette.primary.main, marginLeft: "10px" }}
                    placement="auto" onChange={v => {
                        if (v) {
                            if (v.length == 1) {
                                onDateChange(v[0], v[0])
                                return
                            }
                            if (v.length == 2) {
                                onDateChange(v[0], v[1])
                            }
                        } else {
                            onDateChange(null, null)
                        }
                    }} />}
            </Box>
        </Box>
    )
}
