import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GroupSelect from "../components/BroadCast/GroupSelect";
import SearchBar from "../components/Home/SearchBar";
import Input from "../components/Login/Input";
import UserTypeSelect from "../components/Login/UserTypeSelect";
import ProfileComp from "../components/Profile/ProfileComp";
import GroupScoreProfile from "../components/ScoreBoard/GroupScoreProfile";
import PointsComp from "../components/ScoreBoard/PointsComp";
import ScoreCard from "../components/ScoreBoard/ScoreCard";
import TwoSelectionTab from "../components/TwoSelectionTab";
import hit from "../services/apiManager";
import { notifications } from "../services/endpoints";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import { firebaseDb } from "../utils/firebaseInit";
import { setDoc, doc, collection, addDoc, increment,  } from "firebase/firestore";
export default function BroadCast() {
    const navigate = useNavigate()
    const [selecteds, setSelecteds] = useState([])
    const allteams = useSelector(state => state?.team)?.team
    const [search, setSearch] = useState("")
    const [groups, setGroups] = useState([])
    const user = useSelector(state => state?.auth?.user)
    const team = useSelector(state => state?.team?.team)
    const [message, setMessage] = useState("")

    const dispatch = useDispatch()
    useEffect(() => {
        if (allteams) {
            setGroups(allteams)
        }
    }, [allteams])
    useEffect(() => {
        if (search?.trim()?.length == 0) {
            setGroups(allteams)
        }
    }, [search])

    const searchGroup = (key) => {
        let arr = [...allteams]
        let temp = arr.filter(x => String(x?.name).toLowerCase().includes(key?.toLowerCase()))
        setTimeout(() => {
            setGroups(temp)
        }, 400);
    }
    const submit = () => {


    }

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        console.log(tmp)
        return tmp;
    }






    const sendMessage = () => {
        if (selecteds.length == 0) {
            showToast("No groups selected.", "error")
            return
        }
        if (message?.trim() == "") {
            showToast("Message is required.","error")
            return
        }
        const txt = {
            _id: moment().unix(),
            text: message,
            type: "text",
            createdAt: moment().unix(),
            approved: 1,
            user: {
                _id: user?._id,
                firstName: user?.firstName,
                profilePic: user?.profilePic
            }
        }
        selecteds.forEach((e, i) => {
            let temp = team?.filter(x => x?._id == e)
            let uids = temp[0]?.members
            addDoc(collection(firebaseDb, "chats", e, "messages"), txt)
            let unreadCount = {}
            uids.forEach(x => {
                unreadCount[x] = increment(1)
            })
            unreadCount[user?._id] = 0
            setDoc(doc(firebaseDb, "chats", e), {
                type: "group",
                group: temp[0],
                unreadCount,
                lastMessage: txt,
                userIds: uids,
            },{merge:true})

            sendNotification({
                userIds: uids,
                title: user?.firstName,
                body: message.toString(),
                chat_id: e,
                notiType: "4",
                isBroadCast:true
            }, i)
        });
    }
    const sendNotification = async (data, i) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(notifications, "post", data)
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                if (i == selecteds.length - 1) {
                    navigate("/user/home")
                    dispatch(setLoading(false))
                    showToast("Broadcast successfully!")
                }
            }
        } catch (err) {
            console.log(err)
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600", fontSize: pxToRem(18) }}>Select Group</Typography>
                <br />
                <SearchBar onClickSearch={() => {
                    searchGroup(search)
                }} value={search} onChange={v => setSearch(v)} placeholder={"Search...."} />
                <br />
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "600" }}>Groups</Typography>
                        {/* <Typography sx={{ fontSize: pxToRem(13) }}>View All</Typography> */}
                    </Box>
                    <br />
                    {splitArray(groups, 2).map(items => {
                        return (
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {items?.map((item, index) => {
                                    return <GroupSelect item={item} id={item?._id} selecteds={selecteds} setSelecteds={setSelecteds} isSelected={selecteds.includes(item?._id)} title={item?.name} style={{ marginLeft: index != 0 ? "20px" : "0px" }} />
                                })}
                            </Box>
                        )
                    })}

                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600", fontSize: pxToRem(18) }}>Message</Typography>
                <br />
                <Box sx={{ display: "flex", px: "10px", py: "20px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    <Input value={message} onChange={v=>{
                        setMessage(v)
                    }} rows={8} inputContainerStyle={{ height: "200px" }} placeholder={"Type here..."} multiline={true} titleStyle={{ textAlign: "left" }} title="Message" />
                    <br />
                    <br />
                    <Button onClick={() => {
                        sendMessage()
                    }} variant="contained" sx={{ borderRadius: "5px", width: "45%", marginX: "auto" }} >
                        Send
                    </Button>
                    <br />
                    <br />
                </Box>
            </Box>

        </Box>
    )
}
