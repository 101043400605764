import { TextField, Typography, useTheme, useMediaQuery, Box, MenuItem, Menu } from "@mui/material"
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "../../css/DatePicker.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import moment from "moment";

const Input = ({ props, title, value, isPass, multiline, onChange, containerStyle, rows, rightText, placeholder, imgstyle, titleStyle, inputStyle, inputContainerStyle, img, inputProps }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('tablet'));
    const [showPass, setShowPass] = useState(true)
    const [showBorder, setShowBorder] = useState(false)
    return (
        <Box sx={[{}, containerStyle]}>
            <Typography sx={[{ marginLeft: "10px", fontSize: "12px", fontWeight: '600' }, titleStyle]}>{title}</Typography>
            <Box sx={[{ backgroundColor: "white", borderWidth: "1.5px", borderStyle: "solid", borderColor: showBorder ? "#0BB2D0" : "transparent", display: "flex", alignItems: "center", height: "50px", overflow: "hidden", paddingX: "10px", marginTop: "2px", borderRadius: "5px", boxShadow: "0px 5px 10px #2626260D" }, inputContainerStyle]}>
                <TextField variant="standard"
                    placeholder={placeholder}
                    value={value}
                    onChange={e => {
                        if (onChange) {
                            onChange(e.target.value)
                        }
                    }}
                    multiline={multiline ?? false}
                    rows={multiline ? (rows ?? 4) : 1}
                    InputProps={{
                        disableUnderline: true,
                        justifyContent: 'center',
                        onFocus: () => {
                            setShowBorder(true)
                        },
                        onBlur: () => {
                            setShowBorder(false)
                        }
                        ,
                        type: showPass && String(title).toLowerCase().includes("password") ? "password" : "text",
                        ...inputProps,
                        sx: [{ fontSize: "14px", fontWeight: "500" }, inputStyle],

                    }} sx={{ borderColor: "white", display: "flex", flex: 1, fontSize: { laptop: "1px", mobile: "5px" } }} />
                {img && <img src={isPass ? (showPass ? "assets/login/eye.svg" : "assets/signup/eye-off.svg") : img} onClick={() => {
                    if (isPass) {
                        setShowPass(!showPass)
                    }

                }} alt="side input" style={{ height: "15px", width: "15px", marginRight: ".5em", ...imgstyle }} />}
                {Boolean(rightText) &&
                    rightText
                }
            </Box>
        </Box>
    )
}

export const CustomPhoneInput = ({ props, title, value, isPass, multiline, onChange, containerStyle, rows, rightText, placeholder, imgstyle, titleStyle, inputStyle, inputContainerStyle, img, inputProps }) => {
    const [showBorder, setShowBorder] = useState(false)
    return (
        <Box sx={[{ position: "relative" }, containerStyle]}>
            <Typography sx={[{ marginLeft: "10px", fontSize: "12px", fontWeight: '600' }, titleStyle]}>{title}</Typography>
            <Box sx={[{ backgroundColor: "white", borderWidth: "1.5px", borderStyle: "solid", borderColor: showBorder ? "#0BB2D0" : "transparent", display: "flex", justifyContent: "flex-end", alignItems: "center", height: "50px", overflow: "hidden", paddingX: "10px", marginTop: "2px", borderRadius: "5px", boxShadow: "0px 5px 10px #2626260D" }, inputContainerStyle]}>

                {img && <img src={img} alt="side input" style={{ height: "15px", width: "15px", marginRight: ".5em", ...imgstyle }} />}
            </Box>
            <PhoneInput
                country={'us'}
                value={value}
                onChange={phone => {
                    console.log(phone)
                    onChange(phone)
                }}
                buttonStyle={{ borderWidth: "0px" }}
                containerStyle={{ position: "absolute", top: "28px", left: "10px" }}
                inputProps={{
                    style: { fontSize: "14px", borderWidth: "0px", paddingLeft: "5px", fontWeight: "500", fontFamily: "Montserrat, Public Sans, sans-serif" },
                    disableUnderline: true,
                    justifyContent: 'center',
                    onFocus: () => {
                        setShowBorder(true)
                    },
                    onBlur: () => {
                        setShowBorder(false)
                    }
                }}
            />
        </Box>
    )
}

export const DatePickerInput = ({  title, value,  containerStyle,onClick,  imgstyle, titleStyle, inputContainerStyle, img }) => {
    const [showBorder, setShowBorder] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    return (
        <Box sx={[{ position: "relative" }, containerStyle]}>
            <Typography sx={[{ marginLeft: "10px", fontSize: "12px", fontWeight: '600' }, titleStyle]}>{title}</Typography>
            <Box onClick={onClick} sx={[{cursor:"pointer",backgroundColor: "white", borderWidth: "1.5px", borderStyle: "solid", borderColor: showBorder ? "#0BB2D0" : "transparent", display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px", overflow: "hidden", paddingX: "10px", marginTop: "2px", borderRadius: "5px", boxShadow: "0px 5px 10px #2626260D" }, inputContainerStyle]}>
                <Typography sx={{fontSize: "14px", borderWidth: "0px", paddingLeft: "5px", fontWeight: "500"}}>{value}</Typography>
                {img && <img src={img} alt="side input" style={{ height: "15px", width: "15px", marginRight: ".5em", ...imgstyle }} />}
            </Box>
        </Box>
    )
}

export const DropDownInput = ({ props, title, options, value, isPass, multiline, onChange, containerStyle, rows, rightText, placeholder, imgstyle, titleStyle, inputStyle, inputContainerStyle, img, inputProps }) => {
    const [showBorder, setShowBorder] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box sx={[{ position: "relative" }, containerStyle]}>
            <Typography sx={[{ marginLeft: "10px", fontSize: "12px", fontWeight: '600' }, titleStyle]}>{title}</Typography>
            <Box onClick={handleClick} sx={[{ backgroundColor: "white", borderWidth: "1.5px", borderStyle: "solid", borderColor: showBorder ? "#0BB2D0" : "transparent", display: "flex", justifyContent: "flex-end", alignItems: "center", height: "50px", overflow: "hidden", paddingX: "10px", marginTop: "2px", borderRadius: "5px", boxShadow: "0px 5px 10px #2626260D" }, inputContainerStyle]}>
                <Typography sx={{flex:1,textAlign:"left",fontSize: "14px", fontWeight: "500",}}>{value}</Typography>
                {img && <img src={img} alt="side input" style={{ height: "15px", width: "15px", marginRight: ".5em", ...imgstyle }} />}
            </Box>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 45 * 4.5,
                        minWidth: '200px',
                        // width:"100%"
                    },
                }}
            >
                {options?.map((option) => (
                    <MenuItem sx={{fontSize:"14px"}} key={option} selected={option === value} onClick={() => {
                        onChange(option)
                        setAnchorEl(null)
                    }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

export default Input