import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Splash from "../pages/Splash";
import LandingPage from "../pages/Landing";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import OtpVerification from "../pages/OtpVerification";
import ForgotPassword from "../pages/ForgotPassword";
import FOtpVerification from "../pages/FOtpVerification";
import NewPassword from "../pages/NewPassword";
import ResetSuccessfull from "../pages/ResetSuccessfull";
import DashboardLayout from "../components/layout/DashboardLayout";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Library from "../pages/Library";
import Draft from "../pages/Draft";
import Calendar from "../pages/Calendar";
import Teams from "../pages/Teams";
import Settings from "../pages/Settings";
import ScoreBoard from "../pages/ScoreBoard";
import EditProfile from "../pages/EditProfile";
import ChangePassword from "../pages/ChangePassword";
import SubsPage from "../pages/SubsPage";
import MyAccessCode from "../pages/MyAccessCode";
import ContactUs from "../pages/ContactUs";
import FAQ from "../pages/FAQ";
import FileView from "../pages/FileView";
import AboutUS from "../pages/AboutUs";
import Terms from "../pages/Terms";
import Notifications from "../pages/Notifications";
import ChatPage from "../pages/Chat";
import BroadCast from "../pages/Broadcast";
import UserDetails from "../pages/UserDetails";
import JoinGroup from "../pages/JoinGroup";
import CreateGroup from "../pages/CreateGroup";
import CreateSubGroup from "../pages/CreateSubGroup";
import ManualSubmission from "../pages/ManualSubmission";
import FullGroupDetails from "../pages/FullGroupDetails";
import SubGroup from "../pages/SubGroup";
import Map from "../pages/Map";
import Interaction from "../pages/Interactions";
import PostingTo from "../pages/PostingTo";
import ChooseAccess from "../pages/ChooseAccess";
import Post1 from "../pages/Post1";
import Post2 from "../pages/Post2";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CommentsPage from "../pages/CommentsPage";
import QuizWeb from "../pages/QuizWeb";
import ChatSettings from "../pages/ChatSettings";
import PostDetail from "../pages/PostDetail";
import Quiz from "../pages/Quiz";
import ProfilePoint from "../pages/ProfilePoint";
import UserScoreBoard from "../pages/UserScoreBoard";
import Quizes from "../pages/Quizes";
export default function MainRoute() {
    const auth = useSelector(state => state.auth)
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (auth?.authorize) {
            if (!location.pathname?.includes("user")) {
                navigate("/user/home", { replace: true })
            }
        } else {
            if (location.pathname?.includes("user")) {
                navigate("/", { replace: true })
            }
        }
    }, [location])
    return (
        <Routes>
            <Route path="/" element={<Splash />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/otp" element={<OtpVerification />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/fotp" element={<FOtpVerification />} />
            <Route path="/newp" element={<NewPassword />} />
            <Route path="/success" element={<ResetSuccessfull />} />
            <Route path="/user" element={<DashboardLayout />} >
                <Route path="/user/home" element={<Home />} />
                <Route path="/user/profile" element={<Profile />} />
                <Route path="/user/library" element={<Library />} />
                <Route path="/user/draft" element={<Draft />} />
                <Route path="/user/calendar" element={<Calendar />} />
                <Route path="/user/teams" element={<Teams />} />
                <Route path="/user/settings" element={<Settings />} />
                <Route path="/user/scoreboard" element={<ScoreBoard />} />
                <Route path="/user/editProfile" element={<EditProfile />} />
                <Route path="/user/changePassword" element={<ChangePassword />} />
                <Route path="/user/chatSettings" element={<ChatSettings />} />
                <Route path="/user/subs" element={<SubsPage />} />
                <Route path="/user/access" element={<MyAccessCode />} />
                <Route path="/user/contact" element={<ContactUs />} />
                <Route path="/user/faq" element={<FAQ />} />
                <Route path="/user/fileviewer" element={<FileView />} />
                <Route path="/user/aboutUs" element={<AboutUS />} />
                <Route path="/user/terms" element={<Terms />} />
                <Route path="/user/notis" element={<Notifications />} />
                <Route path="/user/chat" element={<ChatPage />} />
                <Route path="/user/broadcast" element={<BroadCast />} />
                <Route path="/user/details" element={<UserDetails />} />
                <Route path="/user/join" element={<JoinGroup />} />
                <Route path="/user/create" element={<CreateGroup />} />
                <Route path="/user/sub" element={<CreateSubGroup />} />
                <Route path="/user/manual" element={<ManualSubmission />} />
                {/* not in navigations */}
                <Route path="/user/group" element={<FullGroupDetails />} />
                <Route path="/user/map" element={<Map />} />
                <Route path="/user/interaction" element={<Interaction />} />
                {/* upto here */}
                <Route path="/user/post1" element={<Post1 />} />
                <Route path="/user/user_score" element={<UserScoreBoard />} />
                <Route path="/user/post2" element={<Post2 />} />
                <Route path="/user/postingTo" element={<PostingTo />} />
                <Route path="/user/choose" element={<ChooseAccess />} />
                <Route path="/user/comments" element={<CommentsPage />} />
                <Route path="/user/quiz" element={<Quiz />} />
                <Route path="/user/subGroup" element={<SubGroup />} />
                <Route path="/user/post_detail" element={<PostDetail />} />
                <Route path="/user/profile_point" element={<ProfilePoint />} />
                {/* saved quizes List */}
                <Route path="/user/quizes" element={<Quizes />} />
            </Route>
        </Routes>
    )
}