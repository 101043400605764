import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { image_url } from "../../services/endpoints";
import { pxToRem } from "../../theme/typography";
import _ from "lodash"
import DeleteModal from "../DeleteModal";
import { useState } from "react";
export default function FriendsComp({ members, admins, removeMem, onChatClick, groupDetail }) {
    const navigate = useNavigate()
    const userMine = useSelector(state => state?.auth?.user)
    const [deleteModal,setDeleteModal]=useState(false)
    const [selectedUser,setSelectedUser]=useState(null)
    return (
        <Box sx={{ boxShadow: "0px 5px 20px #26262626", p: "10px", borderRadius: "5px", background: "white", marginTop: "20px" }}>
            {_.sortBy(admins, [function (o) { return String(o?.lastName)?.toLowerCase() }])?.map((x, i) => {
                return <>
                    <Box onClick={() => {
                        navigate("/user/details", { state: { userData: x, groupDetail } })
                    }} sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
                        <Avatar
                            src={image_url + x?.profilePic}
                            style={{ height: "50px", width: "50px" }}
                        />
                        <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <Typography sx={{ fontWeight: "600", fontSize: pxToRem(16) }}>{x?.firstName} {x?.lastName} {admins?.map(x => x._id)?.includes(x?._id) ? " (Admin)" : ""}</Typography>
                            <Typography sx={{ fontSize: pxToRem(12) }}>{x?.email}</Typography>
                        </Box>

                        {userMine?._id != x?._id && <Button onClick={(e) => {
                            onChatClick(x)
                            e.stopPropagation()
                        }} variant="contained">
                            Chat
                        </Button>}
                        {(admins?.map(a=>a._id).includes(userMine?._id)&&x._id!=userMine?._id) && <Box onClick={(event) => {
                            event.stopPropagation()
                            setSelectedUser(x)
                            setDeleteModal(true)
                        }} component={"img"} sx={{ height: "25px", width: "25px", marginLeft: "5px", cursor: "pointer" }} src="assets/teams/delete.svg" />}
                   
                    </Box>
                    {i != 7 && <Divider sx={{ marginY: "10px" }} />}
                </>
            })}
            {_.sortBy(members, [function (o) { return String(o?.lastName)?.toLowerCase() }])?.filter(x => !admins?.map(a => a._id)?.includes(x?._id))?.map((x, i) => (
                <>
                    <Box onClick={() => {
                        navigate("/user/details", { state: { userData: x, groupDetail } })
                    }} sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
                        <Avatar
                            src={image_url + x?.profilePic}
                            style={{ height: "50px", width: "50px" }}
                        />
                        <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <Typography sx={{ fontWeight: "600", fontSize: pxToRem(16) }}>{x?.firstName} {x?.lastName}{admins?.map(x => x._id)?.includes(x?._id) ? " (Admin)" : ""}</Typography>
                            <Typography sx={{ fontSize: pxToRem(12) }}>{x?.email}</Typography>
                        </Box>
                        {userMine?._id != x?._id && <Button onClick={(e) => {
                            onChatClick(x)
                            e.stopPropagation()
                        }} variant="contained">
                            Chat
                        </Button>}
                        {admins?.map(a=>a._id).includes(userMine?._id) && <Box onClick={(event) => {
                            event.stopPropagation()
                            setSelectedUser(x)
                            setDeleteModal(true)
                        }} component={"img"} sx={{ height: "25px", width: "25px", marginLeft: "5px", cursor: "pointer" }} src="assets/teams/delete.svg" />}
                    </Box>
                    {i != 7 && <Divider sx={{ marginY: "10px" }} />}

                </>
            ))}
            <DeleteModal
             title={"You want to remove "+`${selectedUser?.firstName} ${selectedUser?.lastName} `+"from team."}
             open={deleteModal}
             handleClose={() => { setDeleteModal(false);  }}
             onPressNo={() => { setDeleteModal(false);  }}
             onPressYes={() => { setDeleteModal(false);
                // removeMemberSubgroup(item._id)
                removeMem(selectedUser?._id,groupDetail?._id)
            }}
   
            />
        </Box>
    )
}