import { Avatar, Badge, Box, Button, TextField, Typography } from "@mui/material";
import { pxToRem } from "../theme/typography";
import TwoSelectionTab from "../components/TwoSelectionTab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDoc, onSnapshot, setDoc, updateDoc, query, where, orderBy, addDoc, increment, getDocs, deleteDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { firebaseDb, firebaseStorage } from "../utils/firebaseInit";
import { setGroupsChats } from "../store/groupsChats";
import { image_url, notifications } from "../services/endpoints";
import moment from "moment";
import { useLocation } from "react-router-dom";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import { setLoading } from "../store/load";
import { setSelectedTab, setSelectGroupStore, setSingleDataStore } from '../store/chat'
import DeleteModal from "../components/DeleteModal";
export default function ChatPage() {
    const ref = useRef()
    const dispatch = useDispatch()
    const userMe = useSelector(state => state?.auth)?.user
    const [chatUsers, setChatsUsers] = React.useState([])
    const [groups, setGroups] = React.useState([])
    // const [selectedGroup, setSelectedGroup] = React.useState(null)
    // const [singleData,setSingleData]=useState(null)
    const selectedGroup = useSelector(state => state?.chat?.selectedGroup)
    const singleData = useSelector(state => state?.chat?.singleData)
    const currentTab = useSelector(state => state?.chat?.selectedTab)

    const setCurrentTab = (data) => {
        dispatch(setSelectedTab(data))
    }

    const setSelectedGroup = (data) => {
        dispatch(setSelectGroupStore(data))
    }
    const setSingleData = (data) => {
        dispatch(setSingleDataStore(data))
    }

    React.useEffect(() => {
        let q = query(collection(firebaseDb, "chats"), where("userIds", "array-contains", userMe?._id ?? ""))
        let unsub = onSnapshot(q,
            (querySnapshot) => {
                let users = []
                let groups = []
                for (let snaps of querySnapshot.docs) {
                    let data = snaps.data()
                    // console.log(data)
                    if (data?.type == "group") {
                        let unreadCount = data?.unreadCount ? data?.unreadCount[userMe?._id] : 0
                        groups.push({ user: { firstName: data?.group?.name, profilePic: data?.group?.profilePic }, group: data?.group, unreadCount, oneOne: data?.oneOne ?? true, oneAdmin: data?.oneAdmin ?? true, lastMessage: data?.lastMessage, type: "group" })
                    } else {
                        let otherUser = data?.users?.filter(x => x?._id != userMe?._id)
                        let unreadCount = data?.unreadCount ? data?.unreadCount[userMe?._id] : 0

                        if (otherUser?.length == 1) {
                            users.push({ user: otherUser[0], lastMessage: data?.lastMessage, group: data?.group, groupDetails: data?.groupDetails, unreadCount: unreadCount, type: "chat", enable: data?.enable ?? 1 })
                        }
                    }
                }
                users.sort((a, b) => b.lastMessage?.createdAt - a.lastMessage?.createdAt)
                groups.sort((a, b) => b.lastMessage?.createdAt - a.lastMessage?.createdAt)
                dispatch(setGroupsChats(groups))
                setChatsUsers(users)
                setGroups(groups)
            }, // onNext
            (error) => console.error(error), // onError
        )
        return () => {
            unsub()
        }
    }, [userMe])

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1 }}>
                <Box sx={{ height: "90%", display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "600", textAlign: "left", fontSize: pxToRem(18) }}>Inbox</Typography>
                        {/* <Box sx={{ height: "20px", width: "20px" }} component={"img"} src="assets/calendar/plus.svg" /> */}
                    </Box>
                    <TwoSelectionTab style={{ background: "white" }} value={currentTab} setCurrentTab={setCurrentTab} t1="Chat" t2="Group Chat" />
                    <Box sx={{ background: "white", marginTop: "20px", overflowY: "auto", display: "flex", flex: 1, flexDirection: "column", width: "100%", minHeight: "400px", p: "10px", borderRadius: "5px", boxShadow: "0px 5px 20px #26262626" }}>
                        {currentTab == 0 && <>{
                            chatUsers?.map((item) => {
                                if (item?.enable == 0) {
                                    return (
                                        <></>
                                    )
                                }
                                return (
                                    <ChatListComp onPress={() => {
                                        // setSelectedUser(item?.user)
                                        // setSelectedGroup(item?.group)
                                        setSingleData(item)
                                    }} item={item} type="indi" />
                                )
                            })
                        }
                        </>}
                        {currentTab == 1 && groups?.map((item) => {
                            return (<ChatListComp onPress={() => {
                                setSelectedGroup(item?.group)
                            }} item={item} type="group" />)
                        })}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ flex: 2, marginLeft: "5%" }}>
                {(currentTab == 1 && selectedGroup != null) && <GroupChatWindow selectedGroup={selectedGroup} selectedUser={null} />}
                {(currentTab == 0 && singleData != null) && <SingleChatWindow selectedGroup={singleData?.group} selectedGroupDetails={singleData?.groupDetails} selectedUser={singleData?.user} />}
            </Box>
        </Box>
    )
}

const GroupChatWindow = ({ selectedGroup, selectedUser }) => {
    const scrollRef = useRef(null);
    const userMine = useSelector(state => state?.auth?.user)
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([]);
    const [visible, setVisible] = React.useState(false)
    const [selectedImage, setSelectedImage] = React.useState("")
    const [optionModal, setOptionModal] = useState(false)
    const [selectedTxt, setSelectedTxt] = useState(null)
    const [isImage, setIsImage] = useState(false)
    const [editM, setEditM] = useState("")
    const [editId, setEditId] = useState("")
    // group 
    const [group, setGroup] = React.useState(selectedGroup ?? {})
    const [groupDetails, setGroupDetails] = useState(selectedGroup ?? {})

    // const [userOnline, setUserOnline] = React.useState(false)
    // login user
    // room id
    const [roomId, setRoomId] = React.useState(selectedGroup?._id)
    const [userIds, setUserIds] = React.useState([])
    //
    const [gChatFData, setgChatFData] = useState(null)

    const [verification, setVerification] = useState(true)
    const [verifyAll, setVerifyAll] = useState(true)
    const [access, setAccess] = useState(null)
    // chat ref
    const getChatRef = (room) => {
        return doc(firebaseDb, "chats", room)
    }
    const location = useLocation()
    const [selectModal, setSelectModal] = useState(false)

    const clearUnReadCount = (roomIds) => {
        getDoc(getChatRef(roomIds)).then(doc => {
            let prevData = doc.data()
            if (prevData?.unreadCount) {
                updateDoc(getChatRef(roomIds), {
                    unreadCount: {
                        ...prevData?.unreadCount,
                        [`${userMine?._id}`]: 0,
                    }
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (gChatFData && groupDetails?._id) {
            if (gChatFData?.verification) {
                setVerification(true)
            } else if (gChatFData?.verification == false) {
                setVerification(false)
            } else {
                setVerification(true)
            }

            if (gChatFData?.verifyAll) {
                setVerifyAll(true)
            } else if (gChatFData?.verifyAll == false) {
                setVerifyAll(false)
            } else {
                setVerifyAll(false)
            }
            let gaccess = [...groupDetails?.members.map(x => x?._id)]
            if (gChatFData.accessUpdate) {
                let nona = gaccess.filter(x => !gChatFData.access?.map(x => x?._id).includes(x)).map(x => ({ _id: x, access: true }))
                setAccess([...gChatFData.access, ...nona])
            } else {
                if (groupDetails?.members) {
                    setAccess(groupDetails?.members.map(x => ({ _id: x?._id, access: true })))
                }
            }
        }else{
            if (groupDetails?.members) {
                setAccess(groupDetails?.members.map(x => ({ _id: x?._id, access: true })))
            }
        }
    }, [gChatFData, groupDetails])

    useEffect(() => {
        clearUnReadCount(roomId)
        return () => {
            clearUnReadCount(roomId)
        }
    }, [roomId, userMine, group, messages, selectedGroup, location])
    useEffect(() => {
        setRoomId(selectedGroup?._id)
        setGroup(selectedGroup)
        if (selectedGroup?._id) {
            getGroupDetails(selectedGroup?._id)
        }

    }, [userMine, location, selectedGroup])

    const getGroupDetails = async (id) => {
        try {
            let res = await hit("/teams/" + id, "get")
            if (!res.err) {
                setGroupDetails(res.data)
            } else {

            }
        } catch (err) {

        } finally {

        }
    }

    useEffect(() => {
        let sub = onSnapshot(getChatRef(roomId),
            async (querySnapshot) => {
                let d = await getDoc(getChatRef(roomId))
                if (d?.data()?.userIds) {
                    setUserIds(d?.data()?.userIds)
                }
                if (d?.data()) {
                    setgChatFData(d?.data())
                }
            }, // onNext
            (error) => console.error(error), // onError
        )
        return () => {
            sub()
        }
    }, [])
    useEffect(() => {
        setGroup(selectedGroup)
    }, [])
    useEffect(() => {
        if (location?.state?.key) {
            setRoomId(location?.state?.key)
            onSnapshot(getChatRef(location?.state?.key),
                querySnapshot => {
                    setGroup(querySnapshot?.data()?.group)
                })
        }
    }, [location])
    useEffect(() => {
        let unsub = onSnapshot(query(collection(getChatRef(roomId), "messages"), orderBy("createdAt", "desc")),
            (querySnapshot) => {
                let messagesC = []
                let total = []
                let final = []
                for (let doc of querySnapshot.docs) {
                    // for clearing the chats uncomment under one
                    // getChatRef(roomId).collection("messages").doc(doc.id).delete()
                    let title = moment(doc?.data()?.createdAt * 1000).format("Do MMM")
                    if (total.includes(title)) {
                    } else {
                        total.push(title)
                    }
                    messagesC.push({ ...doc?.data(), createdAt: moment(doc?.data()?.createdAt * 1000).toDate(), title: title })
                }
                total.forEach(e => {
                    let newa = messagesC?.filter(x => x?.title == e)
                    newa.reverse()
                    final?.push({ title: e, data: newa })
                });
                final.reverse()
                setMessages(final)

            }, // onNext
            (error) => console.error(error), // onError
        )

        return () => {
            unsub()
            setMessages([])
        }
    }, [roomId])

    const uploadImage = async image => {
        try {
            console.log(image)
            let filename = image.name;
            let v = URL.createObjectURL(image)
            onSend([{ text: "Picture", type: "image", image: v }])
            const storageRef = ref(firebaseStorage, filename);
            await uploadBytes(storageRef, image)
            let url = await getDownloadURL(storageRef)
            updateImageURL(v, url)
        } catch (error) {
            console.log(error)
            alert('Something went wrong while sending your image!');
        }
    };
    const updateImageURL = async (path, url) => {
        console.log("Path>>>", path, url)
        let datas = await getDocs(query(collection(getChatRef(roomId), "messages"), where('image', "==", path)))

        datas.docs.map(docy => {
            updateDoc(doc(getChatRef(roomId), "messages", docy.id), {
                image: url
            })
        })
    }
    const onSend = useCallback((messages = []) => {
        const message = {
            _id: moment().unix(),
            text: messages[0].text,
            type: messages[0].type ?? "text",
            image: messages[0].image ?? "",
            location: messages[0].location ?? {},
            createdAt: moment().unix(),
            approved: (groupDetails?.createdBy?._id == userMine?._id || group?.createdBy == userMine?._id || !verification) ? 1 : 0,
            user: {
                _id: userMine?._id,
                firstName: userMine?.firstName,
                profilePic: userMine?.profilePic
            }
        }
        console.log(message)
        addDoc(collection(getChatRef(roomId), "messages"), message)
        let uids = []
        if (group?.members?.length > 0) {
            if (group?.members[0]?._id) {
                uids = uids.concat(group?.members?.map(x => x._id))
            } else {
                uids = uids.concat(group?.members)
            }
        }
        uids = [...new Set(userIds?.concat([userMine?._id]).concat(uids))]
        let unreadCount = {}
        uids.forEach(x => {
            unreadCount[x] = increment(1)
        })
        unreadCount[userMine?._id] = 0
        setDoc(getChatRef(roomId), {
            type: "group",
            group: group,
            unreadCount,
            lastMessage: message,
            userIds: uids,
        }, { merge: true })
        sendNotification({
            userIds: uids,
            title: userMine?.firstName,
            body: messages[0].type == "image" ? "Image" : messages[0].text.toString(),
            chat_id: roomId,
            notiType: "4"
        })
    }, [roomId, userMine, group, userIds, groupDetails, verification])
    const sendNotification = async (data) => {
        try {
            hit(notifications, "post", data)
        } catch (err) {
            console.log(err)
        }
    }
    const fromGallery = () => {

    }
    const fromCamera = (selected) => {

    }
    const copyIt = async (txt) => {
        // Clipboard.setString(txt)
        showToast("Code copied to your clipboard")
    }
    const messageId = async (type) => {
        setOptionModal(false)
        getDocs(collection(getChatRef(roomId), "messages")).then((querySnapshot) => {
            let temp = []
            temp = querySnapshot.docs
            let x = temp?.filter(x => x?.data()?._id == selectedTxt?._id)
            if (x.length > 0) {
                changeThis(x[0]?.id, type)
            } else {
                return
            }
        })
    }
    const changeThis = (txt, type) => {
        getDocs(collection(getChatRef(roomId), "messages")).then((querySnapshot) => {
            let temp = []
            temp = querySnapshot.docs
            let x = temp?.filter(x => x?.data()?._id == txt?._id)
            let id = x[0]?.id
            if (x?.length > 0) {
                if (type == "delete") {
                    // getChatRef(roomId).collection("messages").doc(id).delete()
                    deleteDoc(doc(getChatRef(roomId), "messages", id))
                } else if (type == "edit") {
                    setEditM(txt?.text)
                    setEditId(id)
                }
            }
        })
    }

    const onAccept = (msg, type) => {

        getDocs(collection(getChatRef(roomId), "messages")).then((querySnapshot) => {
            let temp = []
            temp = querySnapshot.docs
            console.log(JSON.stringify(temp?.map(c => c?.data())))
            let x = temp?.filter(x => x?.data()?._id == msg?._id)
            let id = x[0]?.id
            if (id) {
                if (type == "accept") {
                    // getChatRef(roomId).collection("messages").doc(id).update({
                    //     approved: 1
                    // })
                    updateDoc(doc(getChatRef(roomId), "messages", id), {
                        approved: 1
                    })
                } else {
                    // getChatRef(roomId).collection("messages").doc(id).delete()
                    deleteDoc(doc(getChatRef(roomId), "messages", id))


                }
            }
        })
    }
    const editMessage = (id, txt) => {
        try {
            // getChatRef(roomId).collection("messages").doc(id).set({
            //     text: txt,
            //     updatedAt: moment().unix()
            // }, { merge: true })
            setDoc(doc(getChatRef(roomId), "messages", id), {
                text: txt,
                updatedAt: moment().unix()
            }, { merge: true })
            setEditM("")
        } catch (e) {
            console.log("while editing message", e);
        }
    }

    return (
        <Box sx={{ background: "white", display: "flex", height: "90%", flexDirection: "column", width: "100%", minHeight: "400px", borderRadius: "5px", overflow: "hidden", boxShadow: "0px 5px 20px #26262626" }}>
            <ChatHeader avatarURL={image_url + (selectedGroup?.profilePic ?? groupDetails?.profilePic)} title={selectedGroup?.name ?? groupDetails?.name} />
            <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", flex: 1 }}>
                <ChatBody messages={messages} selectedGroup={selectedGroup} type="group" selectedUser={selectedUser}
                    onAccept={onAccept} verification={verification} verifyAll={verifyAll} groupDetails={groupDetails} group={group} setSelectedImage={setSelectedImage} setVisible={setVisible} copyIt={copyIt} changeThis={changeThis}
                />
            </Box>
            <ChatInput
                value={message}
                onAttachClick={(e) => {
                    if (!access?.find(x => x._id == userMine?._id)?.access) {
                        return
                    }
                    if (e.target.files[0]) {
                        let file = e.target.files[0]
                        if (file?.size <= 10000000) {
                            uploadImage(e.target.files[0])
                        } else {
                            showToast("Image cannot be more than 10mb", "warning")
                        }
                    }

                }}
                onChangeText={t => {
                    if (!access?.find(x => x._id == userMine?._id)?.access) {
                        return
                    }
                    setMessage(t)
                }}
                onPressSend={() => {
                    if (!access?.find(x => x._id == userMine?._id)?.access) {
                        return
                    }
                    if (message.trim() == "") {
                        return
                    }
                    setMessage("")
                    onSend([{ text: message }])
                }}
            />
        </Box>
    )
}

const SingleChatWindow = ({ selectedGroup, selectedUser, selectedGroupDetails }) => {
    const userMine = useSelector(state => state?.auth?.user)
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([]);
    const [visible, setVisible] = React.useState(false)
    const [selectedImage, setSelectedImage] = React.useState("")
    const [selectModal, setSelectModal] = useState(false)
    const [editM, setEditM] = useState("")
    const [editId, setEditId] = useState(null)
    // user 
    const [user, setUser] = React.useState(selectedUser ?? {})
    const [teamId, setTeamId] = React.useState(selectedGroup ?? null)
    const [userOnline, setUserOnline] = React.useState(false)
    // login user
    // room id
    const [roomId, setRoomId] = React.useState([user?._id, userMine?._id, teamId].sort().join("_"))
    // chat ref
    const getChatRef = (room) => {
        return doc(firebaseDb, "chats", room)
    }
    const teams = useSelector(state => state.team)?.team
    const groupChats = useSelector(state => state.groupsChats)?.list
    const [enableChat, setEnableChat] = useState(true)
    const [groupDetails, setGroupDetails] = useState({})

    useEffect(() => {
        if (selectedGroup) {
            getGroupDetails(selectedGroup)
            setTeamId(selectedGroup)
        }
        if (selectedUser) {
            setUser(selectedUser)
        }
    }, [selectedGroup, selectedUser])

    useEffect(() => {
        if (user?._id && userMine?._id && teamId) {
            setRoomId([user?._id, userMine?._id, teamId].sort().join("_"))
        }
    }, [user, userMine, teamId])


    useEffect(() => {
        if (user?._id && userMine?._id) {
            let u1 = user?._id
            let u2 = userMine?._id
            let t = teams.find(x => x._id == teamId)
            if (t?.members?.includes(u1) && t?.members?.includes(u2)) {
                let fgroup = groupChats.find(x => x?.group?._id == t?._id)
                if (fgroup) {
                    let oneOne = fgroup.oneOne
                    let oneAdmin = fgroup.oneAdmin
                    if (oneOne && oneAdmin) {
                        setEnableChat(true)
                    } else {
                        if (t?.admins?.includes(u1) || t?.admins?.includes(u2)) {
                            if (oneAdmin) {
                                setEnableChat(true)
                            } else {
                                setEnableChat(false)
                            }
                        } else {
                            if (oneOne) {
                                setEnableChat(true)
                            } else {
                                setEnableChat(false)
                            }
                        }
                    }
                } else {
                    setEnableChat(true)
                }
            }

        }

    }, [teams, groupChats, user, userMine])

    // useEffect(() => {
    //     if (selectedUser?._id && userMine?._id) {
    //         setRoomId([selectedUser?._id, userMine?._id].sort().join("_"))
    //         setUser(selectedUser)
    //     }
    // }, [userMine, selectedUser])

    const getGroupDetails = async (id) => {
        try {
            let res = await hit("/teams/" + id, "get")
            if (!res.err) {
                setGroupDetails(res.data)
            } else {

            }
        } catch (err) {

        } finally {

        }
    }


    const location = useLocation()
    React.useEffect(() => {
        if (location?.state?.key) {
            setRoomId(location?.state?.key);
            onSnapshot(getChatRef(location?.state?.key)
                , querySnapshot => {
                    let users = []
                    users = querySnapshot?.data()?.users
                    let otherUser = users?.filter(x => x?._id != userMine?._id)
                    setUser(...otherUser)
                })
        }

    }, [location])

    const clearUnReadCount = (roomIds) => {
        getDoc(getChatRef(roomIds)).then(doc => {
            let prevData = doc.data()
            if (prevData?.unreadCount) {
                updateDoc(getChatRef(roomIds), {
                    unreadCount: {
                        [`${userMine?._id}`]: 0,
                        [`${user?._id}`]: prevData?.unreadCount[`${user?._id}`],
                    }
                })
            }

        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        clearUnReadCount(roomId)
        return () => {
            clearUnReadCount(roomId)
        }
    }, [roomId, userMine, user, messages, location])
    // online and offline
    useEffect(() => {
        let unsub = onSnapshot(doc(firebaseDb, "onlines", user?._id),
            (querySnapshot) => {
                let data = querySnapshot?.data()
                if (data?.online) {
                    setUserOnline(true)
                } else {
                    setUserOnline(false)
                }
            },
            (error) => console.log(error)
        )

        return () => {
            unsub()
            setUserOnline(false)

        }
    }, [user])

    useEffect(() => {
        let unsub = onSnapshot(query(collection(getChatRef(roomId), "messages"), orderBy("createdAt", "desc")),
            (querySnapshot) => {
                let messagesC = []
                let total = []
                let final = []
                for (let doc of querySnapshot.docs) {
                    // for clearing the chats uncomment under one
                    // getChatRef(roomId).collection("messages").doc(doc.id).delete()
                    let title = moment(doc?.data()?.createdAt * 1000).format("Do MMM")
                    if (total.includes(title)) {
                    } else {
                        total.push(title)
                    }
                    messagesC.push({ ...doc?.data(), createdAt: moment(doc?.data()?.createdAt * 1000).toDate(), title: title })
                }
                total.forEach(e => {
                    let newa = messagesC?.filter(x => x?.title == e)
                    newa.reverse()
                    final?.push({ title: e, data: newa })
                });
                final.reverse()
                setMessages(final)
            }, // onNext
            (error) => console.error(error), // onError
        )

        return () => {
            unsub()
            setMessages([])
        }
    }, [roomId])

    const onSend = useCallback((messages = []) => {
        const message = {
            _id: moment().unix(),
            text: messages[0].text,
            type: messages[0].type ?? "text",
            image: messages[0].image ?? "",
            location: messages[0].location ?? {},
            createdAt: moment().unix(),
            user: {
                _id: userMine?._id,
                firstName: userMine?.firstName,
                firstName: userMine?.firstName,
                email: userMine?.lastName,
                profilePic: userMine?.profilePic
            }
        }
        // setMessages(previousMessages => [...previousMessages, { ...message, createdAt: new Date() }])
        addDoc(collection(getChatRef(roomId), "messages"), message)
        setDoc(getChatRef(roomId), {
            users: [{ _id: userMine?._id, firstName: userMine?.firstName, lastName: userMine?.lastName, email: userMine?.email, profilePic: userMine?.profilePic }, user],
            unreadCount: {
                [`${userMine?._id}`]: 0,
                [`${user?._id}`]: increment(1),
            },
            userIds: [userMine?._id, user?._id],
            lastMessage: message,
            group: teamId,
            groupDetails: groupDetails,
            type: "single"
        }, { merge: true })
        sendNotification({
            userIds: [user?._id],
            title: userMine?.firstName,
            body: messages[0].type == "image" ? "Image" : messages[0].text.toString(),
            chat_id: roomId,
            notiType: "5"
        })
    }, [roomId, userMine, user, groupDetails, teamId])
    const sendNotification = async (body) => {
        try {
            hit(notifications, "post", body)
        } catch (e) {
        }
    }
    const uploadImage = async image => {
        try {
            console.log(image)
            let filename = image.name;
            let v = URL.createObjectURL(image)
            onSend([{ text: "Picture", type: "image", image: v }])
            const storageRef = ref(firebaseStorage, filename);
            await uploadBytes(storageRef, image)
            let url = await getDownloadURL(storageRef)
            updateImageURL(v, url)
        } catch (error) {
            console.log(error)
            alert('Something went wrong while sending your image!');
        }
    };
    const uploadFile = async file => {
        try {

        } catch (error) {
            console.log(error)
            alert('Something went wrong while sending your image!');
        }
    };
    const updateImageURL = async (path, url) => {
        console.log("Path>>>", path, url)
        let datas = await getDocs(query(collection(getChatRef(roomId), "messages"), where('image', "==", path)))

        datas.docs.map(docy => {
            updateDoc(doc(getChatRef(roomId), "messages", docy.id), {
                image: url
            })
        })
    }



    const fromGallery = () => {
    }

    const copyIt = async (txt) => {
        // Clipboard.setString(txt)
        showToast("Code copied to your clipboard")
    }
    const changeThis = async (txt, type) => {
        getDocs(collection(getChatRef(roomId), "messages")).then((querySnapshot) => {
            let temp = []
            temp = querySnapshot.docs
            let x = temp?.filter(x => x?.data()?._id == txt?._id)
            let id = x[0]?.id
            if (x?.length > 0) {
                if (type == "delete") {
                    deleteDoc(doc(getChatRef(roomId), "messages", id))
                } else if (type == "edit") {
                    setEditM(txt?.text)
                    setEditId(id)
                }
            }
        })
    }
    const onAccept = (msg, type) => {
        getDocs(collection(getChatRef(roomId), "messages")).then((querySnapshot) => {
            let temp = []
            temp = querySnapshot.docs
            let x = temp?.filter(x => x?.data()?._id == msg?._id)
            let id = x[0]?.id

            if (id) {
                if (type == "accept") {
                    // .collection("messages").doc(id).update({
                    //     approved: 1
                    // })
                    updateDoc(doc(getChatRef(roomId), "messages", id), {
                        approved: 1
                    })
                } else {
                    deleteDoc(doc(getChatRef(roomId), "messages", id))
                    // getChatRef(roomId).collection("messages").doc(id).delete()

                }
            }
        })
    }
    const editMessage = (id, txt) => {
        try {
            setDoc(doc(getChatRef(roomId), "messages", id), {
                text: txt,
                updatedAt: moment().unix()
            }, { merge: true })
            setEditM("")
        } catch (e) {
            console.log("while editing message", e);
        }
    }

    return (
        <Box sx={{ background: "white", display: "flex", height: "90%", flexDirection: "column", width: "100%", minHeight: "400px", borderRadius: "5px", overflow: "hidden", boxShadow: "0px 5px 20px #26262626" }}>
            <ChatHeader avatarURL={image_url + selectedUser?.profilePic} title={`(${groupDetails?.name ?? ""}) ` + selectedUser?.firstName} />
            <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", flex: 1 }}>
                <ChatBody onAccept={onAccept} messages={messages} selectedGroup={selectedGroup} type="group" selectedUser={selectedUser}
                    groupDetails={groupDetails} setSelectedImage={setSelectedImage} setVisible={setVisible} copyIt={copyIt} changeThis={changeThis}
                />
            </Box>
            <ChatInput
                value={message}
                onAttachClick={(e) => {
                    // if (!access?.find(x => x._id == userMine?._id)?.access) {
                    //     return
                    // }
                    if (!enableChat) {
                        return
                    }
                    if (e.target.files[0]) {
                        let file = e.target.files[0]
                        if (file?.size <= 10000000) {
                            uploadImage(e.target.files[0])
                        } else {
                            showToast("Image cannot be more than 10mb", "warning")
                        }
                    }

                }}
                onChangeText={t => {
                    if (!enableChat) {
                        return
                    }
                    setMessage(t)
                }}
                onPressSend={() => {
                    if (!enableChat) {
                        return
                    }
                    if (message?.trim()?.length == 0) {
                        return
                    }
                    setMessage("")
                    onSend([{ text: message }])
                }}
            />
        </Box>
    )
}


const ChatListComp = ({ item, onPress, type }) => {
    const userMe = useSelector(state => state?.auth)?.user

    return (
        <Box onClick={onPress} sx={{ cursor: "pointer", px: "10px", borderRadius: "5px", display: "flex", marginTop: "15px", alignItems: "center", boxShadow: "0px 5px 20px #26262626", }}>
            <Box sx={{ display: "flex", flex: 1, alignItems: "center", paddingY: "15px" }}>

                <Avatar
                    src={image_url + item?.user?.profilePic}
                    style={{ height: "40px", width: "40px" }}
                />
                <Box sx={{ marginLeft: "10px", flex: 1, display: "flex", textAlign: "left", flexDirection: "column", alignItems: "flex-start" }}>
                    {/*  {item?.user?.lastName} */}
                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(10.5) }}>{type != "group" ? `(${item?.groupDetails?.name}) ` : ""}{item?.user?.firstName}</Typography>
                    <Typography sx={{ fontSize: pxToRem(9), maxHeight: "25px", overflow: "hidden" }}>{item?.lastMessage?.user?._id == userMe?._id && "Me: "}{item?.lastMessage?.text}</Typography>
                </Box>
                <Box sx={{}}>
                    <Typography sx={{ fontSize: pxToRem(7), fontWeight: "600" }}>{moment(item?.lastMessage?.createdAt * 1000).fromNow()}</Typography>
                    {item?.unreadCount > 0 && <Badge badgeContent={item?.unreadCount} color="primary" />}
                </Box>
            </Box>
        </Box>
    )
}

const ChatHeader = ({ avatarURL, title }) => {
    return (
        <Box sx={{ height: "50px", px: "20px", background: "#0DB1D4", display: "flex", alignItems: "center", width: "100%" }}>
            <Box sx={{ display: "flex", flex: 1, alignItems: "center", }}>
                <Avatar
                    src={avatarURL}
                    sx={{ height: "40px", width: "40px" }}
                />
                <Typography sx={{ fontWeight: "600", marginLeft: "15px", color: "white", fontSize: pxToRem(12) }}>{title}</Typography>
            </Box>
        </Box>
    )
}

const ChatBody = ({ messages, onAccept, verification, verifyAll, groupDetails, group, setSelectedImage, setVisible, copyIt, changeThis }) => {
    const userMine = useSelector(state => state?.auth?.user)
    const divRef = useRef()
    const scrollingBottom = () => {
        const e = divRef;

        e.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
        });
    };

    useEffect(() => {
        scrollingBottom();
    }, [messages]);
    return (<Box sx={{ display: "flex", flexDirection: "column", flex: 1, height: "100%" }}>
        {messages?.map(section =>
            <>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(16), textAlign: "center", marginY: "10px" }}>{section?.title}</Typography>
                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "flex-end", }}>
                    {section?.data?.map(item => {
                        return (
                            <ChatView data={item} onAccept={onAccept} isVerification={verification} verifyAll={verifyAll}
                                isAdmin={(groupDetails?.createdBy?._id == userMine?._id || group?.createdBy == userMine?._id)}
                                user={item?.user}
                                onImageClick={(link) => {
                                    setSelectedImage(link)
                                    setVisible(true)
                                }}
                                copyPressed={(data) => {
                                    copyIt(data)
                                }}
                                editPressed={(data) => {
                                    changeThis(data, "edit")
                                }}
                                deletePressed={(data) => {
                                    changeThis(data, "delete")
                                }}
                            // onLongPress={() => {
                            //     if (item?.image != "") {
                            //         setIsImage(true)
                            //     } else {
                            //         setIsImage(false)
                            //     }
                            //     setSelectedTxt(item);
                            //     setOptionModal(true);
                            // }}
                            />)
                    })}

                </Box>
            </>)}
        <div ref={divRef} />
    </Box>
    )
}




const ChatInput = ({ value, onChangeText, onPressSend, onAttachClick }) => {
    return (
        <Box sx={{ height: "50px", marginX: "10px", display: "flex", alignItems: "center", marginY: "5px", border: "1.5px solid #0DB1D433", borderRadius: "50px" }}>
            <TextField variant="standard"
                placeholder={"Enter your message here"}
                value={value}
                onChange={e => onChangeText(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                    justifyContent: 'center',
                    sx: [{ fontSize: "14px", fontWeight: "500", height: "50px", }],
                }} sx={{ borderColor: "white", display: "flex", flex: 1, px: "10px" }} />
            <Box sx={{ height: "40px", width: "40px", display: "flex", position: "relative", justifyContent: "center", alignItems: "center", background: "rgb(207,239,246)", borderRadius: "40px" }}>
                <Box component={"img"} src="assets/chat/attach.svg" />
                <input type="file" id="file-selector" accept="image/png, image/jpeg" onChange={(e) => onAttachClick(e)} style={{ cursor: "pointer", height: "40px", width: "40px", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
            </Box>
            <Box onClick={onPressSend} sx={{ height: "40px", cursor: "pointer", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(13,177,212)", marginX: "10px", borderRadius: "40px" }}>
                <Box component={"img"} src="assets/chat/send.svg" />
            </Box>
        </Box>
    )
}

const ChatView = ({ data, user, onImageClick, onLongPress, onAccept, copyPressed, editPressed, deletePressed, isAdmin, isVerification, verifyAll }) => {
    const userMine = useSelector(state => state?.auth?.user)
    const [visible, setVisible] = useState(false)
    let msg = data
    let isMine = msg?.user?._id == userMine?._id
    const dispatch = useDispatch()


    const showFile = (fileUrl) => {
        window.open(fileUrl, "_blank")

    };
    if (msg?.type == "document") {
        return (
            <>
                <DeleteModal
                    open={visible}
                    title={"Do you want to delete this message?"}
                    handleClose={() => {
                        setVisible(false)
                    }}
                    onPressNo={() => setVisible(false)}
                    onPressOut={() => setVisible(false)}
                    onPressYes={() => deletePressed(data)}
                />
                {isMine ?
                    <Box sx={{ paddingX: "16px", marginBottom: "12px", alignItems: 'flex-end', position: "relative" }}>
                        <Box sx={{ display: "flex", flexDirection: 'row', maxWidth: "80%", justifyContent: 'flex-end' }}>
                            {/* <Popover
                                placement={Placement.FLOATING}
                                isVisible={visible}
                                onRequestClose={() => setVisible(false)}
                                from={ */}
                            <Box
                                onClick={() => {
                                    // onImageClick(msg?.image)
                                    showFile(msg?.image)
                                }}
                                onLongPress={() => setVisible(true)}

                            >
                                {/* required */}
                                {/* <FastImage
                                    source={require("../assets/document.jpeg")}
                                    // resizeMode="contain"
                                    style={{ height: widthPercentageToDP(25), width: widthPercentageToDP(30), borderRadius: 5 }}
                                /> */}
                                <Typography style={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: 6, right: 6 }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                                <Typography style={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: "12vw", right: "10vw" }}>Document</Typography>

                            </Box>
                            <Box component={"img"} onClick={() => {
                                setVisible(true)
                            }} src="assets/post/dots.png" sx={{ height: "20px", cursor: "pointer", marginLeft: "5px", width: "10px", objectFit: "contain" }} />
                            {/*  }
                           >  <View style={{ width: 300, paddingVertical: 16, borderRadius: 6 }}>
                                    <Image source={require("../assets/deletegif.gif")} style={{ height: 100, width: 100, alignSelf: "center", resizeMode: "contain" }} />
                                    <Text style={{ textAlign: "center", fontFamily: fonts.MontBold, color: appcolors.txt, fontSize: 18 }}>Delete Document</Text>
                                    <Text style={{ textAlign: "center", fontFamily: fonts.MontMed, color: appcolors.txt, fontSize: 14, marginVertical: 10, lineHeight: 20 }}>Are you sure you want to delete{"\n"}this document?</Text>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: 260, alignSelf: "center" }}>
                                        <CustomButton
                                            title={"Delete"}
                                            styleBtn={{ color: appcolors.white, backgroundColor: appcolors.red, width: 100, marginTop: 8 }}
                                            styleTxt={{ fontFamily: fonts.MontBold }}
                                            onPress={() => {
                                                setVisible(false)
                                                deletePressed(data)
                                            }}
                                        />
                                        <CustomButton
                                            title={"Cancel"}
                                            styleBtn={{ color: appcolors.white, width: 100, marginTop: 8 }}
                                            styleTxt={{ fontFamily: fonts.MontBold }}
                                            onPress={() => {
                                                setVisible(false)
                                            }}
                                        />
                                    </View>
                                </View> */}
                            {/* </Popover> */}
                            <Box component={"img"} src={image_url + userMine?.profilePic} style={{ height: "20px", width: "20px", borderRadius: "16px", marginLeft: "10px" }} />
                        </Box>
                    </Box>
                    :
                    <Box>
                        {!isAdmin && <Box sx={{ display: "flex", flexDirection: 'row', paddingX: "16px", marginBottom: "12px" }}>
                            <Box component={"img"} src={image_url + user?.profilePic} style={{ height: "20px", width: "20px", borderRadius: "16px", marginRight: "10px" }} />
                            <Box onClick={() => {
                                // onImageClick(msg?.image)
                                if (msg?.approved == 0 && !isAdmin && isVerification) {
                                    return
                                }
                                dispatch(setLoading(false))
                                showFile(msg?.image)
                            }}
                            >
                                {/* need to add document image */}
                                {/* <FastImage
                                    source={{ uri: require("../assets/document.jpeg") }}
                                    style={{ height: widthPercentageToDP(35), width: widthPercentageToDP(35), borderRadius: 5 }}
                                /> */}
                                <Typography sx={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: 6, right: 6 }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                            </Box>
                        </Box>}
                        {isAdmin &&
                            <Box sx={{ paddingX: "16px", marginBottom: "12px", alignItems: 'flex-end' }}>
                                <Box sx={{ display: "flex", flexDirection: 'row', maxWidth: "80%", justifyContent: 'flex-end' }}>
                                    {/* <Popover
                                        placement={Placement.FLOATING}
                                        isVisible={visible}
                                        onRequestClose={() => setVisible(false)}
                                        from={ */}
                                    <Box
                                        onClick={() => {
                                            // onImageClick(msg?.image)
                                            showFile(msg?.image)
                                        }}
                                        onLongPress={() => setVisible(true)}>
                                        {/* <FastImage
                                                    source={require("../assets/document.jpeg")}
                                                    // resizeMode="contain"
                                                    style={{ height: widthPercentageToDP(25), width: widthPercentageToDP(30), borderRadius: 5 }}
                                                /> */}
                                        <Typography style={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: 6, right: 6 }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                                        <Typography style={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: "12vw", right: "10vw" }}>Document</Typography>

                                    </Box>
                                    <Box component={"img"} onClick={() => {
                                        setVisible(true)
                                    }} src="assets/post/dots.png" sx={{ height: "20px", cursor: "pointer", marginLeft: "5px", width: "10px", objectFit: "contain" }} />
                                    {/* } > */}
                                    {/* <View style={{ width: 300, paddingVertical: 16, borderRadius: 6 }}>
                                            <Image source={require("../assets/deletegif.gif")} style={{ height: 100, width: 100, alignSelf: "center", resizeMode: "contain" }} />
                                            <Text style={{ textAlign: "center", fontFamily: fonts.MontBold, color: appcolors.txt, fontSize: 18 }}>Delete Document</Text>
                                            <Text style={{ textAlign: "center", fontFamily: fonts.MontMed, color: appcolors.txt, fontSize: 14, marginVertical: 10, lineHeight: 20 }}>Are you sure you want to delete{"\n"}this document?</Text>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: 260, alignSelf: "center" }}>
                                                <CustomButton
                                                    title={"Delete"}
                                                    styleBtn={{ color: appcolors.white, backgroundColor: appcolors.red, width: 100, marginTop: 8 }}
                                                    styleTxt={{ fontFamily: fonts.MontBold }}
                                                    onPress={() => {
                                                        setVisible(false)
                                                        deletePressed(data)
                                                    }}
                                                />
                                                <CustomButton
                                                    title={"Cancel"}
                                                    styleBtn={{ color: appcolors.white, width: 100, marginTop: 8 }}
                                                    styleTxt={{ fontFamily: fonts.MontBold }}
                                                    onPress={() => {
                                                        setVisible(false)
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </Popover> */}
                                    <Box src={image_url + userMine?.profilePic} style={{ height: "20px", width: "20px", borderRadius: "16px", marginLeft: "10px" }} />
                                </Box>
                            </Box>

                        }
                        {(isAdmin && isVerification && msg.approved == 0) && <Box sx={{ display: "flex", flexDirection: "row", width: "20vw", paddingX: "46px", marginBottom: "10px", marginTop: -5, justifyContent: "space-between" }}>
                            <Box
                                onClick={() => {
                                    onAccept(msg, "reject")
                                }}
                                sx={{ height: "35px", width: "60px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px", background: "red" }}>
                                <Typography sx={{ color: "white", fontSize: "12px" }}>Reject</Typography>
                            </Box>
                            <Box onClick={() => {
                                onAccept(msg, "accept")

                            }} sx={{ height: "35px", width: "60px", cursor: "pointer", alignItems: "center", display: "flex", marginLeft: "10px", justifyContent: "center", borderRadius: "10px", backgroundColor: "#09ab34" }}>
                                <Typography style={{ color: "white", fontSize: "12px" }}>Accept</Typography>
                            </Box>
                        </Box>}
                    </Box>
                }

            </>
        );
    }
    if (msg?.type == "image") {
        return (
            <>
                <DeleteModal
                    open={visible}
                    title={"Do you want to delete this message?"}
                    handleClose={() => {
                        setVisible(false)
                    }}
                    onPressNo={() => setVisible(false)}
                    onPressOut={() => setVisible(false)}
                    onPressYes={() => deletePressed(data)}
                />
                {isMine ?
                    <Box sx={{ paddingX: "16px", display: "flex", flexDirection: "column", marginBottom: "12px", alignItems: 'flex-end' }}>
                        <Box sx={{ flexDirection: 'row', display: 'flex', maxWidth: "80%", justifyContent: 'flex-end' }}>
                            {/* <Popover
                                placement={Placement.FLOATING}
                                isVisible={visible}
                                onRequestClose={() => setVisible(false)}
                                from={ */}
                            <Box
                                onClick={() => {
                                    showFile(msg?.image)
                                }}
                                onLongPress={() => setVisible(true)}>
                                <Box
                                    component={"img"}
                                    src={msg?.image}
                                    style={{ height: "20vw", width: "20vw", borderRadius: "5px", objectFit: "cover" }}
                                />
                                <Typography style={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: 6, right: 6 }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                            </Box>
                            <Box component={"img"} onClick={() => {
                                setVisible(true)
                            }} src="assets/post/dots.png" sx={{ height: "20px", cursor: "pointer", marginLeft: "5px", width: "10px", objectFit: "contain" }} />
                            {/* }
                            >
                                <View style={{ width: 300, paddingVertical: 16, borderRadius: 6 }}>
                                    <Image source={require("../assets/deletegif.gif")} style={{ height: 100, width: 100, alignSelf: "center", resizeMode: "contain" }} />
                                    <Text style={{ textAlign: "center", fontFamily: fonts.MontBold, color: appcolors.txt, fontSize: 18 }}>Delete Image</Text>
                                    <Text style={{ textAlign: "center", fontFamily: fonts.MontMed, color: appcolors.txt, fontSize: 14, marginVertical: 10, lineHeight: 20 }}>Are you sure you want to delete{"\n"}this imgae?</Text>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: 260, alignSelf: "center" }}>
                                        <CustomButton
                                            title={"Delete"}
                                            styleBtn={{ color: appcolors.white, backgroundColor: appcolors.red, width: 100, marginTop: 8 }}
                                            styleTxt={{ fontFamily: fonts.MontBold }}
                                            onPress={() => {
                                                setVisible(false)
                                                deletePressed(data)
                                            }}
                                        />
                                        <CustomButton
                                            title={"Cancel"}
                                            styleBtn={{ color: appcolors.white, width: 100, marginTop: 8 }}
                                            styleTxt={{ fontFamily: fonts.MontBold }}
                                            onPress={() => {
                                                setVisible(false)
                                            }}
                                        />
                                    </View>
                                </View>
                            </Popover> */}
                            <Box component={"img"} src={image_url + userMine?.profilePic} style={{ height: "20px", width: "20px", objectFit: "cover", borderRadius: "16px", marginLeft: "10px" }} />
                        </Box>

                    </Box>
                    :
                    <Box>
                        {isAdmin &&
                            <Box sx={{ paddingX: "16px", marginBottom: "12px", flexDirection: "column", display: "flex", alignItems: 'flex-start' }}>
                                <Box sx={{ flexDirection: 'row', display: "flex", maxWidth: "80%", justifyContent: 'flex-start' }}>
                                    <Box src={image_url + user?.profilePic} component="img" sx={{ height: "20px", width: "20px", objectFit: "cover", borderRadius: "16px", marginRight: "10px" }} />

                                    {/* <Popover
                                        placement={Placement.FLOATING}
                                        isVisible={visible}
                                        onRequestClose={() => setVisible(false)}
                                        from={ */}
                                    <Box
                                        onClick={() => {
                                            showFile(msg?.image)
                                        }}
                                        onLongPress={() => setVisible(true)}>
                                        <Box
                                            component={"img"}
                                            src={msg?.image}
                                            sx={{ height: "20vw", width: "20vw", borderRadius: "5vw", objectFit: "cover" }}
                                        />
                                        <Typography sx={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: 6, right: 6 }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                                    </Box>
                                    <Box component={"img"} onClick={() => {
                                        setVisible(true)
                                    }} src="assets/post/dots.png" sx={{ height: "20px", cursor: "pointer", marginLeft: "5px", width: "10px", objectFit: "contain" }} />
                                    {/* }
                                    >
                                        <View style={{ width: 300, paddingVertical: 16, borderRadius: 6 }}>
                                            <Image source={require("../assets/deletegif.gif")} style={{ height: 100, width: 100, alignSelf: "center", resizeMode: "contain" }} />
                                            <Text style={{ textAlign: "center", fontFamily: fonts.MontBold, color: appcolors.txt, fontSize: 18 }}>Delete Image</Text>
                                            <Text style={{ textAlign: "center", fontFamily: fonts.MontMed, color: appcolors.txt, fontSize: 14, marginVertical: 10, lineHeight: 20 }}>Are you sure you want to delete{"\n"}this imgae?</Text>
                                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around", width: 260, alignSelf: "center" }}>
                                                <CustomButton
                                                    title={"Delete"}
                                                    styleBtn={{ color: appcolors.white, backgroundColor: appcolors.red, width: 100, marginTop: 8 }}
                                                    styleTxt={{ fontFamily: fonts.MontBold }}
                                                    onPress={() => {
                                                        setVisible(false)
                                                        deletePressed(data)
                                                    }}
                                                />
                                                <CustomButton
                                                    title={"Cancel"}
                                                    styleBtn={{ color: appcolors.white, width: 100, marginTop: 8 }}
                                                    styleTxt={{ fontFamily: fonts.MontBold }}
                                                    onPress={() => {
                                                        setVisible(false)
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </Popover> */}
                                </Box>

                            </Box>
                        }
                        {!isAdmin && <Box sx={{ flexDirection: 'row', display: "flex", paddingX: "16px", marginBottom: "12px" }}>
                            <Box component={"img"} src={image_url + user?.profilePic} sx={{ height: "20px", width: "20px", objectFit: "cover", borderRadius: "16px", marginRight: "10px" }} />
                            <Box onClick={() => {
                                if (msg?.approved == 0 && !isAdmin && isVerification) {
                                    return
                                }
                                showFile(msg?.image)
                            }}
                            >
                                <Box
                                    component={"img"}
                                    src={(isAdmin || !isVerification) ? msg?.image : (msg.approved == 0 ? image_url + "/public/cover.png" : msg?.image)}
                                    sx={{ height: "20vw", width: "20vw", borderRadius: "5px", objectFit: "cover" }}
                                />

                                <Typography sx={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", position: "absolute", zIndex: 10, bottom: 6, right: 6 }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                            </Box>

                        </Box>}
                        {(isAdmin && (isVerification || verifyAll) && msg.approved == 0) && <Box sx={{ flexDirection: "row", display: "flex", width: "20vw", paddingX: "46px", marginBottom: "10px", justifyContent: "space-between", marginTop: "-5px", }}>
                            <Box
                                onClick={() => {
                                    onAccept(msg, "reject")
                                }}
                                sx={{ height: "35px", width: "60px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px", background: "red" }}>
                                <Typography sx={{ color: "white", fontSize: "12px" }}>Reject</Typography>
                            </Box>
                            <Box onClick={() => {
                                onAccept(msg, "accept")

                            }} sx={{ height: "35px", width: "60px", display: "flex", cursor: "pointer", alignItems: "center", marginLeft: "10px", justifyContent: "center", borderRadius: "10px", background: "#09ab34" }}>
                                <Typography sx={{ color: "white", fontSize: "12px" }}>Accept</Typography>
                            </Box>
                        </Box>}
                    </Box>
                }

            </>
        );
    }
    if (msg?.type == "wish") {
        return (
            <>
                <Box sx={{ paddingX: "16px", paddingY: "4px" }}>
                    <Box sx={{ backgroundColor: "rgba(0,0,0,1)", height: "160px", width: "160px", display: "flex", alignItems: 'center', justifyContent: 'center', borderRadius: "4px" }}>
                        <Box component={"img"} src={image_url + msg?.image} style={{ height: "150px", objectFit: "cover", width: "150px", borderRadius: "4px" }} />
                    </Box>
                    <Typography sx={{ marginTop: "4px", fontWeight: "500" }}>{msg?.text}</Typography>
                </Box>
            </>
        )
    }
    return (
        <>
            <DeleteModal
                open={visible}
                title={"Do you want to delete this message?"}
                handleClose={() => {
                    setVisible(false)
                }}
                onPressNo={() => setVisible(false)}
                onPressOut={() => setVisible(false)}
                onPressYes={() => deletePressed(data)}
            />
            {isMine ?
                <Box sx={{ paddingX: "16px", marginBottom: "12px", display: "flex", flexDirection: "column", alignItems: 'flex-end' }}>
                    <Box sx={{ display: "flex", flexDirection: 'row', maxWidth: "80%", justifyContent: 'flex-end' }}>
                        {/* <Popover
                       isVisible={visible}
                       placement={Placement.FLOATING}
                       onRequestClose={() => setVisible(false)}
                       from={ */}
                        <Box
                            sx={{ backgroundColor: "#BAF3FF", paddingX: "16px", paddingY: "8px", borderRadius: "20px", borderTopRightRadius: "0px" }}
                            onLongPress={() => setVisible(true)}
                        >
                            <Typography sx={{ fontWeight: "500", fontSize: "12px", }}>{msg?.text}</Typography>
                            <Typography sx={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", marginTop: "2px" }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                        </Box>
                        <Box component={"img"} onClick={() => {
                            setVisible(true)
                        }} src="assets/post/dots.png" sx={{ height: "20px", cursor: "pointer", marginLeft: "5px", width: "10px", objectFit: "contain" }} />
                        {/* }>
                       <View style={{ width: 200, paddingVertical: 16, alignItems: "center", borderRadius: 8 }}>
                           <CustomButton
                               title={"Copy"}
                               styleBtn={{ color: appcolors.white, backgroundColor: appcolors.yellow, width: "86%" }}
                               styleTxt={{ fontFamily: fonts.MontBold }}
                               onPress={() => {
                                   setVisible(false)
                                   copyPressed(data?.text)
                               }}
                           />
                           <CustomButton
                               title={"Edit"}
                               styleBtn={{ color: appcolors.white, width: "86%", marginTop: 8 }}
                               styleTxt={{ fontFamily: fonts.MontBold }}
                               onPress={() => {
                                   setVisible(false)
                                   editPressed(data)
                               }}
                           />
                           <CustomButton
                               title={"Delete"}
                               styleBtn={{ color: appcolors.white, backgroundColor: appcolors.red, width: "86%", marginTop: 8 }}
                               styleTxt={{ fontFamily: fonts.MontBold }}
                               onPress={() => {
                                   setVisible(false)
                                   deletePressed(data)
                               }}
                           />
                       </View>
                   </Popover> */}
                        <Box component={"img"} src={image_url + userMine?.profilePic} style={{ height: "20px", objectFit: "cover", width: "20px", borderRadius: "16px", marginLeft: "10px" }} />
                    </Box>

                </Box>
                :
                <>
                    {isAdmin &&
                        <Box sx={{ paddingX: "16px", marginBottom: "12px", display: "flex", flexDirection: "column", alignItems: 'flex-start' }}>
                            <Box sx={{ display: "flex", flexDirection: 'row', maxWidth: "80%", justifyContent: 'flex-start' }}>
                                <Box src={image_url + user?.profilePic} component="img" sx={{ height: "20px", objectFit: "cover", width: "20px", borderRadius: "16px", marginRight: "10px" }} />
                                {/* <Popover
                                    isVisible={visible}
                                    placement={Placement.FLOATING}
                                    onRequestClose={() => setVisible(false)}
                                    from={ */}
                                <Box onLongPress={() => setVisible(true)} sx={{ maxWidth: '80%', }}>
                                    <Typography sx={{ fontWeight: "500", fontSize: "10px", marginBottom: "8px" }}>{msg?.user?.firstName}</Typography>
                                    <Box sx={{ backgroundColor: "#D8F8FE", minWidth: "20%", paddingX: "16px", paddingY: "6px", borderRadius: "20px", borderTopLeftRadius: "0px" }}>
                                        <Typography sx={{ fontWeight: "500", fontSize: "12px", }}>{msg?.text}</Typography>
                                        <Typography sx={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", marginTop: "2px" }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                                    </Box>
                                </Box>
                                <Box component={"img"} onClick={() => {
                                    setVisible(true)
                                }} src="assets/post/dots.png" sx={{ height: "20px", cursor: "pointer", marginLeft: "5px", width: "10px", objectFit: "contain" }} />
                                {/* }>
                                    <View style={{ width: 200, paddingVertical: 16, alignItems: "center", borderRadius: 8 }}>
                                        <CustomButton
                                            title={"Copy"}
                                            styleBtn={{ color: appcolors.white, backgroundColor: appcolors.yellow, width: "86%" }}
                                            styleTxt={{ fontFamily: fonts.MontBold }}
                                            onPress={() => {
                                                setVisible(false)
                                                copyPressed(data?.text)
                                            }}
                                        />
                                        <CustomButton
                                            title={"Delete"}
                                            styleBtn={{ color: appcolors.white, backgroundColor: appcolors.red, width: "86%", marginTop: 8 }}
                                            styleTxt={{ fontFamily: fonts.MontBold }}
                                            onPress={() => {
                                                setVisible(false)
                                                deletePressed(data)
                                            }}
                                        />
                                    </View>
                                </Popover> */}
                            </Box>
                            {(isAdmin && verifyAll && msg.approved == 0) && <Box sx={{ flexDirection: "row", display: "flex", width: "20vw", paddingX: "30px", marginBottom: "10px", marginTop: "8px", justifyContent: "space-between", }}>
                                <Box
                                    onClick={() => {
                                        onAccept(msg, "reject")
                                    }}
                                    sx={{ height: "35px", "cursor": "pointer", width: "60px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px", background: "red" }}>
                                    <Typography sx={{ color: "white", fontSize: "12px" }}>Reject</Typography>
                                </Box>
                                <Box onClick={() => {
                                    onAccept(msg, "accept")

                                }} sx={{ height: "35px", cursor: "pointer", width: "60px", display: "flex", alignItems: "center", marginLeft: "10px", justifyContent: "center", borderRadius: "10px", background: "#09ab34" }}>
                                    <Typography sx={{ color: "white", fontSize: "12px" }}>Accept</Typography>
                                </Box>
                            </Box>}
                        </Box>
                    }
                    {!isAdmin && <Box sx={{ flexDirection: 'row', display: "flex", paddingX: "16px", marginBottom: "12px" }}>
                        <Box component={"img"} src={image_url + user?.profilePic} sx={{ height: "20px", objectFit: "cover", width: "20px", borderRadius: "16px", marginRight: "10px" }} />
                        <Box sx={{ maxWidth: '80%', }}>
                            <Typography sx={{ fontWeight: "500", fontSize: "10px", marginBottom: "8px", textAlign: "left" }}>{msg?.user?.firstName}</Typography>
                            <Box sx={{ backgroundColor: "#D8F8FE", minWidth: "20%", paddingX: "16px", paddingY: "6px", borderRadius: "20px", borderTopLeftRadius: "0px" }}>
                                <Typography sx={{ fontWeight: "500", fontSize: "12px", }}>{(verifyAll && msg.approved == 0) ? "..." : msg?.text}</Typography>
                                <Typography sx={{ fontWeight: "500", fontSize: "8.6px", textAlign: "right", marginTop: "2px" }}>{moment(msg?.createdAt).format("hh:mm")}</Typography>
                            </Box>
                        </Box>
                    </Box>}

                </>
            }

        </>
    );
}