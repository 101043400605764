import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { pxToRem } from "../../theme/typography";
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { image_url } from "../../services/endpoints";
import { useNavigate } from "react-router-dom";
import { setSelectedTeam } from "../../store/team";

export default function GroupSwipper({teams,user}) {
    const theme = useTheme()
    const [currentIndex, setCurrentIndex] = useState(0)
    const groups = useSelector(state => state?.team?.team)
    const caraouselRef=useRef(null)
    const navigate=useNavigate()
    const dispatch=useDispatch()
    return (
        <Box >
            <Box sx={{ boxShadow: "0px 5px 20px #26262626",borderRadius:"10px" ,overflow:"hidden",background:"white"}}>
                {!teams&&<Carousel onChange={(index) => {
                    setCurrentIndex(index)
                }} showIndicators={false} 
                    showStatus={false} 
                    ref={caraouselRef} showThumbs={false} id="carou" dynamicHeight={false} width={"33vw"}>
                    { groups.map(g => {
                        // console.log(caraouselRef?.current)
                       return(<PointBlock  key={g?._id} item={g} index={0} onPress={i => {
                        dispatch(setSelectedTeam(g))
                        navigate("/user/scoreboard")
                        }} />)
                    })}
                </Carousel>}
                {teams?.length>0&&
                <Carousel onChange={(index) => {
                    setCurrentIndex(index)
                }} showIndicators={false} 
                    showStatus={false} 
                    ref={caraouselRef} showThumbs={false} id="carou" dynamicHeight={false} width={"33vw"}>
                    { teams.map(g => {
                        // console.log(caraouselRef?.current)
                       return(<PointBlock  userData={user} key={g?._id} item={g} index={0} onPress={i => {

                        }} />)

                    })}
                   

                </Carousel>
                }
            </Box>
            <PaginationComp groups={groups} currentIndex={currentIndex} />
        </Box>

    )
}



const PaginationComp = ({ currentIndex ,groups}) => {
    return (
        <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "center", alignItems: "center" }}>
            {groups?.map((x,i)=>{
                return <Box sx={{ height: "10px", width: "10px", borderRadius: "5px",marginRight:"5px", background: currentIndex == i ? "rgb(245,186,23)" : "rgb(246,232,185)" }} />
            })}
         </Box>
    )
}

const PointBlock = ({ item, index, onPress,userData }) => {
    const scores = useSelector(state => state.scores)?.list
    const [user,setUser]=useState(userData)
    const userState = useSelector(state => state?.auth?.user)
    const [totalPoint, setTotalPoints] = useState(0)
    const [averagePoint, setAveragePoint] = useState(0)
    const [role, setRole] = useState("user")
    const theme = useTheme()
    useEffect(()=>{
        if(userData){
            setUser(userData)
        }else{
            setUser(userState)
        }
    },[,userState,userData])
    useEffect(() => {
        if (scores?.length > 0) {
            let filteredList = scores?.filter(x => x.groups?.includes(item?._id))
            let filteredList1 = scores?.filter(x => x.groups?.includes(item?._id))?.filter(x=>x.user?._id==user?._id)
            
            let c = 0
            for (let i of filteredList) {
                c += i?.point
            }
            let c1=0
            for (let i of filteredList1) {
                c1 += i?.point
            }
            setTotalPoints(c1)
            let totalMembers = (item?.members?.length+item?.ableToScores?.length - item?.admins?.length)
            if (totalMembers != 0) {
                let avg = 0
                avg = Math.round(c / totalMembers)
                setAveragePoint(avg)
            } else {
                setAveragePoint(c)
            }
        } else {
            setTotalPoints(0)
        }
    }, [scores])
    useEffect(() => {
        if (item?.ableToScores) {
            let a = item?.ableToScores?.includes(user?._id)
            if (a) {
                setRole("scoringadmin")
                return
            } else {
                let a = item?.admins?.includes(user?._id)
                if (a) {
                    setRole("admin")
                }
            }
        }
    }, [item, user])

    return (
        <div style={{ position: "relative", }}>
            <Box onClick={() => {
                if (onPress) {
                    onPress(item)
                }
            }} sx={{ display: "flex",cursor:"pointer", height: "100%", alignItems: "center", background: "white", p: "20px", py: "15px", borderRadius: "5px" }}>
                <Avatar
                    src={image_url + item?.profilePic}
                    style={{ height: "60px", width: "60px" }}
                />
                <Box sx={{ marginLeft: "15px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{item?.name}</Typography>
                    {role == "user" ?
                        <Typography sx={{ fontSize: pxToRem(18) }} color="primary">{totalPoint} <Typography sx={{ fontSize: "12px", color: theme.palette.text.primary }} component={"span"}>Total Points</Typography></Typography>
                        : (
                            role == "admin" ? <Typography sx={{ fontSize: pxToRem(18) }} color="primary">{averagePoint} <Typography sx={{ fontSize: "12px", color: theme.palette.text.primary }} component={"span"}>Average Points</Typography></Typography>
                                : <>
                                    <Typography sx={{ fontSize: pxToRem(18) }} color="primary">{totalPoint} <Typography sx={{ fontSize: "12px", color: theme.palette.text.primary }} component={"span"}>Total Points</Typography></Typography>
                                    <Typography sx={{ fontSize: pxToRem(18) }} color="primary">{averagePoint} <Typography sx={{ fontSize: "12px", color: theme.palette.text.primary }} component={"span"}>Average Points</Typography></Typography>
                                </>
                        )
                    }
                </Box>
            </Box>
        </div>
    )
}