import { Avatar, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import PointsComp from "../components/ScoreBoard/PointsComp";
import hit from "../services/apiManager";
import { image_url, subgroups, teams } from "../services/endpoints";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import _ from 'lodash'
import SearchBar from "../components/Home/SearchBar";
export default function SubGroup() {
    const [subGroupDetail, setSubGroupDetail] = useState(null)
    const [groupDetail, setGroupDetail] = useState(null)

    const { subGroup } = useLocation().state ?? {}
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth)?.user
    const scores = useSelector(state => state.scores)?.list
    const [points, setPoints] = useState([])
    const [deleteSGroupModal, setDeleteSGroupModal] = useState(false)
    const [sMembers, setSMembers] = useState([])
    const navigate = useNavigate()
    const [showAddMember, setShowAddMember] = useState(false)
    const [search, setSearch] = useState("")

    useEffect(() => {
        if (subGroup?._id) {
            getGroupDetail()
            getParentGroupDetail()
        }
    }, [subGroup])

    useEffect(() => {
        if (scores?.length > 0 && subGroupDetail) {
            let temp = scores?.filter(x => x?.groups?.includes(subGroupDetail?.parentId))
            setPoints(temp)
            setSMembers(subGroupDetail?.members?.map(x => x._id))
        }
    }, [scores, subGroupDetail])



    const deleteSubGroup = async (id) => {
        try {
            setDeleteSGroupModal(false)
            dispatch(setLoading(false))
            const res = await hit(`${subgroups?.create}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                navigate(-1)
                showToast("Sub-Group deleted successfully")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const getGroupDetail = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${subGroup?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setSubGroupDetail(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const getParentGroupDetail = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${subGroup?.parentId}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupDetail(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const addMem = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(subgroups?.addMember, "post", { gId: subGroupDetail?._id, userId: id })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member added successfully.")
                setSMembers(res?.data?.members)
            }
        } catch (e) {
        } finally {
            getGroupDetail()
            dispatch(setLoading(false))
        }
    }

    const removeMem = async (id) => {
        try {
            if (user?._id == id) {
                return
            }
            dispatch(setLoading(true))
            let res = await hit(subgroups?.removeMember, "post", { gId: subGroup?._id, userId: id })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member removed successfully.")
                setSMembers(res?.data?.members)
            }
        } catch (e) {
        } finally {
            getGroupDetail()
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600", textAlign: "left" }}>{subGroupDetail?.name}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button onClick={() => {
                            setShowAddMember(!showAddMember)
                        }} variant="contained">Add Member</Button>
                        <Button onClick={() => {
                            setDeleteSGroupModal(true)
                        }} color="error" sx={{ marginLeft: "10px" }} variant="contained">Delete Group</Button>
                    </Box>
                </Box>
                <br />
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    {_.sortBy(subGroupDetail?.members,[function(o){return String(o.lastName).toLowerCase()}])?.map(item => {
                        let point = 0
                        let temp = [...points]
                        let myScores = temp?.filter(x => x?.user?._id == item?._id)
                        myScores.forEach(e => {
                            point = point + e?.point
                        });
                        return <MemberComponent subid={subGroupDetail?._id} groupId={subGroup?.parentId} removeMemberSubgroup={removeMem} myScores={myScores} item={item} point={point} title={item?.firstName} />
                    })}
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                {
                    showAddMember && <><Typography sx={{ textAlign: "left", fontWeight: "600" }}>Add Members In Sub group</Typography>
                        <br />
                        <SearchBar value={search} onChange={r => { setSearch(r) }} placeholder={"Search...."} />
                        <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                            <br />
                            {_.sortBy(groupDetail?.members,[function(o){return String(o?.lastName).toLowerCase()}])?.filter(x => {
                                if (search?.trim() != "") {
                                    return (String(x?.firstName + x?.lastName + x?.email).toLowerCase()?.includes(search?.toLowerCase()))
                                } else {
                                    return true
                                }
                            })?.map(x => <CheckComponent onPressChecked={() => {
                                if (sMembers?.includes(x?._id)) {
                                    removeMem(x?._id)

                                } else {
                                    addMem(x?._id)
                                }
                            }} isChecked={sMembers?.includes(x?._id)} item={x} title={x?.firstName} />)}
                            <br />
                            <br />
                            <Button onClick={() => {
                                setShowAddMember(false)
                            }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                                Done
                            </Button>
                            <br />
                        </Box></>}
                {/* <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600", marginBottom: "15px", textAlign: "left" }}>Profile</Typography> */}
                {/* <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <Box sx={[{ display: "flex", flexDirection: "column", alignItems: "center", padding: "5px", flex: 1, }]}>
                        <Avatar src={image_url + subGroupDetail?.profilePic} sx={{ height: "100px", border: "3px solid white", width: "100px", }} />
                        <Typography sx={{ marginTop: "10px", fontWeight: "600", fontSize: pxToRem(24), textAlign: "center" }}>{subGroupDetail?.name}</Typography>
                        <Button sx={{ fontWeight: "400", width: "40%" }} variant="outlined">
                            <Box>
                                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18) }}>1290</Typography>
                                <Typography sx={{ color: "black", fontSize: pxToRem(10) }}>Total Points</Typography>
                            </Box>
                        </Button>
                    </Box>
                    <br />
                    <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Per Quarter Points</Typography>
                    <Box sx={{ display: "flex", marginTop: "20px" }}>
                        <ScoreButtons title={"Daily"} score={"190"} />
                        <ScoreButtons title={"Weekly"} style={{ marginLeft: "4%" }} score={"190"} />
                    </Box>
                    <br />
                    <Box sx={{ display: "flex" }}>
                        <ScoreButtons title={"Monthly"} score={"190"} />
                        <ScoreButtons title={"Miscellaneous"} style={{ marginLeft: "4%" }} score={"190"} />
                    </Box>
                    <br />
                    <Typography sx={{ textAlign: "left", fontWeight: "600", fontSize: pxToRem(18) }}>My Categories</Typography>
                    <br />
                    <SubComponent title={"Personal Development"} />
                    <br />
                    <SubComponent title={"Personal Development"} />
                    <br />
                    <SubComponent title={"Personal Development"} />

                </Box> */}
                <Box height={"20px"} />
            </Box>
            <DeleteModal
                title={"You want to delete this sub-group?"}
                open={deleteSGroupModal}
                handleClose={() => {
                    setDeleteSGroupModal(false)
                }}
                onPressYes={() => deleteSubGroup(subGroupDetail?._id)}
                onPressNo={() => setDeleteSGroupModal(false)}
                onPressOut={() => setDeleteSGroupModal(false)}
            />
        </Box>
    )
}

const ScoreButtons = ({ title, score, style }) => {
    return (
        <Box sx={[{ boxShadow: "0px 5px 20px #26262626", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", px: "10px", py: "20px", borderRadius: "5px", minHeight: "100px", flex: 1, background: "white" }, style]}>
            <Typography sx={{ fontWeight: "600" }}>{title}</Typography>
            <Button variant="outlined" sx={{ minWidth: "150px", marginTop: "5px" }}>{score}</Button>
        </Box>
    )
}



const SubComponent = ({ title }) => {
    const [show, setShow] = useState(true)
    return (
        <Box sx={{ width: "100%", padding: "10px", borderRadius: "5px", textAlign: "left", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
            <Typography sx={{ fontSize: pxToRem(18), fontWeight: "600" }}>{title}</Typography>
            <Typography sx={{ fontSize: pxToRem(14), marginY: "7px", fontWeight: "400" }}>Average Points</Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography color={"primary"} sx={{ fontSize: pxToRem(22), fontWeight: "600" }}>{show ? "2760" : "****"}</Typography>
            </Box>
        </Box>
    )
}

const MemberComponent = ({ title, item, point ,myScores,removeMemberSubgroup,groupId,subid}) => {
    const [isChecked, setIsChecked] = useState(false)
    const [showPoint, setShowPoint] = useState(false)
    const [deleteModal,setDeleteModal]=useState(false)
    const navigate=useNavigate()
    return (
        <>
            <Box onClick={()=>{
                                    navigate("/user/user_score",{state:{id:item?._id,gId:groupId,subid}})

                // setShowPoint(!showPoint)
            }} sx={{ minHeight: "60px", width: "100%", p: "10px",cursor:"pointer", marginBottom: "15px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
                <Avatar
                    src={image_url + item?.profilePic}
                    style={{ height: "40px", width: "40px" }}
                />
                <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title} {item?.lastName}</Typography>
                    <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>

                </Box>
                <Button variant="outlined">
                    {point}
                </Button>
                <Box onClick={(event)=>{
                    event.stopPropagation()
                    setDeleteModal(true)
                }} component={"img"} sx={{ height: "25px", width: "25px", marginLeft: "5px", cursor: "pointer" }} src="assets/teams/delete.svg" />
            </Box>
            {showPoint && myScores?.filter(x => item?._id == x?.user?._id)?.map((i, j) => {
                return <PointsComp i={i} j={j} style={{ marginTop: "15px" }} admin={true} key={i?._id} adminView={true} />
            })}
            {showPoint&&<br/>}
            <DeleteModal 
             title={"You want to remove "+`${title} ${item?.lastName} `+"from team."}
             open={deleteModal}
             handleClose={() => { setDeleteModal(false);  }}
             onPressNo={() => { setDeleteModal(false);  }}
             onPressYes={() => { setDeleteModal(false);
                removeMemberSubgroup(item._id)
            }}
   
            />
        </>
    )
}

const CheckComponent = ({ title, isChecked, onPressChecked, item }) => {
    return (
        <Box sx={{ minHeight: "60px", width: "100%", marginBottom: "20px", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }}>

            <Avatar
                src={image_url + item?.profilePic}
                style={{ height: "40px", width: "40px" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title} {item?.lastName}</Typography>
                <Typography sx={{ fontWeight: "400", fontSize: pxToRem(12) }}>{item?.email}</Typography>

            </Box>
            <Box onClick={() => {
                // setIsChecked(!isChecked)
                onPressChecked()
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}