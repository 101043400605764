import { Box, Button, Typography } from "@mui/material";
import Input from "../components/Login/Input";
import { pxToRem } from "../theme/typography";
import '../css/signup.css';
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { useState } from "react";
import { setLoading } from "../store/load";
import { useDispatch } from "react-redux";
import hit from "../services/apiManager";
import { auth } from "../services/endpoints";
import showToast from "../utils/showToast";

export default function FOtpVerification() {
    const navigate = useNavigate()
    const [otp, setOTP] = useState("")
    const {user,}=useLocation().state
    const dispatch=useDispatch()

    const resendForgot = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(auth.forgotpass, "post", { "email": user })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("OTP sent sucessfully.")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const submitforgot = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(auth.verifyforgot, "post", { "email": user, "otp": otp })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                navigate("/newp", {state:{ token: res?.data?.token }})
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ width: "100vw", position: "relative", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box component={"img"} src="assets/login/fullback.png" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box component={"img"} src="assets/login/fullback1.svg" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box className="noScrollBar" sx={{ width: { xs: "90vw", md: "40vw" }, overflowY: "auto", scrollbarWidth: "none", minWidth: { md: "550px" }, borderRadius: "10px", position: "relative", backgroundImage: "linear-gradient(150deg,rgb(212,241,247), white ,rgb(254,243,214))" }}>
                <Box sx={{ height: "100%", width: "100%", paddingY: "7%", paddingX: {xs:"5%",md:"12%"}, position: "relative" }}>
                <Box component={"img"} src="assets/logo.png" sx={{ width: { md: "200px", sm: "200px" }, mx: "auto", height: "60px", objectFit: "contain" }} />
                    <Typography sx={{ fontSize: pxToRem(26), fontWeight: "700", marginTop: "10px" }}>Verification</Typography>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "500", marginTop: "4px" }}>We have sent a 4-digit verification code on your email id {user}</Typography>
                    <br />
                    <Box sx={{ width: "90%", marginX: "auto" }}>
                        <Typography sx={{ fontSize: "12px", fontWeight: "600", textAlign: "left",marginTop:"10px" }}>Enter OTP</Typography>
                        <OtpInput
                            value={otp}
                            onChange={e => setOTP(e)}
                            numInputs={4}
                            separator={<span> </span>}
                            containerStyle={{ display: "flex", justifyContent: "space-between",marginTop:"5px" }}
                            inputStyle={{ height: "70px",fontSize:pxToRem(24),color:"#0DB1D4", width: "70px", borderWidth: 0 , boxShadow: "0px 5px 10px #2626260D"}}
                            renderInput={(props) => <input {...props} />}
                        />
                        <Box sx={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                            <Typography sx={{fontSize:"12px"}}>Did'nt receive the code?</Typography>
                            <Typography color={"primary"} onClick={()=>{
                                resendForgot()
                            }} sx={{fontStyle:"italic",fontWeight:"600",fontSize:"12px",cursor:"pointer"}}>Resend</Typography>
                        </Box>
                        <br/>
                        <br/>
                        <Button onClick={()=>{
                            submitforgot()
                        }} sx={{ borderRadius: "5px", height: "50px", marginTop: "16px", width: "80%" }} variant="contained" >
                            Continue
                        </Button>
                        </Box>
                </Box>
                <Box onClick={()=>{
                    navigate(-1)
                }} component={"img"} src="assets/arrow-left.png" style={{height:"25px",width:"25px",position:"absolute",top:20,left:20}} />
            </Box>
        </Box >
    )
}

