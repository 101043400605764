import { Box, Button, Typography } from "@mui/material";
import { pxToRem } from "../theme/typography";
import Calendar from 'react-calendar'
import Input, { DatePickerInput, DropDownInput } from '../components/Login/Input'
import EventComponent from '../components/EventComponent'
import '../css/Calendar.css';
import { useEffect, useState } from "react";
import moment from "moment";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import { events, image_url, scores } from "../services/endpoints";
import { useDispatch, useSelector } from "react-redux";
import showToast from "../utils/showToast";
import DatePickerModal from "../components/DatePickerModal";
import TimePickerModal from "../components/TimePickerModal";
import { Avatar } from "rsuite";
import { getAllTeamThunk } from "../store/team";
import { getAllScoreData } from "../store/scores";
import DeleteModal from "../components/DeleteModal";
import { useNavigate } from "react-router-dom";

export default function CalendarPage() {
    const [value, onChange] = useState(null);
    const [showCreate, setShowCreate] = useState(false)
    const [showCalendars, setShowCalendars] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth?.user)

    const [allEvents, setAllEvents] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [showPopOver, setShowPopOver] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    // const timeZone = RNLocalize.getTimeZone()
    const [monthChanged, setMonthChanged] = useState(moment().format("YYYY-MM-DD"))
    const [monthlyEvents, setMonthlyEvents] = useState([])
    const [scoringDay, setScoringDay] = useState([])
    const [nonScoringEvent, setNonScoringEvent] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [eventParticular, setEventParticular] = useState([])
    const [scoreDetail, setScoreDetail] = useState([])

    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"))

    useEffect(() => {
        setSelectedDate(null)
        let date = moment(monthChanged).format("MM-DD-YYYY")
        getInteractionDetails()
        // getAllEvents(date)
        // return () => { date };
    }, [monthChanged])

    useEffect(() => {
        let date = moment(monthChanged).format("MM-DD-YYYY")
        getAllEvents(date)
    }, [scoreDetail])


    useEffect(() => {
        let temp = allEvents?.filter(x => moment(x?.startDateTime ? x?.startDateTime : x?.currentDate ? x?.currentDate : x?.scoreStartTime).format("DD") == moment(selectedDate).format("DD"))
        setEventParticular(temp)
    }, [selectedDate, allEvents])

    const getInteractionDetails = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${scores?.getscore}?user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setScoreDetail(res?.data)
            }
        } catch (e) {
        } finally {
        }
    }
    const getAllEvents = async (month) => {
        try {
            let res = await hit(`${events?.event}?global=true&date=${month}&populate=user&limit=100`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                let upcomingArray = []
                let score = []
                let nonScore = []
                let scoreDet = scoreDetail
                let temp = res?.data?.events
                let temp2 = res?.data?.posts?.results
                temp.map(item => {
                    if (item?.isScoring == true) {
                        score.push(moment(item?.startDateTime).format("YYYY-MM-DD"))
                    } else {
                        nonScore.push(moment(item?.startDateTime).format("YYYY-MM-DD"))
                    }
                    upcomingArray.push(moment(item?.startDateTime).format("YYYY-MM-DD"))
                })
                let fTemp2 = []
                temp2 = temp2?.filter(x => {
                    let endTime = moment(x?.scoreEndTime)
                    if (endTime.toDate() < moment().toDate()) {
                        return false
                    }
                    return true
                })
                temp2.map(item => {
                    let filterArr = []
                    filterArr.push(...scoreDet.filter(x => (x?.postId == item?._id && x?.user == user?._id)))
                    if (item?.scoreCount > filterArr.length) {
                        let startTime = moment(item?.scoreStartTime).unix()
                        let endTime = moment(item?.scoreEndTime).unix()
                        if ((endTime > moment().unix() && moment().unix() > startTime)) {
                            let i = item
                            i = { ...i, currentDate }
                            score.push(moment(currentDate).format("YYYY-MM-DD"))
                            upcomingArray.push(moment(currentDate).format("YYYY-MM-DD"))
                            fTemp2.push(i)
                        } else if (moment().unix() > startTime) {
                            score.push(moment(item?.scoreStartTime).format("YYYY-MM-DD"))
                            upcomingArray.push(moment(item?.scoreStartTime).format("YYYY-MM-DD"))
                            fTemp2.push(item)
                        } else {
                            console.log(item?.txt);
                        }
                    } else {

                    }
                })
                let finalTemp = temp.concat(fTemp2);
                setAllEvents(finalTemp.sort(function (a, b) {
                    return Math.abs(moment(a?.startDateTime ? a?.startDateTime : a?.scoreStartTime).unix() - moment().unix()) - Math.abs(moment(b?.startDateTime ? b?.startDateTime : b?.scoreStartTime).unix() - moment().unix())
                }))
                setScoringDay(score)
                setNonScoringEvent(nonScore)
                setMonthlyEvents(upcomingArray)
                // saveEvents(temp, temp2)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const attendEve = async (id, type) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${events?.attend}/${id}`, "post", { type })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Response recorded.")
                let date = moment(monthChanged).format("MM-DD-YYYY")
                getAllEvents(date)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const deleteEvent = async (id) => {
        setDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${events?.event}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Event deleted successfully.")
                let date = moment(monthChanged).format("MM-DD-YYYY")
                getAllEvents(date)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
            setDeleteId("")
        }
    }

    useEffect(() => {
        dispatch(getAllTeamThunk())
        dispatch(getAllScoreData())
    }, [])

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minWidth: "30vw", minHeight: "100px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "600", textAlign: "left", fontSize: pxToRem(18) }}>Draft Post</Typography>
                    <Box onClick={() => {
                        setShowCreate(!showCreate)
                    }} sx={{ height: "20px", width: "20px" }} component={"img"} src="assets/calendar/plus.svg" />
                </Box>
                <Box>
                    <Calendar formatShortWeekday={(locale, date) => {
                        return moment(date).format("dd").charAt(0)
                    }}
                        onActiveStartDateChange={({ action, activeStartDate, value, view }) => {

                            if (view == "month") {
                                dispatch(setLoading(true))
                                setMonthChanged(moment(activeStartDate).format("YYYY-MM-DD"))
                                onChange(null)
                                setSelectedDate(null)
                            }
                        }}
                        tileContent={({ date, view }) => {
                            let scoring = scoringDay.includes(moment(date).format("YYYY-MM-DD"))
                            let nonScoring = nonScoringEvent.includes(moment(date).format("YYYY-MM-DD"))
                            let nonscount = nonScoringEvent.filter(x => x == moment(date).format("YYYY-MM-DD"))?.length
                            let isMarked = monthlyEvents.includes(moment(date).format("YYYY-MM-DD"))
                            let eventCounts = monthlyEvents.filter(x => x == moment(date).format("YYYY-MM-DD")).length
                            let isSelected = moment(date).format("YYYY-MM-DD") == selectedDate
                            const today = moment(date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
                            if (isMarked) {
                                return (
                                    <Box
                                        sx={{ position: "relative" }}
                                    >
                                        {scoring &&
                                            <Box sx={{ background: "#09ab34", display: "flex", height: "10px", width: "10px", borderRadius: "10px", position: 'absolute', right: 0, top: -22, justifyContent: "center", alignItems: "center" }}>
                                                <Typography sx={{ fontWeight: "600", color: "white", fontSize: "8px" }}>{eventCounts - nonscount}</Typography>
                                            </Box>
                                        }
                                        {nonScoring &&
                                            <Box sx={{ background: "#FF5A5A", display: "flex", height: "10px", width: "10px", borderRadius: "10px", position: 'absolute', right: 0, top: -22, justifyContent: "center", alignItems: "center" }}>
                                                <Typography style={{ fontWeight: "600", color: "white", fontSize: "8px" }}>{nonscount}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                );
                            }
                            return null
                        }}
                        onClickDay={(value, event) => {
                            onChange(value)
                            setSelectedDate(moment(value).format("YYYY-MM-DD"))
                        }} value={value} />
                </Box>
            </Box>
            <Box sx={{ flex: 1, maxWidth: "32vw", marginLeft: "5%" }}>
                {showCreate &&
                    <CreateEvent setShowCalendars={setShowCalendars} setShowCreate={setShowCreate} />
                }
                {!showCreate && <>
                    {selectedDate == null ?
                        <Typography style={{ fontWeight: "600", textAlign: "left", marginLeft: "16px", marginTop: "16px", marginBottom: "8px" }}>{moment(monthChanged).format("MMMM")}</Typography>
                        :
                        <Typography style={{ fontFamily: "600", textAlign: "left", marginLeft: "16px", marginTop: "16px", marginBottom: "8px" }}>{moment(monthChanged).format("MMMM")} {moment(selectedDate).format("Do")}</Typography>
                    }
                    {selectedDate == null ? allEvents?.map((i, j) => {
                        return (
                            <EventComponent
                                i={i} j={j} key={i?._id}
                                onPressYes={() => {
                                    attendEve(i?._id, "attend")
                                }}
                                onPressNo={() => {
                                    attendEve(i?._id, "unattend")
                                }}
                                onPressM={() => {
                                    attendEve(i?._id, "maybe")
                                }}
                                onPressDelete={() => {
                                    setDeleteModal(true)
                                    setDeleteId(i?._id)
                                }}
                                onPressPost={() => {
                                    navigate("/user/post_detail", { state: { id: i?._id } })
                                    // navigation.navigate("IndividualPost", { id: i?._id })
                                }}
                            />
                        )
                    })
                        :
                        eventParticular.map((i, j) => {
                            return (
                                <EventComponent
                                    i={i} j={j} key={i?._id}
                                    onPressYes={() => {
                                        attendEve(i?._id, "attend")
                                    }}
                                    onPressNo={() => {
                                        attendEve(i?._id, "unattend")
                                    }}
                                    onPressM={() => {
                                        attendEve(i?._id, "maybe")
                                    }}
                                    onPressDelete={() => {
                                        setDeleteModal(true)
                                        setDeleteId(i?._id)
                                    }}
                                    onPressPost={() => {
                                        navigate("/user/post_detail", { state: { id: i?._id } })
                                    }}
                                />
                            )
                        })}
                    <Box sx={{ height: "20px" }} />
                </>}
                <DeleteModal
                    open={deleteModal}
                    title={"You want to delete this event?"}
                    handleClose={() => {
                        setDeleteModal(false)
                    }}
                    onPressNo={() => setDeleteModal(false)}
                    onPressOut={() => setDeleteModal(false)}
                    onPressYes={() => deleteEvent(deleteId)}
                />
            </Box>
        </Box>
    )
}


const CreateEvent = ({ setShowCalendars, setShowCreate }) => {
    const [title, setTitle] = useState("")
    const [location, setLocation] = useState("")
    const [sdate, setSdate] = useState(new Date())
    const [edate, setEdate] = useState(new Date())
    const [desc, setDesc] = useState("")
    const [typeDate, setTypeDate] = useState("") //starting or ending 
    const [eventType, setEventType] = useState("")
    const [repeat, setRepeat] = useState("")
    const [scoring, setScoring] = useState(false)
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false)
    const [isTimePickerVisible, setTimePickerVisibility] = useState(false)
    const [groupsSelected, setGroupsSelected] = useState([])
    const dispatch = useDispatch()


    const showDatePicker = (type) => {
        setTypeDate(type)
        setDatePickerVisibility(true)
    }
    const hideDatePicker = () => {
        setTypeDate("")
        setDatePickerVisibility(false)
        setTimePickerVisibility(false)
    }
    const handleConfirm = (date) => {
        typeDate == "start" ? setSdate(date) : setEdate(date)
        hideDatePicker()
    }
    const submit = async (data) => {

        if (data?.title.trim() == "") {
            showToast("Title is required.", "warning")
            return
        }
        if (data?.eventType?.trim() == "") {
            showToast("Event type is required.", "warning")
            return
        }
        if (data?.address.trim() == "") {
            showToast("Location is required.", "warning")
            return
        }
        if (data?.startDateTime && data?.endDateTime) {
            let start = data?.startDateTime
            let end = data?.endDateTime
            if (start > end) {
                showToast("End time must be correct.", "warning")
                return
            }
        }
        if (data?.description.trim() == "") {
            showToast("Description is required.", "warning")
            return
        }
        if (groupsSelected?.length == 0) {
            showToast("please select any group", "warning")
            return
        }
        try {
            dispatch(setLoading(true))
            const res = await hit(events?.event, "post", { ...data, groups: groupsSelected, users: [] })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Event posted successfully")
                setShowCreate(false)

            }
        } catch (err) {

        } finally {
            dispatch(setLoading(false))
        }

        // navigation.navigate("Eventsfor", { eventObj })
    }


    return (
        <>
            <Typography sx={{ fontWeight: "600", textAlign: "left", fontSize: pxToRem(18) }}>Create Event</Typography>

            <Box sx={{ background: "white", display: "flex", marginTop: "20px", textAlign: "left", flexDirection: "column", width: "100%", minHeight: "400px", p: "10px", borderRadius: "5px", boxShadow: "0px 5px 20px #26262626" }}>
                <Input value={title} onChange={v => setTitle(v)} placeholder={"Event title"} title={"Title here..."} />
                <br />
                <DropDownInput value={eventType} onChange={v => {
                    setEventType(v)
                }} title={"Event Type"} options={["Event", "Game"]} img={"assets/posts/arrow_ios.svg"} />
                <br />
                <Input value={location} onChange={v => setLocation(v)} placeholder={"Location"} title={"Enter location..."} />
                <br />
                <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                    <DatePickerInput onClick={() => {
                        setDatePickerVisibility(true)
                        setTypeDate("start")
                    }} inputContainerStyle={{}} title={"Start Date"} value={moment(sdate).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                    <DatePickerInput onClick={() => {
                        setTimePickerVisibility(true)
                        setTypeDate("start")
                    }} inputContainerStyle={{}} title={"Start Time"} value={moment(sdate).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />
                </Box>
                <Box sx={{ display: "flex", textAlign: "left", marginTop: "10px" }}>
                    <DatePickerInput onClick={() => {
                        setDatePickerVisibility(true)
                        setTypeDate("end")
                    }} inputContainerStyle={{}} title={"End Date"} value={moment(edate).format("MMM, DD, YYYY")} containerStyle={{ flex: 1 }} />
                    <DatePickerInput onClick={() => {
                        setTimePickerVisibility(true)
                        setTypeDate("end")
                    }} inputContainerStyle={{}} title={"End Time"} value={moment(edate).format("hh:mm A")} containerStyle={{ flex: 1, marginLeft: "4%" }} />

                </Box>
                <br />
                <DropDownInput value={repeat} onChange={v => {
                    setRepeat(v)
                }} title={"Repeating Event"} options={["Once", "Weekly", "Monthly"]} img={"assets/posts/arrow_ios.svg"} />

                <br />
                <Input value={desc} onChange={v => setDesc(v)} title={"Description"} inputContainerStyle={{ height: "100px" }} placeholder="Add a note or description" multiline={true} />
                <CheckComponent isChecked={scoring} setIsChecked={v => {
                    setScoring(v)
                }} title={"Scoring Event"} />
                <ShowGroups groupsSelected={groupsSelected} setGroupsSelected={setGroupsSelected} />
                <br />
                <Button onClick={() => {
                    // setShowCreate(false)
                    // setShowCalendars(true)
                    const eventObj = {
                        title: title,
                        address: location,
                        startDateTime: moment(sdate).unix() * 1000,
                        endDateTime: moment(edate).unix() * 1000,
                        description: desc,
                        eventType: eventType?.toLowerCase(),
                        repeatEvent: repeat?.toLowerCase(),
                        isScoring: scoring
                    }
                    submit(eventObj)
                }} variant="contained" sx={{ width: "45%", marginX: "auto" }}>Create</Button>
                <br />
            </Box>
            <Box height={"20px"} />
            <DatePickerModal open={isDatePickerVisible} setOpen={setDatePickerVisibility} value={typeDate == "start" ? moment(sdate) : moment(edate)} onChange={v => {
                handleConfirm(moment(v).toDate())
            }} />
            <TimePickerModal open={isTimePickerVisible} setOpen={setTimePickerVisibility} value={typeDate == "start" ? moment(sdate) : moment(edate)} onChange={v => {
                handleConfirm(moment(v).toDate())
            }} />
        </>
    )
}

const CheckComponent = ({ title, isChecked, setIsChecked, style }) => {
    return (
        <Box sx={[{ minHeight: "60px", width: "100%", p: "10px", display: "flex", alignItems: "center" }, style]}>
            <Box onClick={() => {
                setIsChecked(!isChecked)
            }} component={"img"} sx={{ cursor: "pointer", width: "15px", height: "15px" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />

            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title}</Typography>
            </Box>
        </Box>
    )
}

const ShowGroups = ({ groupsSelected, setGroupsSelected }) => {
    const [show, setShow] = useState(false)
    const teams = useSelector(state => state?.team?.team)

    return (
        <>
            <Box onClick={() => {
                setShow(!show)
            }} sx={{ display: "flex", cursor: "pointer", justifyContent: "space-between", alignItems: "center", px: "20px", py: "15px", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px" }}>
                <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>Select Groups</Typography>
                <Box component={"img"} src={"assets/posts/arrow_ios.svg"} />
            </Box>
            {show && teams?.map(x => {
                return (
                    <CheckComponentGroup setIsChecked={v => {
                        if (v) {
                            setGroupsSelected([...groupsSelected, x?._id])
                        } else {
                            setGroupsSelected([...groupsSelected].filter(g => g != x?._id))
                        }
                    }} isChecked={groupsSelected?.includes(x?._id)} style={{ marginTop: "15px" }} title={x?.name} item={x} />
                )
            })}
        </>
    )
}

const CheckComponentGroup = ({ title, item, isChecked, setIsChecked, style }) => {
    return (
        <Box sx={[{ minHeight: "60px", width: "100%", p: "10px", display: "flex", alignItems: "center", background: "white", boxShadow: "0px 5px 20px #26262626" }, style]}>

            <Avatar
                src={image_url + item?.profilePic}
                style={{ height: "40px", width: "40px", objectFit: "cover" }}
            />
            <Box sx={{ marginLeft: "15px", flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{title}</Typography>
            </Box>
            <Box onClick={() => {
                setIsChecked(!isChecked)
            }} component={"img"} sx={{ cursor: "pointer" }} src={isChecked ? "assets/groups/ios-checkbox.svg" : "assets/groups/ios-checkbox-outline.svg"} />
        </Box>
    )
}