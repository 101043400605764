import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { minHeight } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../components/Home/SearchBar";
import EnterTime from "../components/EnterTime";
import { pxToRem } from "../theme/typography";
import DenyReasonModal from "../components/DenyReasonModal";
import { getAllTeamThunk } from '../store/team'
import { image_url, post, uploads } from "../services/endpoints";
import { setPostData } from "../store/post";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import showToast from "../utils/showToast";
import moment from "moment";
import { post as postApi, teams as teamsApi } from '../services/endpoints'
import PostComponent from "../components/PostComponent";
import DeleteModal from "../components/DeleteModal";
import ViewedByModal from "../components/ViewedByModal";
import PostDeniedModal from "../components/PostDeniedModal";
export default function PostDetail() {
    const postid = useLocation()?.state?.id
    const user = useSelector(state => state?.auth?.user);
    const dispatch = useDispatch()
    const [feed, setFeed] = useState([])
    const [deleteId, setDeleteId] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [checkingStatus, setCheckingStatus] = useState("")
    const [checkingPost, setCheckingPost] = useState("")
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectDetail, setRejectDetail] = useState(null)
    const [showViewdBy, setShowViewdBy] = useState(false)
    const [viewedByDetail, setViewedByDetail] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const navigate=useNavigate()
    useEffect(() => {
        if (postid) {
            getPost(postid)
        }
    }, [])

    const getPost = async (id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                console.log(res.data)
                let temp = []
                temp = [res?.data]
                setFeed(temp)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const likePost = async (id) => {
        try {
            let res = await hit(`${post?.post}/${id}/like`, "post")
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                getPost(id)
            }
        } catch (e) {
        } finally {
        }
    }
    const deleteF = (id) => {
        setDeleteId(id)
        setTimeout(() => {
            setShowDeleteModal(true)
        }, 700);
    }
    const deletePost = async (id) => {
        setShowDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("Post deleted")
                navigate(-1)
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }
    const votePoll = async (selected, id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(post?.poll, "post", { "postId": id, "selected": selected })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                getPost(id)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    function componentDidMount(id) {
        if (navigator.geolocation) {
            dispatch(setLoading(true))
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let myloc = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    getAddress(position?.coords, id, myloc)
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log("errorrrr===>>>", error.code, error.message);
                }
            );
        }
    }
    function getAddress(cords, id, myloc) {
        fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            (cords?.latitude ?? 0.0) +
            ',' +
            (cords?.longitude ?? 0.0) +
            '&key=' +
            'AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q',
        )
            .then(async res => {
                let resjson = await res.json();
                if (resjson.results.length > 0) {
                    let addressComponent = resjson.results[0].address_components;
                    console.log(addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name);
                    let address = addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name
                    interactPost(id, address, myloc)
                }
            })
            .catch(error => console.log('results error => ', error.message));
    }
    const interactPost = async (id, add, myloc) => {
        if (myloc == null) {
            dispatch(setLoading(false))
            return
        }
        try {
            let res = await hit(post?.interact, "post", {
                "postId": id,
                "last_checkin_location": {
                    "lat": myloc?.latitude,
                    "lng": myloc?.longitude,
                    "address": add
                },
            })
            if (res?.err) {
                showToast(res?.msg)
                dispatch(setLoading(false))
            } else {
                getuserInteractions()
            }
        } catch (e) {
        } finally {
        }
    }
    const getuserInteractions = async () => {
        try {
            let res = await hit(`${post?.interact}?user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setCheckingPost(res?.data[0]?.postId)
                setCheckingStatus(res?.data[0]?.current_status)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const approve = async (id, approve, ind) => {
        if (approve == 2) {
            setShowRejectModal(true)
            setRejectDetail(ind)
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post.postApproval}/${id}`, "patch", { isApproved: approve })
            if(!res.err){
                getPost(id)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const rejectPost = async (detail, reason) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post.postApproval}/${detail?._id}`, "patch", { isApproved: 2, reason: reason.trim() })
            if(!res?.err){
                getPost(postid)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
            {feed.map((i, j) => {
                    let isCheckedin = "in"
                    if (checkingPost == i?._id) {
                        console.log(i?._id, checkingStatus);
                        if (checkingStatus == "in") {
                            isCheckedin = "out"
                        } else {
                            isCheckedin = "in"
                        }
                    }
                    return (
                        <PostComponent
                            i={i} j={j}
                            key={i?._id}
                            onPressProfile={() => {
                                if (user?._id == i?.user?._id) {
                                    // navigation?.navigate("Profile")
                                    return
                                }
                                // navigation?.navigate("FriendsProfile", { user: i?.user?._id, team: "noteam" })
                            }}
                            postLiked={liked => { likePost(liked);  }}
                            onPressDelete={id => {
                                deleteF(id)
                            }}
                            onPressComment={(groupId) => {
                                navigate("/user/comments", { state: { id: i?._id, from: 'home' ,groupId} })
                            }}
                            onPressPost={(data) => {
                                if (data?.type == "photo") {
                                    window.open(image_url + data?.img, "_blank")
                                } else {
                                    let ind = i?.thumbnails?.indexOf(data?.img)
                                    let vid = i?.videos[ind]
                                    window.open(image_url + vid, "_blank")
                                }
                            }}
                            selectPoll={(selected) => {
                                votePoll(selected, i?._id)
                            }}
                            onPressApprove={() => {
                                approve(i?._id, 1)
                            }}
                            onPressReject={() => {
                                approve(i?._id, 2, i)
                            }}
                            onPressCheck={() => {
                                componentDidMount(i?._id)
                            }}
                            onPressInteraction={() => {
                                if (i?.verifyBy?.includes(user?._id)) {
                                    navigate("/user/interaction", { state: { postid: i?._id } })
                                }
                            }}
                            isCheckedin={isCheckedin}
                            onPressViewed={(members, grpId) => {
                                setSelectedGroup(grpId)
                                setTimeout(() => {
                                    setShowViewdBy(true)
                                    setViewedByDetail({ viewed: i?.viewsBy, memb: members })
                                }, 400);
                            }}
                        />
                    )
                })}
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, }}>
            </Box>
            <DeleteModal
                open={showDeleteModal}
                handleClose={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressNo={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressYes={() => {
                    deletePost(deleteId)
                }}
            />
            <ViewedByModal
                open={showViewdBy}
                arr={viewedByDetail}
                setOpen={setShowViewdBy}
                selectedGroup={selectedGroup}
                onPressCancel={() => {
                    setShowViewdBy(false)
                }}

            />
             <PostDeniedModal
                open={showRejectModal}
                setOpen={setShowRejectModal}
                outPressed={() => setShowRejectModal(false)}
                onPressOk={(t) => {
                    setShowRejectModal(false)
                    rejectPost(rejectDetail, t)
                }}
            />
        </Box>
    )
}
