
const storeData = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value)
       localStorage.setItem(key, jsonValue)
    } catch (e) {
        // saving error
    }
}

const removeValue = async (key) => {
    try {
        localStorage.removeItem(key)
    } catch (e) {
        // remove error
    }

    console.log('Done.')
}

const getData = async (key) => {
    try {
        const value =  localStorage.getItem(key)
        if (value !== null) {
            // value previously stored
            return JSON.parse(value)
        }
    } catch (e) {
        // error reading value
    }
}

const clearAll = async () => {
    try {
        localStorage.clear()
    } catch (e) {
        // clear error
    }

    console.log('Done.')
}

export { storeData, getData, removeValue, clearAll }