import { Box, Button, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import DatePickerRangeModal from "../components/DatePickerRangeModal";
import SearchBar from "../components/Home/SearchBar";
import UserTypeSelect from "../components/Login/UserTypeSelect";
import ProfileComp from "../components/Profile/ProfileComp";
import GroupScoreProfile from "../components/ScoreBoard/GroupScoreProfile";
import PointsComp from "../components/ScoreBoard/PointsComp";
import ScoreCard from "../components/ScoreBoard/ScoreCard";
import TwoSelectionTab from "../components/TwoSelectionTab";
import hit from "../services/apiManager";
import { post, scores, subgroups, teams } from "../services/endpoints";
import { setInteractionList } from "../store/interactions";
import { setLoading } from "../store/load";
import { getAllScoreData } from "../store/scores";
import { getAllTeamThunk, setSelectedTeam } from "../store/team";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import { setDateRange } from "../store/miscDateRanges";
import { firebaseDb, firebaseStorage } from "../utils/firebaseInit";
import { collection, doc, getDoc, onSnapshot, setDoc, updateDoc, query, where, orderBy, addDoc, increment, getDocs, deleteDoc } from "firebase/firestore";
import { updatePointfilter } from "../store/pointFilter";
import _ from 'lodash'

export default function ScoreBoard() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const groups = useSelector(state => state?.team?.team)
    const selectedTeam = useSelector(state => state?.team?.selectedTeam)
    const [search, setSearch] = useState("")
    const [allGroup, setAllGroup] = useState([])
    const [groupDetail, setGroupDetail] = useState(null)
    const [groupScores, setGroupScores] = useState([])
    const [groupPoint, setGroupPoint] = useState(0)
    const [avgPoint, setAvgPoint] = useState(0)
    const userDetails = useSelector(state => state?.auth?.user)
    const [allsubgroups, setAllsubgroups] = useState([])
    const [feed, setFeed] = useState([])
    const [count, setCount] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const interactionList = useSelector(state => state?.interactions?.list)
    const [allInteractions, setallInteractions] = useState([])
    const [yourPoint, setYourPoint] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectDetail, setRejectDetail] = useState(null)
    const [showViewdBy, setShowViewdBy] = useState(false)
    const [viewedByDetail, setViewedByDetail] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [deleteSGroupModal, setDeleteSGroupModal] = useState(false)
    const [groupMembersDetails, setGroupMemberDetails] = useState([])
    const location = useLocation()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllScoreData())
        dispatch(getAllTeamThunk())
    }, [location])

    useEffect(() => {
        setAllGroup(groups)
    }, [groups])

    useEffect(() => {
        if (selectedTeam == null) {
            dispatch(setSelectedTeam(groups[0]))
        }
    }, [selectedTeam, groups])

    useEffect(() => {
        if (selectedTeam != null) {
            getGroupDetail()
            getAllSubGroup()
            getGroupTotalPoint()
            getGroupFeed(selectedTeam?._id, 1)
        }
    }, [selectedTeam, location])
    const getGroupDetail = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${selectedTeam?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupDetail(res?.data)
                setGroupMemberDetails(res?.data?.members)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const getGroupTotalPoint = async () => {
        try {
            let res = await hit(`${scores?.getscore}?groups=${selectedTeam?._id}&groupOnly=true`)
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupScores(res?.data)
            }
        } catch (e) {
        }
    }
    const getAllSubGroup = async () => {
        try {
            const res = await hit(`${subgroups?.create}/?parentId=${selectedTeam?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                if (groupDetail?.createdBy?._id == userDetails?._id) {
                    setAllsubgroups(res?.data)
                } else {
                    let newArr = res?.data?.filter(x => x?.createdBy == userDetails?._id)
                    setAllsubgroups(newArr)
                }
            }
        } catch (e) {
        } finally {
        }
    }
    useEffect(() => {
        if (search?.length == 0) {
            setAllGroup(groups)
        }
    }, [search])
    useEffect(() => {
        setallInteractions(interactionList)
    }, [interactionList])

    useEffect(() => {
        let totalPoint = 0
        let temp = [...groupScores]
        temp.forEach((i, j) => {
            if (i?.type == 4) {
                totalPoint = +i?.point
            }
        })
        feed.forEach(p => {
            if (p?.isScorePost) {
                if (p?.scoreBy?.includes("likes")) {
                    totalPoint += p?.scorePoint
                }
                if (p?.scoreBy?.includes("verification")) {
                    totalPoint += (p?.scorePoint1 * p?.scoreCount)
                }
                if (p?.scoreBy?.includes("geo")) {
                    totalPoint += p?.scorePoint2
                }
            }
        })
        setGroupPoint(totalPoint)
    }, [groupScores, feed])
    const deleteGroup = async (id) => {
        try {
            setDeleteSGroupModal(false)
            dispatch(setLoading(true))
            const res = await hit(`${teams.createTeam}/${id}`, "patch", { active: 0 })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Group deleted successfully")
                dispatch(getAllTeamThunk(() => {
                    dispatch(setSelectedTeam(null))
                }))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    useEffect(() => {
        let c = 0
        let members = groupDetail?.members
        let admins = groupDetail?.admins
        let ableToScores = groupDetail?.ableToScores
        for (let i of groupScores) {
            c += i?.point
        }
        if (isAdmin) {
            let memb = (members.length + ableToScores?.length - admins.length)
            if (memb == 0) {
                setAvgPoint(c)
            }
            if (memb > 0) {
                let avg = Math.round(c / memb)
                setAvgPoint(avg)
            }
        } else {
            let point = 0
            let yourPoints = groupScores.filter(x => x?.user?._id == userDetails?._id)
            yourPoints.forEach((i, j) => {
                point = +i?.point
            })
            setYourPoint(point)
        }
    }, [groupDetail, groupPoint, isAdmin])

    useEffect(() => {
        if (groupDetail && userDetails) {
            let admins = groupDetail?.admins?.map(x => x._id)
            if (admins?.includes(userDetails?._id)) {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        }
    }, [groupDetail, userDetails])
    const searchGroup = (key) => {
        let arr = groups
        let temp = arr.filter(x => String(x?.name)?.trim().toLowerCase().includes(key?.trim().toLowerCase()))
        setTimeout(() => {
            setAllGroup(temp)
        }, 400);
    }

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        console.log(tmp)
        return tmp;
    }
    const getGroupFeed = async (id, pg) => {
        try {
            let res = await hit(`${post?.post}?groups=${id}&page=${pg}&populate=likes,user,viewsBy,groups.members,pollSelected.user`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setFeed(res?.data?.results)
                setTotalPage(res?.data?.totalPages)
                setCount(1)
            }
        } catch (e) {
        } finally {
        }
    }
    const refreshGroupFeeds = async (id, pg) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}?groups=${id}&page=${pg}&sortby=createdAt:asc&populate=likes,user,viewsBy,groups.members,pollSelected.user`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setFeed([...feed, ...res?.data?.results])
            }
        } catch (e) {
        } finally {
            setCount(pg)
            dispatch(setLoading(false))
        }
    }
    const viewPost = async (id) => {
        console.log(id, "viewPost")
        try {
            let res = await hit(`${post?.viewPost}/${id}`, "patch")
            console.log(res);
        } catch (e) {
        } finally {
        }
    }
    const likePost = async (id) => {
        try {
            let res = await hit(`${post?.post}/${id}/like`, "post")
            console.log(res);
            if (res?.err) {
                showToast(res?.msg, "error")
            }
        } catch (e) {
        } finally {
        }
    }

    const deletePost = async (id) => {
        setShowDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Post deleted successfully")
                getGroupFeed(selectedTeam?._id, count)
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }

    const deleteF = (id) => {
        setDeleteId(id)
        setTimeout(() => {
            setShowDeleteModal(true)
        }, 700);
    }
    const votePoll = async (selected, id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(post?.poll, "post", { "postId": id, "selected": selected })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                getGroupFeed(selectedTeam?._id, count)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const approve = async (id, approve, ind) => {
        if (approve == 2) {
            setShowRejectModal(true)
            setRejectDetail(ind)
            return
        }
        try {
            let res = await hit(`${post.postApproval}/${id}`, "patch", { isApproved: approve })
        } catch (e) {
        } finally {
        }
    }
    const rejectPost = async (detail, reason) => {
        let temp = [...feed]
        let index = temp.indexOf(detail)
        temp.splice(index, 1)
        setFeed(temp)
        try {
            let res = await hit(`${post.postApproval}/${detail?._id}`, "patch", { isApproved: 2, reason: reason.trim() })
        } catch (e) {
        } finally {
        }
    }

    function componentDidMount(id) {
        if (navigator.geolocation) {
            dispatch(setLoading(true))
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let myloc = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    getAddress(position?.coords, id, myloc)
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log("errorrrr===>>>", error.code, error.message);
                }
            );
        }
    }

    function getAddress(cords, id, myloc) {
        fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            (cords?.latitude ?? 0.0) +
            ',' +
            (cords?.longitude ?? 0.0) +
            '&key=' +
            'AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q',
        )
            .then(async res => {
                let resjson = await res.json();
                if (resjson.results.length > 0) {
                    let addressComponent = resjson.results[0].address_components;
                    console.log(addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name);
                    let address = addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name
                    interactPost(id, address, myloc)
                }
            })
            .catch(error => console.log('results error => ', error.message));
    }
    const interactPost = async (id, add, myloc) => {
        if (myloc == null) {
            dispatch(setLoading(false))
            return
        }
        try {
            let res = await hit(post?.interact, "post", {
                "postId": id,
                "last_checkin_location": {
                    "lat": myloc?.latitude,
                    "lng": myloc?.longitude,
                    "address": add
                },
            })
            if (res?.err) {
                showToast(res?.msg, "error")
                dispatch(setLoading(false))
            } else {
                getuserInteractions()
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))

        }
    }
    const getuserInteractions = async () => {
        try {
            let res = await hit(`${post?.interact}?user=${userDetails?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                dispatch(setInteractionList(res?.data))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const [showAll, setShowAll] = useState(false)
    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <SearchBar onClickSearch={() => {
                    searchGroup(search)
                }} value={search} onChange={v => setSearch(v)} placeholder={"Search...."} />
                <br />
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "600" }}>Groups</Typography>
                        {/* <Typography sx={{ fontSize: pxToRem(13) }}>View All</Typography> */}
                    </Box>
                    <br />
                    {/* {splitArray.map(())} */}
                    {splitArray(allGroup, 2)?.map((items, index) => {
                        return (
                            <Box sx={{ display: "flex", marginBottom: "15px", justifyContent: "space-between", alignItems: "center" }}>
                                {items?.map((item, index1) => <ScoreCard isTeam={false} item={item} onSelect={() => {
                                    dispatch(setSelectedTeam(item))
                                }} style={{ marginLeft: index1 != 0 ? "20px" : "0px" }} title={item?.name} />)}
                            </Box>
                        )
                    }
                    )}
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                {selectedTeam != null && <PointHistory groupMembersDetails={groupMembersDetails} isAdmin={isAdmin} avgPoint={avgPoint} yourPoint={yourPoint} selectedTeam={selectedTeam} />}
            </Box>

        </Box>
    )
}

const PointHistory = ({ avgPoint, yourPoint, selectedTeam, groupMembersDetails }) => {
    const [currentTab, setCurrentTab] = useState(0)
    const [group, setGroup] = useState(selectedTeam)
    const user = useSelector(state => state?.auth?.user);
    const FriendList = useSelector(state => state.friends)?.list
    const notif = useSelector(state => state?.notification?.list)
    const [onPressDone, setOnPressDone] = useState(false)
    const [quarter, setQuartet] = useState([
        { title: "Daily", points: 2760 },
        { title: "Weekly", points: 2760 },
        { title: "Monthly", points: 2760 },
        // { title: "Quarterly", points: 2760 },
        { title: "Miscellaneous", points: 2760, show: 0 },
    ])
    const [points, setPoints] = useState([])
    const [refresher, setRefresher] = useState(true)
    const [allranking, setAllranking] = useState([])
    const [selectedQuarter, setSelectedQuarter] = useState("")
    const [userType, setUserType] = useState("Points")
    const [groupDetails, setGroupDetails] = useState(null)
    const [pointData, setPointData] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const [ableToScore, setAbleToScore] = useState(false)
    const [allScores, setAllScores] = useState([])
    const [pointHis, setPointHis] = useState([])
    const [adminView, setAdminView] = useState("Admin")
    const [pointModal, setPointModal] = useState(false)
    const [sdate, setSdate] = useState(null)
    const [edate, setEdate] = useState(null)
    // const miscDateRanges = useSelector(state => state.miscDateRanges)
    const dispatch = useDispatch()
    const [scoringPosts, setScoringPosts] = useState([])
    const location = useLocation()
    const filters = useSelector(state => state?.pointFilter?.filters)

    // useEffect(() => {
    //     // setAdminView("Admin")
    // }, [location])

    useEffect(() => {
        if (group?._id) {
            if (filters[group?._id] && filters[group?._id]?.sdate && filters[group?._id]?.edate) {
                let start = filters[group?._id]?.sdate
                let end = filters[group?._id]?.edate
                setSdate(new Date(start))
                setEdate(new Date(end))
                setOnPressDone(true)

            } else {
                setSdate(null)
                setEdate(null)
                setOnPressDone(false)
            }
        }
    }, [groupDetails, filters, location])


    useEffect(() => {
        if (groupDetails?._id) {
            if (!groupDetails?.showMiscBoard) {
                getSavedDate(groupDetails?._id)
            }
        }

    }, [groupDetails])

    useEffect(() => {
        setGroup(selectedTeam)
        setAdminView("Admin")
        setSelectedQuarter("")
        setSdate(null)
        setEdate(null)
        setOnPressDone(false)
        setAllranking([])
    }, [selectedTeam, location])


    useEffect(() => {
        getGroupScoringsPostAll(groupDetails)
    }, [groupDetails])


    const getGroupScoringsPostAll = async (group) => {
        try {
            let res = await hit("/posts/allScorePosts", "post", { groups: group._id })
            if (!res.err) {
                setScoringPosts(res.data)
            }
        } catch (error) {

        } finally {

        }
    }

    useEffect(() => {
        if (group) {
            let admin = false
            setGroupDetails(group)
            if (group?.admins?.includes(user?._id)) {
                admin = true
            }
            setIsAdmin(admin)
            getPointsData(group?._id, group.members.length, admin, group?.admins.length)
        }
    }, [group])

    useEffect(() => {
        if (isAdmin) {
            if (groupDetails?.ableToScores.includes(user?._id)) {
                setAbleToScore(true)
            } else {
                setAbleToScore(false)
            }
        } else {
            setAbleToScore(false)
        }
    }, [isAdmin, groupDetails])
    useEffect(() => {
        setSelectedQuarter("")
        if (allScores.length > 0) {
            if (adminView == "User" || !isAdmin) {
                let temp = (allScores?.filter(x => x?.user?._id == user?._id)).reverse()
                if (sdate && edate) {
                    temp = temp.filter(x => {
                        let start = moment(sdate).startOf('day')
                        let end = moment(edate).endOf('day')
                        if (x?.type == 3) {
                            const dateIsAfter = moment(x?.updatedAt).isAfter(moment(start));
                            const dateIsBefore = moment(x?.updatedAt).isBefore(moment(end));
                            if (dateIsAfter && dateIsBefore) {
                                return x
                            }
                        } else {
                            const dateIsAfter = moment(x?.createdAt).isAfter(moment(start));
                            const dateIsBefore = moment(x?.createdAt).isBefore(moment(end));
                            if (dateIsAfter && dateIsBefore) {
                                return x
                            }
                        }
                    })
                }
                setPoints(temp)
            } else {
                let temp = allScores.reverse()
                if (sdate && edate) {
                    temp = temp.filter(x => {
                        let start = moment(sdate).startOf('day')
                        let end = moment(edate).endOf('day')
                        if (x?.type == 3) {
                            const dateIsAfter = moment(x?.updatedAt).isAfter(moment(start));
                            const dateIsBefore = moment(x?.updatedAt).isBefore(moment(end));
                            if (dateIsAfter && dateIsBefore) {
                                return x
                            }
                        } else {
                            const dateIsAfter = moment(x?.createdAt).isAfter(moment(start));
                            const dateIsBefore = moment(x?.createdAt).isBefore(moment(end));
                            if (dateIsAfter && dateIsBefore) {
                                return x
                            }
                        }
                    })
                }
                setPoints(temp)
            }
            if (sdate && edate) {
                let temp = allScores.filter(x => {
                    let start = moment(sdate).startOf('day')
                    let end = moment(edate).endOf('day')
                    if (x?.type == 3) {
                        const dateIsAfter = moment(x?.updatedAt).isAfter(moment(start));
                        const dateIsBefore = moment(x?.updatedAt).isBefore(moment(end));
                        if (dateIsAfter && dateIsBefore) {
                            return x
                        }
                    } else {
                        const dateIsAfter = moment(x?.createdAt).isAfter(moment(start));
                        const dateIsBefore = moment(x?.createdAt).isBefore(moment(end));
                        if (dateIsAfter && dateIsBefore) {
                            return x
                        }
                    }
                })
                setPointHis(temp)
            } else {
                setPointHis(allScores)
            }


        } else {
            setPointHis([])
            setPoints([])
        }
    }, [adminView, isAdmin, allScores, sdate, edate, refresher])


    // Function to compare two objects based on their properties (e.g., 'id' in this case)
    const comparator = (obj1, obj2) => obj1 === obj2;

    useEffect(() => {
        let all = []
        if (group?.members) {
            const uniquieGMembers = _.uniqWith(group?.members, comparator);
            uniquieGMembers?.forEach(ele => {
                let arr = ((selectedQuarter == ""&!onPressDone) ? allScores : points)?.filter(x => x?.user?._id === ele)
                let name = groupMembersDetails?.find(x => x._id == ele)?.firstName + " " + groupMembersDetails?.find(x => x._id == ele)?.lastName
                if (name?.trim() == "") {
                    name = groupMembersDetails?.find(x => x._id == ele)?.email?.split("@")[0]
                }
                // }
                let c = 0;
                arr.forEach(e => {
                    c = c + e?.point
                });
                if (name || name?.trim() == "") {
                    all.push({ name: name, point: c, _id: ele })
                }
            });
            let final = all.sort((a, b) => b?.point - a?.point)
            setAllranking(final)
        }
    }, [points, allScores,onPressDone, groupMembersDetails])

    const getPointsData = async (id, gMembers, admin, adminNo) => {
        try {
            let res = await hit(`${scores?.getscore}?groups=${id}&groupOnly=true`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let temp = res?.data.filter(x => (x?.type == 4 || x?.type == 5 || x?.postId != null))
                setAllScores(temp)
            }
        } catch (e) {
        } finally {
        }
    }
    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        console.log(tmp)
        return tmp;
    }

    const saveMisDate = (type, dates) => {
        if (type == "clear") {
            let data = { ...filters }
            setOnPressDone(false)
            if (data[groupDetails?._id]) {
                delete data[groupDetails?._id]
                setSdate(null)
                setEdate(null)
                dispatch(updatePointfilter(data))
                updateForAll(null)
            }
            return
        }
        if (dates[0] && dates[1]) {
            let temp = { [groupDetails?._id]: { sdate: new Date(dates[0]).toString(), edate: new Date(dates[1]).toString() } }
            let data = { ...filters, ...temp }
            dispatch(updatePointfilter(data))
            updateForAll(temp)
        }
    }
    const updateForAll = async (date) => {
        if (!isAdmin) {
            return
        }
        setDoc(doc(collection(firebaseDb, "dateRange"), groupDetails?._id), { date })
    }

    const getSavedDate = async (id) => {
        if (!groupDetails?.showMiscBoard || isAdmin) {
            onSnapshot(doc(collection(firebaseDb, "dateRange"), id), (res) => {
                let data = res?.data()
                if (data?.date) {
                    let allFilters = { ...filters, ...data?.date }
                    dispatch(updatePointfilter(allFilters))
                }
            })
        }

    }
    return (
        <>
            {ableToScore &&
                <>
                    <TwoSelectionTab setCurrentTab={(v) => {
                        if (v == 0) {
                            setAdminView("Admin")
                        } else {
                            setAdminView("User")
                        }
                    }} t1={"Admin"} t2="User" style={{ background: "white", marginTop: "0px", marginBottom: "25px" }} />
                </>
            }
            {
                [0].map(x => {
                    let c = 0
                    let avg = 0
                    let fin = 0
                    let totalTemp = 0
                    scoringPosts.forEach(p => {
                        const date1 = moment().toDate();
                        const date2 = moment(p?.scoreStartTime).toDate();
                        const date3 = moment(p?.scoreEndTime).toDate();
                        if (date1 > date2) {
                            if (p?.scoreBy?.includes("likes")) {
                                totalTemp += p?.scorePoint
                            }
                            if (p?.scoreBy?.includes("verification")) {
                                totalTemp += (p?.scorePoint1 * p?.scoreCount)
                            }
                            if (p?.scoreBy?.includes("geo")) {
                                totalTemp += p?.scorePoint2
                            }
                            if (p?.postType1 == "quiz") {
                                totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
                            }
                        }
                    })

                    allScores.filter(x => x.user?._id == user?._id).forEach(x => {
                        if (x?.type == 4) {
                            totalTemp += x?.point
                        }
                        // if (x?.type == 5) {
                        //     totalTemp += x?.point
                        // }
                    })
                    if (isAdmin) {
                        let gMembers = group.members.length
                        let adminNo = group?.admins.length
                        let ableToScores = group?.ableToScores?.length
                        for (let i of allScores) {
                            c += i?.point
                        }
                        let memb = (gMembers + ableToScores - adminNo)

                        if (memb == 0) {
                            if (adminView != "User") {
                                fin = c
                            } else {
                                let newArr = allScores.filter(x => x?.user?._id == user?._id)
                                for (let i of newArr) {
                                    fin += i?.point
                                }
                            }

                        } else {
                            if (adminView != "User") {
                                avg = (c / memb)
                                Math.round(avg)
                                fin = avg
                            } else {
                                let newArr = allScores.filter(x => x?.user?._id == user?._id)
                                for (let i of newArr) {
                                    fin += i?.point
                                }
                            }
                        }
                    } else {
                        let newArr = allScores.filter(x => x?.user?._id == user?._id)
                        for (let i of newArr) {
                            fin += i?.point
                        }

                    }

                    return (<GroupScoreProfile isScore={true} fin={fin} adminView={adminView} totalTemp={totalTemp} isAdmin={isAdmin} avgPoint={avgPoint} yourPoint={yourPoint} team={selectedTeam} />
                    )
                })
            }
            <br />
            <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Groups</Typography>
            <br />

            {/* <Box sx={{ display: "flex" }}>
                <ScoreButtons title={"Daily"} score={"190"} />
                <ScoreButtons title={"Weekly"} style={{ marginLeft: "4%" }} score={"190"} />
            </Box>
            <br />
            <Box sx={{ display: "flex" }}>
                <ScoreButtons title={"Monthly"} score={"190"} />
                <ScoreButtons title={"Miscellaneous"} style={{ marginLeft: "4%" }} score={"190"} />
            </Box> */}
            {splitArray(quarter, 2).map(items => {
                return (
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                        {items?.map((item, index) => {
                            let temp = 0
                            let total = 0
                            let newa = []
                            let filterOutScoringPost = []
                            let manuals = []
                            let arr = []
                            if (item?.title == "Daily") {
                                newa = allScores.filter(x => {
                                    // let date1 = moment();
                                    let date1 = moment().format("YYYYMMDD");
                                    if (x?.type == 3) {
                                        let date2 = moment(x?.updatedAt).format("YYYYMMDD");
                                        if (date1 == date2) {
                                            return x
                                        }
                                    } else {
                                        let date2 = moment(x?.createdAt).format("YYYYMMDD");
                                        if (date1 == date2) {
                                            return x
                                        }
                                    }
                                })
                                filterOutScoringPost = scoringPosts.filter(x => {
                                    let date1 = moment().toDate()
                                    let date2 = moment(x?.scoreStartTime).toDate()
                                    let date3 = moment(x?.scoreEndTime).toDate()
                                    if (date1 >= date2 && date1 <= date3) {
                                        return true
                                    }
                                    return false
                                })
                                manuals = allScores.filter(x => {
                                    if (x?.type == 4 || x?.type == 5) {
                                        let date1 = moment().format("YYYY-MM-DD")
                                        let date2 = moment(x?.createdAt).format("YYYY-MM-DD")
                                        if (date1 == date2) {
                                            return true
                                        }
                                        return false
                                    }
                                })
                            } else if (item?.title == "Weekly") {
                                newa = allScores.filter(x => {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: 1
                                        }
                                    });
                                    let weekStart = moment().startOf('week')
                                    let weekEnd = moment().endOf('week')
                                    if (x?.type == 3) {
                                        const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                        const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                        if (dateIsAfter && dateIsBefore) {
                                            return x
                                        }
                                    } else {
                                        const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                        const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                        if (dateIsAfter && dateIsBefore) {
                                            return x
                                        }
                                    }
                                })
                                filterOutScoringPost = scoringPosts.filter(x => {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: 1
                                        }
                                    });
                                    let weekStart = moment().startOf('week')
                                    let weekEnd = moment().endOf('week')
                                    let startDate = moment(x?.scoreStartTime)
                                    let endDate = moment(x?.scoreEndTime)
                                    if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
                                        return true
                                    }
                                    return false
                                })
                                manuals = allScores.filter(x => {
                                    if (x?.type == 4 || x?.type == 5) {
                                        let weekStart = moment().startOf('week')
                                        let weekEnd = moment().endOf('week')
                                        let startDate = moment(x?.createdAt)
                                        if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd))) {
                                            return true
                                        }
                                        return false
                                    }
                                })
                                arr = [...newa]
                            } else if (item?.title == "Monthly") {
                                newa = allScores.filter(x => {

                                    let weekStart = moment().startOf('month')
                                    let weekEnd = moment().endOf('month')
                                    if (x?.type == 3) {
                                        const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                        const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                        if (dateIsAfter && dateIsBefore) {
                                            return x
                                        }
                                    } else {
                                        const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                        const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                        if (dateIsAfter && dateIsBefore) {
                                            return x
                                        }
                                    }
                                })
                                filterOutScoringPost = scoringPosts.filter(x => {
                                    let weekStart = moment().startOf('month')
                                    let weekEnd = moment().endOf('month')
                                    let startDate = moment(x?.scoreStartTime)
                                    let endDate = moment(x?.scoreEndTime)
                                    if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
                                        return true
                                    }
                                    return false
                                })
                                manuals = allScores.filter(x => {
                                    if (x?.type == 4 || x?.type == 5) {
                                        let weekStart = moment().startOf('month')
                                        let weekEnd = moment().endOf('month')
                                        let startDate = moment(x?.createdAt)
                                        if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd))) {
                                            return true
                                        }
                                        return false
                                    }
                                })
                                arr = [...newa]

                            } else if (item?.title == "Quarterly") {
                                newa = allScores.filter(x => {
                                    let weekStart = moment().startOf('quarter')
                                    let weekEnd = moment().endOf('quarter')
                                    if (x?.type == 3) {
                                        const dateIsAfter = moment(x?.updatedAt).isAfter(moment(weekStart));
                                        const dateIsBefore = moment(x?.updatedAt).isBefore(moment(weekEnd));
                                        if (dateIsAfter && dateIsBefore) {
                                            return x
                                        }
                                    } else {
                                        const dateIsAfter = moment(x?.createdAt).isAfter(moment(weekStart));
                                        const dateIsBefore = moment(x?.createdAt).isBefore(moment(weekEnd));
                                        if (dateIsAfter && dateIsBefore) {
                                            return x
                                        }
                                    }
                                })
                                filterOutScoringPost = scoringPosts.filter(x => {
                                    let weekStart = moment().startOf('quarter')
                                    let weekEnd = moment().endOf('quarter')
                                    let startDate = moment(x?.scoreStartTime)
                                    let endDate = moment(x?.scoreEndTime)
                                    if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
                                        return true
                                    }
                                    return false
                                })
                                manuals = allScores.filter(x => {
                                    if (x?.type == 4 || x?.type == 5) {
                                        let date1 = moment().format("YYYYMMDD")
                                        let date2 = moment(x?.createdAt).format("YYYYMMDD")
                                        if (date1 == date2) {
                                            return true
                                        }
                                        return false
                                    }
                                })
                                arr = [...newa]
                            } else {

                                if (sdate && edate) {
                                    newa = allScores.filter(x => {
                                        let start = moment(sdate).startOf('day')
                                        let end = moment(edate).endOf('day')
                                        if (x?.type == 3) {
                                            const dateIsAfter = moment(x?.updatedAt).isAfter(moment(start));
                                            const dateIsBefore = moment(x?.updatedAt).isBefore(moment(end));
                                            if (dateIsAfter && dateIsBefore) {
                                                return x
                                            }
                                        } else {
                                            const dateIsAfter = moment(x?.createdAt).isAfter(moment(start));
                                            const dateIsBefore = moment(x?.createdAt).isBefore(moment(end));
                                            if (dateIsAfter && dateIsBefore) {
                                                return x
                                            }
                                        }
                                    })
                                    filterOutScoringPost = scoringPosts.filter(x => {
                                        let weekStart = moment(sdate)
                                        let weekEnd = moment(edate)
                                        let startDate = moment(x?.scoreStartTime)
                                        let endDate = moment(x?.scoreEndTime)
                                        if ((startDate.isAfter(weekStart) && startDate.isBefore(weekEnd)) || (endDate.isAfter(weekStart) && endDate.isBefore(weekEnd)) || (endDate.isAfter(weekEnd) && startDate.isBefore(weekStart))) {
                                            return true
                                        }
                                        return false
                                    })
                                    manuals = allScores.filter(x => {
                                        if (x?.type == 4 || x?.type == 5) {
                                            let date1 = moment().format("YYYYMMDD")
                                            let date2 = moment(x?.createdAt).format("YYYYMMDD")
                                            if (date1 == date2) {
                                                return true
                                            }
                                            return false
                                        }
                                    })
                                }
                                arr = [...newa]
                            }

                            let totalTemp = 0
                            filterOutScoringPost.forEach(p => {
                                if (moment(p?.scoreStartTime).toDate() > moment().toDate()) {

                                } else {
                                    if (p?.scoreBy?.includes("likes")) {
                                        totalTemp += p?.scorePoint
                                    }
                                    if (p?.scoreBy?.includes("verification")) {
                                        totalTemp += (p?.scorePoint1 * p?.scoreCount)
                                    }
                                    if (p?.scoreBy?.includes("geo")) {
                                        totalTemp += p?.scorePoint2
                                    }
                                    if (p?.postType1 == "quiz") {
                                        totalTemp += (p?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0))
                                    }
                                }
                            })


                            manuals.filter(x => x.user?._id == user?._id).forEach(x => {
                                if (x?.type == 4) {
                                    totalTemp += x?.point
                                }
                                // if (x?.type == 5) {
                                //     totalTemp += x?.point
                                // }

                            })


                            if (isAdmin) {
                                if (adminView != "User") {
                                    newa.forEach(e => {
                                        temp += e?.point
                                    })
                                    console.log("DATA", newa)
                                    temp = (temp / ((groupDetails?.members?.length + groupDetails?.ableToScores?.length - groupDetails?.admins?.length) <= 0 ? 1 : (groupDetails?.members?.length + groupDetails?.ableToScores?.length - groupDetails?.admins?.length)))
                                    totalTemp = (totalTemp / groupDetails?.members?.length)
                                } else {
                                    let arr = newa.filter(x => x?.user?._id == user?._id)
                                    arr.forEach(e => {
                                        temp += e?.point
                                    })
                                }
                            } else {
                                let arr = newa.filter(x => x?.user?._id == user?._id)
                                arr.forEach(e => {
                                    temp += e?.point
                                })
                            }

                            return (
                                <ScoreButtons
                                    setSelectedQuarter={setSelectedQuarter}
                                    styleView={{ marginLeft: index != 0 ? "4%" : "0%" }}
                                    setSdate={setSdate}
                                    setGroup={setGroup}
                                    setEdate={setEdate}
                                    groupDetails={groupDetails}
                                    isAdmin={isAdmin}
                                    adminView={adminView}
                                    totalScore={totalTemp}
                                    onPressDone={onPressDone}
                                    sDate={sdate}
                                    eDate={edate}
                                    item={item}
                                    index={index}
                                    selected={selectedQuarter}
                                    point={temp}
                                    onPress={() => {
                                        if (item?.show == 0) {
                                            getSavedDate(groupDetails?._id)
                                            setOnPressDone(true)
                                            return
                                        }
                                        setOnPressDone(false)
                                        setSelectedQuarter(item?.title)
                                        setSdate(null)
                                        setEdate(null)
                                        setPoints(newa)
                                        setPointHis(newa)
                                        // if(isAdmin){
                                        //     saveMisDate("clear")
                                        // }

                                    }}
                                    refreshData={() => {
                                        setRefresher(!refresher)
                                    }}
                                    changeToAll={() => {
                                        setOnPressDone(false)
                                        setSelectedQuarter("")
                                        setSdate(null)
                                        setEdate(null)
                                        setRefresher(!refresher)
                                        saveMisDate("clear")
                                        // dispatch(setDateRange(miscDateRanges?.list?.filter(x => x.id != group._id)))
                                    }}

                                    onDateSelect={(v1, v2) => {
                                        saveMisDate("save", [v1, v2])
                                        setPointModal(false)
                                    }}
                                />
                            )
                        })}
                    </Box>
                )
            })}
            <br />
            <TwoSelectionTab setCurrentTab={setCurrentTab} t1={"Points"} t2="Ranking" style={{ background: "white" }} />
            <br />
            {currentTab == 0 &&
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    {points?.map((i, j) => {
                        return <PointsComp i={i} j={j} style={{ marginTop: j != 0 ? "15px" : "0px" }} admin={isAdmin} key={i?._id} adminView={adminView} />
                    })
                    }
                </Box>
            }
            {currentTab == 1 &&
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <RankingHeader isAdmin={isAdmin} />
                    {allranking?.filter(x => {
                        const admins = group?.admins
                        const ableToScores = group?.ableToScores
                        if (admins?.includes(x?._id) && !ableToScores?.includes(x?._id)) {
                            return false
                        }
                        return true
                    })?.map((i, j) => {
                        return (<RankingRow adminView={adminView} points={points} isAdmin={isAdmin} i={i} j={j} key={i?._id} admin={isAdmin} user={user?._id} currentGrp={group}
                            onPress={() => {
                                if (isAdmin) {
                                    // navigation
                                }
                            }} />)
                    })}
                    {isAdmin && <Typography sx={{ fontSize: pxToRem(14), marginTop: "10px", fontWeight: "500" }}>Note:- A for Admin and S for Scoring enabled.</Typography>}
                </Box>
            }
            <br />
        </>
    )
}


const ScoreButtons = ({ item, index, setGroup, groupDetails, refreshData, changeToAll, isAdmin, adminView, styleView, selected, totalScore, onDateSelect, onPress, point, onPressDone, sDate, eDate, setEdate, setSdate, setSelectedQuarter }) => {
    let isSelected = selected == item?.title
    const theme = useTheme()
    const [on, setOn] = useState(false)
    // showMiscBoard
    useEffect(() => {
        if (groupDetails?.showMiscBoard) {
            setOn(true)
        } else {
            setOn(false)
        }
    }, [groupDetails])

    const dispatch = useDispatch()
    const updateGroupDetailsData = async (value) => {
        try {
            dispatch(setLoading(true))
            let res = await hit("teams/" + groupDetails?._id, "patch", { showMiscBoard: value })
            if (!res.err) {
                setOn(value)
                setGroup(res.data)
            } else {
                alert(res.msg)
            }
        } catch (err) {

        } finally {
            if (value == false) {

            }
            dispatch(setLoading(false))
        }
    }
    return (
        <Box onClick={onPress} sx={[{ boxShadow: "0px 5px 20px #26262626", height: "80px", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", px: "10px", py: "10px", borderRadius: "5px", flex: 1, background: (isSelected && !onPressDone) ? theme.palette.primary.main : ((onPressDone && item?.show == 0) ? theme.palette.primary.main : "white") }, styleView]}>
            <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "600", color: (isSelected && !onPressDone) ? ((onPressDone && item?.show == 0) ? theme.palette.primary.main : "white") : theme.palette.text, }}>{item?.title}</Typography>
                {(item?.show == 0 && isAdmin) && <Box sx={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => { updateGroupDetailsData(!on) }} component={"img"} src={on ? "assets/notis/on.svg" : "assets/notis/off.svg"} />}
            </Box>
            {item?.show != 0 && <Box sx={{ display: "flex", background: (isSelected && !onPressDone) ? ((onPressDone && item?.show == 0) ? theme.palette.primary.main : "white") : "transparent", border: `1px solid ${theme.palette.primary.main}`, justifyContent: "center", alignItems: "center", padding: "4px", borderRadius: "4px", marginTop: "6px" }}>
                {/* <Typography sx={{ color: theme.palette.primary.main, fontSize: "14px", fontWeight: "600" }}>
                {Math.round(point)}{!isAdmin ? `/${(point > totalScore ? point : totalScore)}` : adminView == "User" ? `/${(point > totalScore ? point : totalScore)}` : ""}</Typography> */}
                <Typography sx={{ color: theme.palette.primary.main, fontSize: "14px", fontWeight: "600" }}>{Math.round(point)}{!isAdmin ? `` : adminView == "User" ? `` : ""}</Typography>
            </Box>}
            {item?.show == 0 &&
                <Box onClick={e => {
                    e.stopPropagation()
                }} display={"flex"} alignItems={"center"}>
                    <Box sx={{ display: "flex", background: "white", border: `1px solid ${theme.palette.primary.main}`, marginRight: "5px", justifyContent: "center", alignItems: "center", padding: "4px", borderRadius: "4px", }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: "14px", fontWeight: "600" }}>{Math.round(point)}{!isAdmin ? `` : adminView == "User" ? `` : ""}</Typography>
                        {/* <Typography sx={{ color: theme.palette.primary.main, fontSize: "14px", fontWeight: "600" }}>{Math.round(point)}{!isAdmin ? `/${(point > totalScore ? point : totalScore)}` : adminView == "User" ? `/${(point > totalScore ? point : totalScore)}` : ""}</Typography> */}
                    </Box>
                    {(!on && !isAdmin) && <Typography fontSize={"12px"} color={"white"}>{moment(sDate).format("MMM, DD , YYYY")}-{moment(eDate).format("MMM, DD , YYYY")}</Typography>}
                    {(on || isAdmin) && <DateRangePicker placeholder="Select Date Range" format="MMM, dd, yyyy" style={{ color: theme.palette.primary.main }} value={[sDate, eDate]} placement="auto" onChange={v => {

                        if (v) {
                            setSelectedQuarter("")
                            if (v.length == 1) {
                                onDateSelect(v[0], v[0])
                                return
                            }
                            if (v.length == 2) {
                                onDateSelect(v[0], v[1])
                            }
                        } else {
                            if (changeToAll) {
                                changeToAll()
                            }
                        }
                    }} />}
                </Box>
            }
        </Box>
    )
}

const RankingHeader = ({ isAdmin }) => {
    return (
        <Box sx={{ height: "40px", display: "flex", px: "10px", justifyContent: "space-between", textAlign: "left", alignItems: "center", border: "1px solid #0BB2D0", background: "#0BB2D00D", width: "100%", borderRadius: "5px" }}>
            <Typography color={"primary"} sx={{ width: "20%", fontWeight: "600", fontSize: pxToRem(14) }}>Ranking</Typography>
            {isAdmin && <Typography color={"primary"} sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>Name</Typography>}
            <Typography color={"primary"} sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>Points</Typography>
        </Box>
    )
}

const RankingRow = ({ isAdmin, i, j, currentGrp, user, adminView, points }) => {
    const admins = currentGrp?.admins
    const ableToScores = currentGrp?.ableToScores
    const members = currentGrp?.members
    const [showPoint, setShowPoint] = useState(false)


    return (
        <>
            <Box onClick={() => {
                setShowPoint(!showPoint)
            }} sx={{ height: "40px", cursor: "pointer", display: "flex", px: "10px", justifyContent: "space-between", textAlign: "left", alignItems: "center", marginTop: "15px", background: (user == i?._id) ? "#98fb98" : "#0DB1D41A", width: "100%", borderRadius: "5px" }}>
                <Typography sx={{ width: "20%", fontWeight: "600", fontSize: pxToRem(14) }}>{j + 1}</Typography>
                {<Typography sx={{ width: "40%", fontWeight: "600", fontSize: pxToRem(14) }}>{(isAdmin || user == i?._id) ? (i?.name + " ") : ""}{admins?.includes(i?._id) && isAdmin ? "(A) " : ""}{ableToScores?.includes(i?._id) && isAdmin ? "(S) " : ""}{(members && !members?.includes(i?._id)) ? "(L)" : ""}</Typography>}
                <Typography sx={{ width: "40%", fontSize: pxToRem(14), fontWeight: "600" }}>{i?.point}</Typography>
            </Box>
            {showPoint && points?.filter(x => i?._id == x?.user?._id)?.map((i, j) => {
                return <PointsComp i={i} j={j} style={{ marginTop: "15px" }} admin={isAdmin} key={i?._id} adminView={adminView} />
            })
            }
        </>
    )
}