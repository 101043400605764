import { Avatar, Box, Button, Popover, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { pxToRem } from '../../theme/typography';
import { getAllNotifications, setNotiLoad } from '../../store/notification'
import { useEffect, useState } from 'react';
import { image_url, notifications, notificationsDelete, verifynoti } from '../../services/endpoints';
import showToast from '../../utils/showToast'
import { setLoading } from '../../store/load';
import hit from '../../services/apiManager';
import DeleteModal from '../DeleteModal';
import useLongPress from '../../utils/useLongPress';
import moment from 'moment';
import ScoreAccessModal from '../ScoreAccessModal';
import ScoreAccessDeniedModal from '../ScoreAccessDeniedModal';
import { useNavigate } from 'react-router-dom';
export default function NotificationPop({ anchorEl, handleClose, open, id }) {


    const dispatch = useDispatch()
    const list = useSelector(state => state?.notification?.list)
    const page = useSelector(state => state?.notification?.page)
    const totalResults = useSelector(state => state?.notification?.totalResults)
    const currentPage = useSelector(state => state?.notification?.page)
    const isloading = useSelector(state => state?.notification?.load)

    const navigate = useNavigate()
    const [notificationList, setNotificationList] = useState([])
    const [selectedArr, setSelectedArr] = useState([])
    const [selectionMode, setSelectionMode] = useState(false)
    const [deniedModal, setDeniedModal] = useState(false)
    const [deniedDetail, setDeniedDetail] = useState(null)
    const [acceptModal, setAcceptModal] = useState(false)
    const [check, setCheck] = useState(false)
    const [unselect, setUnselect] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)


    useEffect(() => {
        if (open) {
            readNotifications()
            dispatch(getAllNotifications())
        }
    }, [open])
    useEffect(() => {
        setNotificationList(list)
    }, [list])
    useEffect(() => {
        if (unselect) {
            setUnselect(false)
        }
    }, [unselect])

    const readNotifications = async () => {
        try {
            const res = await hit(notifications, "patch")
            if (!res?.err) {
                dispatch(getAllNotifications())
            }
        } catch (e) {
        } finally {
        }
    }
    const deleteNotifications = async (arr) => {
        if (arr.length == 0) {
            showToast("No items selected", "warning")
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(notificationsDelete, "post", { list: arr })
            if (res?.err) {
                showToast(res?.msg, "warning")
            } else {
                showToast("Deleted sucessfully.")
            }
        } catch (e) {
        } finally {
            setNotificationList([])
            dispatch(getAllNotifications())
            setSelectedArr([])
            setSelectionMode(false)
            dispatch(setLoading(false))
        }
    }
    const notiAction = async (action, item) => {
        if (action == "verify") {
            try {
                let res = await hit(`${verifynoti}/${item?._id}`, "patch", { type: 1 })
                if (res?.err) {
                    showToast(res?.msg)
                } else {
                    if(item?.isSecondType){
                        showToast("comment approved!!")
                        setTimeout(() => {
                            deleteNotifications([item._id])
                        }, 900);
                    }else{
                        setAcceptModal(true)
                    }
                 
                }
            } catch (e) {
            } finally {
            }
        } else {
            setDeniedModal(true)
        }
    }
    const denyAcion = async (id, reason) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${verifynoti}/${id}`, "patch", { type: 2, reason })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Request denied")
                setTimeout(() => {
                    deleteNotifications([id])
                }, 900);
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            sx={{ marginTop: "10px" }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box sx={{ maxHeight: "60vh", minWidth: "100px", overflowY: "auto" }}>
                {selectionMode == true &&
                    <Box
                        component={"img"}
                        src="assets/teams/delete.svg"
                        onClick={() => {
                            deleteNotifications(selectedArr)
                        }}
                        sx={{ marginLeft: "90%", cursor: "pointer", height: "25px", width: "25px", marginTop: "10px" }}
                    />
                }
                {notificationList?.map((item, index) => (
                    <NotiComp
                        index={index}
                        item={item}
                        arr={selectedArr}
                        unselect={unselect}
                        onLongPress={(t) => {
                            setSelectionMode(true)
                            let temp = []
                            temp = [...selectedArr]
                            if (!temp.includes(t)) {
                                temp.push(t)
                            }
                            setSelectedArr(temp)
                        }}
                        onPress={() => {
                            if (selectionMode) {
                                if (check) {
                                    setCheck(false)
                                }
                                let temp = []
                                temp = [...selectedArr]
                                if (!temp.includes(item?._id)) {
                                    temp.push(item?._id)
                                } else {
                                    let ind = temp.indexOf(item?._id)
                                    temp.splice(ind, 1)
                                    if (temp.length == 0) {
                                        setSelectionMode(false)
                                    }
                                }
                                setSelectedArr(temp)
                            } else {
                                if (item?.postId) {
                                    if (item?.notiType != 9) {
                                        if (item?.postId?.isScorePost == false && item?.postId?.isApproved == 0) {
                                            return
                                        }
                                        handleClose()
                                        navigate("/user/post_detail", { state: { id: item?.postId?._id } })

                                        // navigation.navigate("IndividualPost", { id: item?.postId?._id })
                                    }
                                }
                            }
                        }}
                        onPressVerify={() => {
                            setDeniedDetail(item)
                            notiAction("verify", item)
                        }}
                        onPressDeny={() => {
                            setDeniedDetail(item)
                            notiAction("deny", item)
                        }}
                    />
                ))}
                {!isloading ? <Box sx={{ width: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {totalResults > list?.length && <Button onClick={() => {
                        dispatch(setNotiLoad(true))
                        dispatch(getAllNotifications(10, page + 1))
                    }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                        Load More
                    </Button>}
                </Box> :
                    <Box sx={{ width: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src='assets/loader1.gif' style={{ height: "40px" }} />
                    </Box>
                }

            </Box>
            <Typography onClick={() => {
                setDeleteModal(true)
                let temp = []
                notificationList.forEach((e) => {
                    temp.push(e?._id)
                })
                setSelectedArr(temp)
            }} color={"primary"} sx={{ cursor: "pointer", fontSize: pxToRem(12), fontWeight: "600", textAlign: "center", marginY: "20px" }}>Clear All</Typography>
            <DeleteModal
                title={"You want to delete notifications?"}
                open={deleteModal}
                handleClose={() => { setDeleteModal(false); setSelectedArr([]); setUnselect(true) }}
                onPressNo={() => { setDeleteModal(false); setSelectedArr([]); setUnselect(true) }}
                onPressYes={() => { setDeleteModal(false); deleteNotifications(selectedArr); }}
            />
            <ScoreAccessModal open={acceptModal} setOpen={setAcceptModal} detail={deniedDetail}
                onPressOk={(id) => {
                    deleteNotifications([id])
                    setAcceptModal(false)
                }} />
            <ScoreAccessDeniedModal open={deniedModal} setOpen={setDeniedModal} detail={deniedDetail}
                onPressOk={(r, data) => {
                    setDeniedModal(false)
                    denyAcion(data?._id, r)
                }}
                outPressed={() => {
                    setDeniedModal(false)
                }} />
        </Popover>
    );
}

const NotiComp = ({ item, index, onLongPress, arr, onPress, onPressVerify, onPressDeny, unselect }) => {
    const [isIncluded, setIsIncluded] = useState(false)
    const theme = useTheme()
    useEffect(() => {
        if (arr.includes(item?._id)) {
            setIsIncluded(true)
        }
    }, [arr])
    useEffect(() => {
        if (unselect) {
            setIsIncluded(false)
        }
    }, [unselect])
    let name = []
    if (item?.body?.includes("group")) {
        let n = item?.body?.split("group")
        name = [...n]
        console.log(name);
    }
    const longPress = useLongPress();

    return (
        <Box

            onClick={() => {
                onPress(item?._id)
                setIsIncluded(false)
            }}
            {...longPress(() => {
                onLongPress(item?._id);
                setIsIncluded(true)
            })}
            sx={{ cursor: "pointer", minHeight: "60px", position: "relative", p: "10px", borderRadius: "5px", width: "400px", display: "flex", alignItems: "center", boxShadow: "0px 5px 10px #00000026", background: "white", marginX: "10px", marginTop: "20px" }}>
            <Avatar sx={{ height: "50px", width: "50px", borderRadius: "50px", background: "black" }} src={isIncluded == true ? "assets/notifications/s.svg" : image_url + item?.refUsers[0]?.profilePic} />
            <Box sx={{ display: "flex", flexDirection: "column", wordBreak: "break-all" }}>
                {name?.length == 0 ? <Typography sx={{ fontSize: pxToRem(14), fontWeight: "500", marginLeft: "5px" }}>{item?.body}</Typography>
                    :
                    <Typography sx={{ fontSize: pxToRem(14), fontWeight: "500", marginLeft: "5px" }}>{`${name[0]}group`}<Typography component={"span"} sx={{ fontSize: pxToRem(14), fontWeight: "600" }}>{`${name[1]}`}</Typography></Typography>
                }
                {item?.notiType == "8" &&
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                        <Button title={""}
                            variant="contained"
                            color='error'
                            sx={{ height: 24, width: "46%", fontSize: pxToRem(12) }}
                            onClick={(e)=>{
                                e.stopPropagation()   
                                onPressDeny()
                                }} >
                            Deny
                        </Button>
                        <Button
                            color='success'
                            variant="contained"
                            sx={{ height: 24, width: "46%", fontSize: pxToRem(12) }}
                            onClick={(e)=>{
                                e.stopPropagation()  
                                onPressVerify()
                                }} >
                            Verify
                        </Button>
                    </Box>
                }
            </Box>
            <Typography sx={{ position: "absolute", top: 5, right: 5, fontSize: pxToRem(10) }}>{moment(item?.createdAt).format("Do MMM, hh:mm a")}</Typography>
        </Box>
    )
}