import { Box, Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import { pxToRem } from "../../theme/typography";

export default function LandingPageFooterSection() {
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    return (
        <>
            <Box sx={{ background: "#0BB2D020", width: "100vw" }}>
                <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                    {!isMobile && <Box component={"img"} src="assets/landing/foot.svg" sx={{ height: "400px" }}></Box>}
                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", paddingTop: "20px", justifyContent: "center", alignItems: "center" }}>
                        <img src="assets/logo.png" style={{ objectFit: "contain" }} height={"70px"} width={"250px"} />
                        <br />
                        <Box display={"flex"} width={"100%"} justifyContent="center" mt={2} >
                            <Typography sx={{ fontSize: pxToRem(16), fontWeight: "800" }}>Home</Typography>
                            <Typography sx={{ fontSize: pxToRem(16), fontWeight: "800", mx: "5%" }}>About Us</Typography>
                            <Typography sx={{ fontSize: pxToRem(16), fontWeight: "800" }}>Why Us</Typography>
                            <Typography sx={{ fontSize: pxToRem(16), fontWeight: "800", mx: "5%" }}>FAQ</Typography>
                            <Typography sx={{ fontSize: pxToRem(16), fontWeight: "800", mr: "5%" }}>ContactUs</Typography>
                        </Box>
                        <br />
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                            <img alt="android" src="assets/landing/android.png" style={{ height: "70px", objectFit: "contain" }} />
                            <img alt="ios" src="assets/landing/ios.png" style={{ height: "70px", objectFit: "contain", marginLeft: "20px" }} />
                        </Box>
                    </Box>
                    {!isMobile && <Box component={"img"} src="assets/landing/foot.svg" sx={{ height: "400px" }}></Box>}
                </Box>
            </Box>
            {!isMobile && <Box sx={{ background: "#262626", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography textAlign={"center"} color="white">© 2022 - All Rights Reserved - Culture Score | Privacy and Terms of Use | Designed & Developed by</Typography>
                <Box sx={{ height: "20px", marginLeft: "5px" }} component="img" src="assets/landing/solidappmaker.png" />
            </Box>}
            {isMobile && <Box sx={{ background: "#262626", height: "60px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography textAlign={"center"} fontSize={"12px"} color="white">© 2022 - All Rights Reserved - Culture Score | Privacy and</Typography>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography textAlign={"center"} fontSize={"12px"} color="white">Terms of Use | Designed & Developed by</Typography>
                    <Box sx={{ height: "12px", marginLeft: "5px" }} component="img" src="assets/landing/solidappmaker.png" />
                </Box>
            </Box>}
        </>
    )
}