import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import hit from "../services/apiManager";
import { gettc } from "../services/endpoints";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";

export default function AboutUs() {
    const navigate = useNavigate()
    const [data, setData] = React.useState(null)
    const dispatch=useDispatch()
    React.useEffect(() => {
        getDetails()
    }, [])

    const getDetails = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(gettc, "get")
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                setData(res?.data)
                console.log(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>About Us</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                    <Typography sx={{ textAlign: "left", fontSize: pxToRem(14) }}>{data?.about}</Typography>
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
        </Box>
    )
}