import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pxToRem } from "../theme/typography";

export default function Settings() {
    const navigate = useNavigate()
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px", textAlign: "left" }}>
                <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Account Settings</Typography>
                <Box sx={{ width: "100%", borderRadius: "5px", marginTop: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", p: "10px", minHeight: "100px" }}>
                    <RowComp onClick={() => navigate("/user/editProfile")} title={"Edit Profile"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/changePassword")} t title={"Change Password"} />
                    {/* <br />
                    <RowComp onClick={() => navigate("/user/subs")} title={"Subscription"} /> */}
                    <br />
                    <RowComp onClick={() => navigate("/user/access")} title={"My Access Code"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/chatSettings")} title={"Chat Settings"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/notis")} title={"Notification"} />
                    <br />
                    <br />
                    <Box sx={{ display: "flex", marginBottom: "25px", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Button variant="contained" sx={{ width: "150px", background: "rgb(254,90,89)", borderRadius: "0px", marginX: "auto" }}>
                            Delete Account
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", minHeight: "100px", textAlign: "left" }}>
                <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>Contact Us</Typography>
                <Box sx={{ width: "100%", borderRadius: "5px", marginTop: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", p: "10px", minHeight: "100px" }}>
                    <RowComp onClick={() => navigate("/user/terms")} title={"Terms & Services"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/aboutUs")} title={"About Us"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/contact")} title={"Contact Us"} />
                    <br />
                    <RowComp onClick={() => navigate("/user/faq")} title={"FAQ"} />
                    <br />
                    <br />
                </Box>
            </Box>
        </Box>
    )
}

const RowComp = ({ title, onClick }) => {
    return (
        <Box onClick={() => {
            if (onClick) {
                onClick()
            }
        }} sx={{ cursor: "pointer", height: "50px", padding: "10px", background: "#0DB1D41A", display: "flex", alignItems: "center", borderRadius: "5px" }}>
            <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), flex: 1, marginLeft: "5px" }}>{title}</Typography>
            <Box component={"img"} sx={{ height: "10px", width: "10px", rotate: "180deg", objectFit: "contain" }} src="assets/settings/direction.png" />
        </Box>
    )
}