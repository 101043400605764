import { Box, Button, Typography } from "@mui/material";
import Input, { CustomPhoneInput, DatePickerInput, DropDownInput } from "../components/Login/Input";
import SocialButton from "../components/Login/SocialButton";
import UserTypeSelect from "../components/Login/UserTypeSelect";
import { pxToRem } from "../theme/typography";
import '../css/signup.css';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import hit from "../services/apiManager";
import { auth as authApi, default_pic, uploads } from '../services/endpoints'
import { firebaseAuth } from "../utils/firebaseInit";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { storeData } from "../services/asyncStore";
import { setLoading } from "../store/load";
import moment from "moment";
import showToast from "../utils/showToast";
import { authorize } from "../store/auth";
import DatePickerModal from "../components/DatePickerModal";
export default function Signup() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")

    const [email, setEmail] = useState("")
    const [dob, setDob] = useState(new Date())
    const [grade, setGrade] = useState("")
    const [mobile, setMobile] = useState("")
    const [pass, setPass] = useState("")
    const [cpass, setCpass] = useState("")
    const [show, setShow] = useState(false);
    const [countryCode, setCountryCode] = useState('+1');
    const [profile, setProfile] = useState(null)
    const [userType, setUserType] = useState("User")
    const [accountType, setAccountType] = useState("Business")
    const [open, setOpen] = useState(false)
    const [grades, setGrades] = useState([
        { name: "Pre-K" },
        { name: "Kindergarten" },
        { name: "1st" },
        { name: "2nd" },
        { name: "3rd" },
        { name: "4th" },
        { name: "5th" },
        { name: "6th" },
        { name: "7th" },
        { name: "8th" },
        { name: "9th" },
        { name: "10th" },
        { name: "11th" },
        { name: "12th" },
    ])

    useEffect(() => {
        if (accountType == "Business") {
            setGrade("")
        }
    }, [accountType])
    const handleFile = async (e) => {
        try {
            if (e.target.files[0]) {
                let file = e.target.files[0]
                if (file?.size <= 10000000) {
                    setProfile(e.target.files[0])
                } else {
                    showToast("Image cannot be more than 10mb","warning")
                }

            }
        } catch (err) {
            console.log(err)
        }
    }

    const uploadImage = async () => {

        if (profile == null) {
            submit(default_pic)
        }
        try {
            let formdata = new FormData()
            formdata.append("file", profile)
            let res = await hit(uploads, "post", formdata)
            if (!res.err) {
                if (res?.data?.length > 0) {
                    submit(res?.data[0])
                }
            } else {
                showToast(res?.msg, "error")
            }
        } catch (err) {
        } finally {
        }
    }
    const submit = async (pic) => {
        let commonFields = {
            "firstName": fname,
            "lastName":lname,
            "email": email,
            "phone": mobile,
            "cc": countryCode,
            "password": pass,
            "profilePic": pic,
            "role": userType.toLowerCase(),
            "dob": (moment(dob).unix() * 1000),
            "userType": accountType.toLowerCase(),
            "os": "web"
        };
        let body = accountType != "Student" ? {
            ...commonFields,
        } :
            {
                ...commonFields,
                "grade": grade
            };
        try {
            dispatch(setLoading(true))
            let res = await hit(authApi.register, "post", body)
            console.log(res);
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                storeData("@tokens", res?.data?.tokens)
                navigate("/otp", { state: { from: "signup", user: res?.data?.user } })
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const googleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            signInWithPopup(firebaseAuth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    let f_name = user?.displayName?.split(" ")[0] ?? ""
                    let l_name = user?.displayName?.split(" ")[1] ?? ""
                    if (user?.email) {
                        socialLogin(user?.email, "google", token, f_name, l_name)
                    }
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    showToast(error.message, "error")
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });

        } catch (err) {

        } finally {

        }

    }
    const appleLogin = async () => {
        try {
            const provider = new OAuthProvider("apple.com");
            provider.addScope('email');
            provider.addScope('name');
            signInWithPopup(firebaseAuth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = OAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const idToken = credential.idToken;
                    // The signed-in user info.
                    const user = result.user;
                    let f_name = user?.displayName?.split(" ")[0] ?? ""
                    let l_name = user?.displayName?.split(" ")[1] ?? ""
                    if (user?.email) {
                        socialLogin(user?.email, "apple", token, f_name, l_name)
                    }
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    showToast(error.message, "error")
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });

        } catch (err) {

        } finally {

        }

    }

    const socialLogin = async (email, loginType, token, firstName, lastName) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(authApi.socialLogin, "post", { email, loginType, token, firstName, lastName, "role": userType.toLowerCase(), "os": "web" })
            if (res?.err) {
                showToast(res?.msg, "erroe")
            } else {
                storeData("@tokens", res?.data?.tokens)
                dispatch(authorize({ user: res?.data?.user }))
                res?.data?.user?.role == "user" ? navigate("/user/home") : navigate("/user/home")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ width: "100vw", position: "relative", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box component={"img"} src="assets/signup/signupfullback.png" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box component={"img"} src="assets/login/fullback1.svg" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box className="noScrollBar" sx={{ width: { xs: "90vw", md: "40vw" }, overflowY: "auto", scrollbarWidth: "none", minWidth: { md: "550px" }, borderRadius: "10px", position: "relative", height: "90vh", backgroundImage: "linear-gradient(150deg,rgb(212,241,247), white ,rgb(254,243,214))" }}>
                <Box sx={{ height: "100%", width: "100%", paddingY: "7%", paddingX: { xs: "5%", md: "12%" }, position: "relative" }}>
                    <Box component={"img"} src="assets/logo.png" sx={{ width: { md: "200px", sm: "200px" }, mx: "auto", height: "60px", objectFit: "contain" }} />
                    <Typography sx={{ fontSize: pxToRem(26), fontWeight: "700", marginTop: "10px" }}>Sign Up</Typography>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "500", marginTop: "4px" }}>We'll keep this private, like everything you share with us</Typography>
                    <br />
                    <Box sx={{ display: "flex", alignItems: "center", px: "10%" }}>
                        <SocialButton onPress={() => {
                            googleSignIn()
                        }} title={"Login with Google"} img={"assets/login/google.png"} />
                        <SocialButton onPress={() => {
                            appleLogin()
                        }} title={"Login with Apple"} img={"assets/login/apple.png"} textStyle={{ color: "#F5BA16" }} style={{ background: "#FEBE2D17", marginLeft: "10px" }} />
                    </Box>
                    <br />
                    <UserTypeSelect onSelect={(v) => {
                        setUserType(v)
                    }} type={0} />
                    <br />
                    {userType == "User" && <><UserTypeSelect onSelect={(v) => {
                        setAccountType(v)
                    }} type={1} />
                        <br /></>}
                    <Typography sx={{ fontSize: pxToRem(12), fontWeight: "600", mb: "10px", mt: "20px" }}>Or Create Using Email</Typography>
                    <Box sx={{ width: "90%", marginX: "auto" }}>
                        <Box sx={{ position: "relative", marginX: "auto", marginTop: "20px" }}>
                            <Box component={"img"} src={profile ? URL.createObjectURL(profile) : "assets/signup/picture.png"} sx={{ height: "80px", position: "relative", width: "80px", marginX: "auto", objectFit: "contain" }} />
                            <input type="file" id="file-selector" accept="image/png, image/jpeg" onChange={handleFile} style={{ height: "80px", width: "100%", marginX: "auto", top: 0, position: "absolute", left: 0, opacity: 0, zIndex: 111 }}></input>
                        </Box>
                        <Typography sx={{ fontSize: pxToRem(12), fontWeight: "600", mb: "10px" }}>Add profile picture</Typography>
                        <Input value={fname} onChange={t => {
                            setFname(t)
                        }} titleStyle={{ textAlign: "left" }} title="First Name" img={"assets/signup/user.svg"} />
                        <br />
                        <Input value={lname} onChange={t => {
                            setLname(t)
                        }} titleStyle={{ textAlign: "left" }} title="Last Name" img={"assets/signup/user.svg"} />
                        <br />
                        <Input value={email} onChange={t => {
                            setEmail(t)
                        }} titleStyle={{ textAlign: "left" }} title="Email" img={"assets/login/mail.svg"} />
                        {accountType == "Student" && <><br />
                            <DropDownInput onChange={v => {
                                setGrade(v)
                            }} value={grade} options={grades.map(x => x.name)} titleStyle={{ textAlign: "left" }} title="Grade" img={"assets/signup/grade.svg"} />
                        </>}
                        <br />
                        <DatePickerInput onClick={() => {
                            setOpen(true)
                        }} titleStyle={{ textAlign: "left" }} value={moment(dob).format("DD/MM/YYYY")} title="Date of birth" img={"assets/signup/grade.svg"} />
                        <br />
                        <CustomPhoneInput titleStyle={{ textAlign: "left" }} value={mobile} img={"assets/signup/phone.svg"} onChange={v => setMobile(v)} title="Mobile Number" />
                        <br />
                        <Input value={pass} onChange={t => {
                            setPass(t)
                        }} isPass={true} titleStyle={{ textAlign: "left" }} title="Password" img={"assets/login/eye.svg"} />
                        <br />
                        <Input value={cpass} onChange={t => {
                            setCpass(t)
                        }} isPass={true} titleStyle={{ textAlign: "left" }} title="Confirm Password" img={"assets/login/eye.svg"} />
                        <Button onClick={() => {
                            if (fname.trim() == "") {
                                showToast("First name is required.", "warning")
                            } else if (email.trim() == "") {
                                showToast("Email is required.", "warning")
                            } else if (mobile == "") {
                                showToast("Phone number is required.", "warning")
                            } else if (accountType == "Student" && grade.trim() == "") {
                                showToast("Grade is required.", "warning")
                            } else if (pass == "") {
                                showToast("Password is required.", "warning")
                            } else if (pass != cpass) {
                                showToast("Password does not match.", "warning")
                            } else if (dob == "") {
                                showToast("All fields are required.", "warning")
                            } else {
                                uploadImage()
                            }
                        }} sx={{ borderRadius: "5px", height: "55px",fontSize:"15px", marginTop: "16px", width: "90%" }} variant="contained" >
                            Sign Up
                        </Button>
                        <Typography onClick={() => {
                            navigate("/login")
                        }} sx={{ fontSize: "12px", fontWeight: "600", my: "10px" }}>Already have an account? <Typography component={"span"} color="primary" sx={{ fontSize: "12px", fontWeight: "600" }}>Login</Typography></Typography>
                        <br />
                        <br />
                        <Typography sx={{ fontSize: "12px" }}>By creating an account, you agree to our</Typography>
                        <Typography sx={{ fontSize: "12px" }}><Typography sx={{ fontSize: "12px", fontWeight: "600" }} component={"span"}>Terms of Service</Typography> and <Typography sx={{ fontSize: "12px", fontWeight: "600" }} component={"span"}>Privacy Policy</Typography></Typography>
                    </Box>
                </Box>
            </Box>
            <DatePickerModal open={open} setOpen={setOpen} value={moment(dob)} onChange={v => {
                if (moment(v).toDate() > moment().toDate()) {
                    return
                }
                setDob(moment(v).toDate())
            }} />
        </Box >
    )
}

