import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { minHeight } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import hit from "../services/apiManager";
import { sendEmail, teams } from "../services/endpoints";
import { getUserDetailThunk } from "../store/auth";
import { setLoading } from "../store/load";
import { getAllTeamThunk } from "../store/team";
import { setShowTeams } from "../store/test";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import { QRCodeCanvas } from "qrcode.react";

export default function CreateGroup() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth.user)
    const [team, setTeam] = useState("")
    const [scoring, setScoring] = useState(true)
    const [createdTeam, setCreatedTeam] = useState(null)
    const submit = async () => {
        if (team.trim() == "") {
            showToast("Enter your team name.", "warning")
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(teams?.createTeam, "post", { name: team, ableToScore: scoring })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Team created sucessfully")
                dispatch(getAllTeamThunk())
                setCreatedTeam(res.data)
                dispatch(getUserDetailThunk(user?._id))
                // navigation.navigate("Addmembers", { grpAccess: res?.data?.accessCode })

            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Create Team</Typography>
                <Box sx={{ display: "flex", px: "10px", minHeight: "400px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "500", fontSize: pxToRem(14) }}>Name your team, choose a sport and add members</Typography>
                    <br />
                    <Input value={team} onChange={v => setTeam(v)} isPass={true} titleStyle={{ textAlign: "left" }} title="Name" />
                    <br />
                    <Typography sx={{ textAlign: "left", fontWeight: "500", fontSize: pxToRem(14) }}>Are you able to Score in group?</Typography>
                    <Box sx={{ display: "flex", marginTop: "10px", marginX: "5px", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CheckBox checked={scoring} onChecked={() => {
                                setScoring(true)
                            }} />
                            <Typography sx={{ fontWeight: "500", marginLeft: "5px", fontSize: pxToRem(14) }}>Yes</Typography>
                        </Box>
                        <Box sx={{ display: "flex", marginLeft: "40px", alignItems: "center" }}>
                            <CheckBox checked={scoring == false} onChecked={() => {
                                setScoring(false)
                            }} />
                            <Typography sx={{ fontWeight: "500", marginLeft: "5px", fontSize: pxToRem(14) }}>No</Typography>
                        </Box>
                    </Box>
                    <br />

                    <br />
                    <Button onClick={() => {
                        // navigate(-1)
                        if(createdTeam==null){
                            submit()
                        }else{
                            navigate(-1)
                        }
                      
                        // dispatch(setShowTeams(true))
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px", marginX: "auto", height: "45px", width: "200px" }} variant="contained">
                        Create
                    </Button>
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>
                {createdTeam != null && <>
                    <Typography sx={{ textAlign: "left", fontWeight: "600" }}>Add Members</Typography>
                    <Box sx={{ display: "flex", minHeight: "400px", px: "10px", py: "20px", marginTop: "10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", }}>
                        <br />
                        <RowComp type="code" team={createdTeam} title={"Add members via Access Code"} />
                        <Box height={"10px"} />
                        <RowComp type="qr" title={"Add members via QR Code"} />
                        <Box height={"10px"} team={createdTeam} />
                        <RowComp type="email" team={createdTeam} title={"Add members via Email"} />
                        <br />
                        <br />

                        <br />
                    </Box>
                </>}
            </Box>
        </Box>
    )
}

const RowComp = ({ title, onClick, team, type }) => {
    const [show, setShow] = useState(false)
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const sendMail = async () => {
        if (email.trim() == "") {
            showToast("Email is reqiured", "warning")
            return
        }
        try {
            dispatch(setLoading(true))
            let res = await hit(sendEmail, "post", { to: email, subject: "Group acess code.", text: team?.accessCode })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Code sent sucessfully.")
                setEmail("")
                navigate(-1)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <>
            <Box onClick={() => {
                setShow(!show)
            }} sx={{ cursor: "pointer", height: "50px", padding: "10px", background: "#0DB1D41A", display: "flex", alignItems: "center", borderRadius: "5px" }}>
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), flex: 1, marginLeft: "5px" }}>{title}</Typography>
                <Box component={"img"} sx={{ height: "10px", width: "10px", rotate: "180deg", objectFit: "contain" }} src="assets/settings/direction.png" />

            </Box>
            {show &&
                (
                    type == "code" ? <Box>
                        <Input rightText={<Box onClick={() => {
                            if (navigator?.clipboard) {
                                navigator.clipboard.writeText(team?.accessCode).then(() => {
                                    showToast('Access code copied to clipboard');
                                    /* Resolved - text copied to clipboard successfully */
                                }, () => {
                                    console.error('Failed to copy');
                                    /* Rejected - text failed to copy to the clipboard */
                                });
                            } else {
                                showToast('Clipboard not supported', "error");

                            }

                        }} sx={{ height: "100%", cursor: "pointer", borderRadius: "10px", px: "10px", background: "rgb(245,185,21)", justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <Typography sx={{ fontSize: pxToRem(14), color: "white" }}>Copy Code</Typography>
                        </Box>} title={"Access Code"} containerStyle={{ marginY: "10px" }} value={team?.accessCode} titleStyle={{ textAlign: "left" }} />
                        <Button onClick={() => {
                            if (navigator.share) {
                                navigator.share({
                                    title: 'Team Access Code',
                                    url: team?.accessCode
                                }).then(() => {
                                    console.log('Thanks for sharing!');
                                })
                                    .catch(console.error);
                            } else {
                                showToast('Share not supported', "error");

                            }
                        }} variant="contained" sx={{ marginY: "10px" }} >
                            Share
                        </Button>
                    </Box> : (
                        type == "email" ?
                            <Box>
                                <Input placeholder={"Type email ...."} title={"Email"} value={email} containerStyle={{ marginTop: "10px" }} onChange={t => setEmail(t)} titleStyle={{ textAlign: "left" }} />
                                <Button onClick={() => {
                                    sendMail()
                                }} variant="contained" sx={{ marginY: "10px" }} >
                                    Send
                                </Button>
                            </Box> :
                            <Box>
                                <QRCodeCanvas
                                    id="qrCode"
                                    value={team?.accessCode}
                                    size={200}
                                    bgColor={"white"}
                                    level={"H"}
                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                />
                            </Box>
                    )
                )
            }
        </>
    )
}

const CheckBox = ({ checked, onChecked }) => {
    return (
        <Box onClick={onChecked} sx={{ height: "15px", cursor: "pointer", width: "15px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "15px", border: "1px solid #0DB1D4" }}>
            {checked &&
                <Box sx={{ height: "10px", width: "10px", borderRadius: "13px", background: "#0DB1D4" }} />
            }
        </Box>
    )
}