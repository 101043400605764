

import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/Home/SearchBar";
import PostComponent from "../components/PostComponent";
import GroupScoreProfile from "../components/ScoreBoard/GroupScoreProfile";
import ScoreCard from "../components/ScoreBoard/ScoreCard";
import ThreeSelectionTab from "../components/threeSelectionTab";
import { pxToRem } from "../theme/typography";
import FriendsComp from "../components/Profile/FriendsComp";
import EventComponent from "../components/EventComponent";
import AddMemberModal from "../components/AddMemberModal";
import { getAllScoreData } from "../store/scores";
import { getAllTeamThunk, setSelectedTeam } from "../store/team";
import { setLoading } from "../store/load";
import hit from "../services/apiManager";
import { image_url, post, scores, subgroups, teams, userDetail } from "../services/endpoints";
import showToast from "../utils/showToast";
import { setInteractionList } from "../store/interactions";
import DeleteModal from "../components/DeleteModal";
import ViewedByModal from "../components/ViewedByModal";
import PostDeniedModal from "../components/PostDeniedModal";
import { setSelectedTab, setSelectGroupStore, setSingleDataStore } from "../store/chat";
import TeamCalendarComp from "./TeamCalendarComp";
import { setToInitalPostData } from "../store/post";

export default function Teams() {
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState(0)
    const [open, setOpen] = useState(false)
    const groups = useSelector(state => state?.team?.team)
    const selectedTeam = useSelector(state => state?.team?.selectedTeam)
    const [search, setSearch] = useState("")
    const [allGroup, setAllGroup] = useState([])
    const [groupDetail, setGroupDetail] = useState(null)
    const [groupScores, setGroupScores] = useState([])
    const [groupPoint, setGroupPoint] = useState(0)
    const [avgPoint, setAvgPoint] = useState(0)
    const userDetails = useSelector(state => state?.auth?.user)
    const [allsubgroups, setAllsubgroups] = useState([])
    const [feed, setFeed] = useState([])
    const [count, setCount] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const interactionList = useSelector(state => state?.interactions?.list)
    const [allInteractions, setallInteractions] = useState([])
    const [yourPoint, setYourPoint] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectDetail, setRejectDetail] = useState(null)
    const [showViewdBy, setShowViewdBy] = useState(false)
    const [viewedByDetail, setViewedByDetail] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [deleteSGroupModal, setDeleteSGroupModal] = useState(false)
    const [showAdminLink, setShowAdminLink] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllScoreData())
        dispatch(getAllTeamThunk())
    }, [])

    useEffect(() => {
        setAllGroup(groups)
    }, [groups])

    useEffect(() => {
        for (let g of groups) {
            if (g?.admins?.includes(userDetails?._id)) {
                setShowAdminLink(true)
                break
            } else {
                setShowAdminLink(false)
            }
        }
    }, [groups])

    useEffect(() => {
        if (selectedTeam == null) {
            dispatch(setSelectedTeam(groups[0]))
        }
    }, [selectedTeam, groups])

    useEffect(() => {
        if (selectedTeam != null) {
            getGroupDetail(selectedTeam?._id)
            getGroupTotalPoint(selectedTeam?._id)
            getGroupFeed(selectedTeam?._id, 1)
        }
    }, [selectedTeam])

    useEffect(() => {
        if (selectedTeam?._id) {
            getAllSubGroup(selectedTeam?._id)
        }
    }, [selectedTeam, groupDetail])

    const getGroupDetail = async (id) => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupDetail(res?.data)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const getGroupTotalPoint = async (id) => {
        try {
            let res = await hit(`${scores?.getscore}?groups=${id}&groupOnly=true`)
            console.log("Group Scores", res.data)
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupScores(res?.data)
            }
        } catch (e) {
        }
    }
    const getAllSubGroup = async (id) => {
        try {
            const res = await hit(`${subgroups?.create}/?parentId=${id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                // if (groupDetail?.createdBy?._id == userDetails?._id) {
                //     setAllsubgroups(res?.data)
                // } else {
                    let newArr = res?.data?.filter(x => x?.createdBy == userDetails?._id)
                    setAllsubgroups(newArr)
                // }
            }
        } catch (e) {
        } finally {
        }
    }
    useEffect(() => {
        if (search?.length == 0) {
            setAllGroup(groups)
        }
    }, [search])
    useEffect(() => {
        setallInteractions(interactionList)
    }, [interactionList])

    useEffect(() => {
        let totalPoint = 0
        let temp = [...groupScores]
        temp.forEach((i, j) => {
            if (i?.type == 4) {
                totalPoint = +i?.point
            }
        })
        feed.forEach(p => {
            if (p?.isScorePost) {
                if (p?.scoreBy?.includes("likes")) {
                    totalPoint += p?.scorePoint
                }
                if (p?.scoreBy?.includes("verification")) {
                    totalPoint += (p?.scorePoint1 * p?.scoreCount)
                }
                if (p?.scoreBy?.includes("geo")) {
                    totalPoint += p?.scorePoint2
                }
            }
        })
        setGroupPoint(totalPoint)
    }, [groupScores, feed])
    const deleteGroup = async (id) => {
        try {
            setDeleteSGroupModal(false)
            dispatch(setLoading(true))
            const res = await hit(`${teams.createTeam}/${id}`, "patch", { active: 0 })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Group deleted successfully")
                dispatch(getAllTeamThunk(() => {
                    dispatch(setSelectedTeam(null))
                }))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const scoress = useSelector(state => state.scores)?.list

    useEffect(() => {
        let c = 0
        let members = groupDetail?.members
        let admins = groupDetail?.admins
        let ableToScores = groupDetail?.ableToScores
        for (let i of groupScores) {
            c += i?.point
        }
        if (isAdmin) {
            let memb = (members.length + ableToScores?.length - admins.length)
            if (memb == 0) {
                setAvgPoint(c)
            }
            if (memb > 0) {
                let avg = Math.round(c / memb)
                setAvgPoint(avg)
            }
        } else {
            let point = 0
            let yourPoints = groupScores?.filter(x => x?.user?._id == userDetails?._id)
            yourPoints.forEach((i, j) => {
                point = point + i?.point
            })
            setYourPoint(point)
        }
    }, [groupDetail, groupPoint, isAdmin, groupScores, scoress])

    useEffect(() => {
        if (groupDetail && userDetails) {
            let admins = groupDetail?.admins?.map(x => x._id)
            if (admins?.includes(userDetails?._id)) {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        }
    }, [groupDetail, userDetails])
    const searchGroup = (key) => {
        let arr = groups
        let temp = arr.filter(x => String(x?.name)?.trim().toLowerCase().includes(key?.trim().toLowerCase()))
        setTimeout(() => {
            setAllGroup(temp)
        }, 400);
    }

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        console.log(tmp)
        return tmp;
    }
    const getGroupFeed = async (id, pg) => {
        try {
            let res = await hit(`${post?.post}?groups=${id}&page=${pg}&populate=likes,user,viewsBy,groups.members,pollSelected.user`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setFeed(res?.data?.results)
                setTotalPage(res?.data?.totalPages)
                setCount(1)
            }
        } catch (e) {
        } finally {
        }
    }
    const refreshGroupFeeds = async (id, pg) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}?groups=${id}&page=${pg}&populate=likes,user,viewsBy,groups.members,pollSelected.user`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setFeed([...feed, ...res?.data?.results])
            }
        } catch (e) {
        } finally {
            setCount(pg)
            dispatch(setLoading(false))
        }
    }
    const viewPost = async (id) => {
        console.log(id, "viewPost")
        try {
            let res = await hit(`${post?.viewPost}/${id}`, "patch")
            console.log(res);
        } catch (e) {
        } finally {
        }
    }
    const likePost = async (id) => {
        try {
            let res = await hit(`${post?.post}/${id}/like`, "post")
            console.log(res);
            if (res?.err) {
                showToast(res?.msg, "error")
            }
        } catch (e) {
        } finally {
        }
    }

    const deletePost = async (id) => {
        setShowDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Post deleted successfully")
                getGroupFeed(selectedTeam?._id, count)
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }

    const deleteF = (id) => {
        setDeleteId(id)
        setTimeout(() => {
            setShowDeleteModal(true)
        }, 700);
    }
    const votePoll = async (selected, id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(post?.poll, "post", { "postId": id, "selected": selected })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                getGroupFeed(selectedTeam?._id, count)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const approve = async (id, approve, ind) => {
        if (approve == 2) {
            setShowRejectModal(true)
            setRejectDetail(ind)
            return
        }
        try {
            let res = await hit(`${post.postApproval}/${id}`, "patch", { isApproved: approve })
        } catch (e) {
        } finally {
        }
    }
    const rejectPost = async (detail, reason) => {
        let temp = [...feed]
        let index = temp.indexOf(detail)
        temp.splice(index, 1)
        setFeed(temp)
        try {
            let res = await hit(`${post.postApproval}/${detail?._id}`, "patch", { isApproved: 2, reason: reason.trim() })
        } catch (e) {
        } finally {
        }
    }

    function componentDidMount(id) {
        if (navigator.geolocation) {
            dispatch(setLoading(true))
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let myloc = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    getAddress(position?.coords, id, myloc)
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log("errorrrr===>>>", error.code, error.message);
                }
            );
        }
    }

    function getAddress(cords, id, myloc) {
        fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            (cords?.latitude ?? 0.0) +
            ',' +
            (cords?.longitude ?? 0.0) +
            '&key=' +
            'AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q',
        )
            .then(async res => {
                let resjson = await res.json();
                if (resjson.results.length > 0) {
                    let addressComponent = resjson.results[0].address_components;
                    console.log(addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name);
                    let address = addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name
                    interactPost(id, address, myloc)
                }
            })
            .catch(error => console.log('results error => ', error.message));
    }
    const interactPost = async (id, add, myloc) => {
        if (myloc == null) {
            dispatch(setLoading(false))
            return
        }
        try {
            let res = await hit(post?.interact, "post", {
                "postId": id,
                "last_checkin_location": {
                    "lat": myloc?.latitude,
                    "lng": myloc?.longitude,
                    "address": add
                },
            })
            if (res?.err) {
                showToast(res?.msg, "error")
                dispatch(setLoading(false))
            } else {
                getuserInteractions()
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))

        }
    }
    const getuserInteractions = async () => {
        try {
            let res = await hit(`${post?.interact}?user=${userDetails?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                dispatch(setInteractionList(res?.data))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    const [showAll, setShowAll] = useState(false)

    const removeMem = async (id,gId) => {
        try {
            dispatch(setLoading(true))
            let res = await hit("/teams/removeMember", "post", { userId: id, groupId: gId})
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                showToast("Member removed successfully.")
                
            }
        } catch (e) {
        } finally {
            getGroupDetail(selectedTeam?._id)
            dispatch(setLoading(false))
            dispatch(getAllTeamThunk())
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", flexDirection: "column", pr: "10%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Box onClick={() => {
                    navigate("/user/join")
                }} sx={{ display: "flex", flexDirection: "column", cursor: "pointer", flex: 1, justifyContent: "center", alignItems: "center", height: "50px", background: "#F3FDFF", border: "1.5px solid #0BB2D04D" }}>
                    <Typography sx={{ fontWeight: "600" }}>Join Team/Group</Typography>
                </Box>
                <Box onClick={() => {
                    navigate("/user/create")
                }} sx={{ flex: 1, display: "flex", flexDirection: "column", cursor: "pointer", marginLeft: "4%", justifyContent: "center", alignItems: "center", height: "50px", background: "#F3FDFF", border: "1.5px solid #0BB2D04D" }}>
                    <Typography sx={{ fontWeight: "600" }}>Create Team</Typography>
                </Box>
            </Box>
            <br />
            {groups?.length == 0 && <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", py: "50px", width: "50%", marginX: "auto", marginBottom: "20px", boxShadow: "0px 5px 20px #26262626", marginTop: "25px", borderRadius: "5px", background: "white", }}>
                <Box component={"img"} src="assets/teams/conts.png" sx={{ width: "60px", height: "60px", marginBottom: "20px", textAlign: "center" }} />
                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(16) }}>Get started with a Team/Group</Typography>
                <Typography sx={{ fontSize: pxToRem(14) }}>Tap buttons to create or join a team/group</Typography>
            </Box>}

            {groups?.length > 0 &&
                <Box sx={{ display: "flex", width: "100%", }}>
                    <Box sx={{ flex: 1, minHeight: "100px" }}>
                        <SearchBar onClickSearch={() => {
                            searchGroup(search)
                        }} value={search} onChange={v => setSearch(v)} placeholder={"Search...."} />
                        <br />
                        <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "600" }}>Groups</Typography>
                                {/* <Typography sx={{ fontSize: pxToRem(13) }}>View All</Typography> */}
                            </Box>
                            {showAdminLink && <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography sx={{ alignSelf: "flex-start", mb: "5px" }} component={"a"} target="_blank" href="https://admin.ourculturescore.com/" >Click here to go to admin panel</Typography>
                            </Box>}
                            {/* <br /> */}
                            {splitArray(allGroup, 2)?.map((items, index) => {
                                return (
                                    <Box sx={{ display: "flex", marginBottom: "15px", justifyContent: "space-between", alignItems: "center" }}>
                                        {items?.filter(x=>String(x?.name).toLowerCase()?.includes(String(search).toLowerCase()))?.map((item, index1) => <ScoreCard isTeam={true} item={item} onSelect={() => {
                                            dispatch(setSelectedTeam(item))
                                        }} style={{ marginLeft: index1 != 0 ? "20px" : "0px" }} title={item?.name} />)}
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                        <Box sx={{ height: "20px" }} />
                    </Box>
                    <Box sx={{ flex: 1, marginLeft: "4%" }}>
                        {selectedTeam != null && <>
                            <GroupScoreProfile isAdmin={isAdmin} avgPoint={avgPoint} yourPoint={yourPoint} team={selectedTeam} />
                            <br />
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Button onClick={() => {

                                    dispatch(setSelectedTab(1))
                                    dispatch(setSelectGroupStore(groupDetail))
                                    navigate("/user/chat")
                                }} startIcon={<Box sx={{ height: "15px", width: "15px" }} component={"img"} src="assets/home/chat.png" ></Box>} variant="contained" sx={{ height: "45px", flex: 1 }}>Group Chat</Button>
                                <Button onClick={() => {
                                    setOpen(true)
                                }} variant="contained" sx={{ height: "45px", flex: 1, marginLeft: "20px", background: "#F5BA16" }}><span style={{ fontSize: pxToRem(20) }}>+</span>&nbsp;Add Members</Button>
                            </Box>
                            {
                                isAdmin && <>
                                    <br />
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Button onClick={() => {
                                            navigate("/user/sub", { state: { group: groupDetail } })
                                        }} variant="outlined" sx={{ height: "45px", flex: 1 }}>Create SubGroup</Button>
                                        <Button onClick={() => {
                                            navigate("/user/manual", { state: { gid: selectedTeam?._id } })
                                        }} variant="outlined" sx={{ height: "45px", flex: 1, marginLeft: "20px" }}>Manual Submission</Button>
                                    </Box>
                                </>}
                            <br />
                            <Box sx={{ display: "flex" }}>
                                <Button onClick={() => {
                                    dispatch(setToInitalPostData())
                                    navigate("/user/post1",{state:{groupDetail:groupDetail,isAdmin}})
                                }} variant="contained" sx={{ height: "45px", flex: 1, marginBottom: "10px" }}>Post</Button>
                            </Box>
                            {isAdmin && <Box display={"flex"}>
                                <Button onClick={() => {
                                    setDeleteSGroupModal(true)
                                }} color="error" variant="outlined" sx={{ height: "45px", flex: 1 }}>Delete Group</Button>
                            </Box>}

                            {allsubgroups?.length > 0 && <>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "600" }}>SubGroups</Typography>
                                    <Typography onClick={() => {
                                        // navigate("/user/subGroup", { group: groupDetail })
                                        setShowAll(!showAll)
                                    }} sx={{ cursor: "pointer", fontSize: pxToRem(12) }}>{showAll ? "Hide All" : "View All"}</Typography>

                                </Box>
                                {splitArray(allsubgroups, 2).map((items, index) => {
                                    if (!showAll && index > 0) {
                                        return null
                                    }
                                    return <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                                        {items.map((item, indexj) => {
                                            let points = 0
                                            let temp = [...groupScores]
                                            let arr = []
                                            item?.members?.forEach(m => {
                                                temp.forEach(e => {
                                                    if (e?.user?._id == m) {
                                                        arr.push(e)
                                                    }
                                                })
                                            })
                                            arr.forEach(e => {
                                                points += e?.point
                                            })
                                            points = Math.round((points / (item?.members)?.length))
                                            return (
                                                <SubComponent points={points} subGroup={item} title={item?.name} />
                                            )
                                        })}
                                    </Box>
                                })}

                                <br />
                            </>
                            }

                            <ThreeSelectionTab style={{ background: "white" }} setCurrentTab={setCurrentTab} t1="Feed" t2="Members" t3={"Calendars"} />
                            {currentTab == 0 &&
                                <>
                                    {feed?.map((i, j) => {
                                        let isCheckedin = "in"
                                        let temp = interactionList?.filter(x => x?.postId == i?._id)
                                        if (temp?.length > 0) {
                                            if (temp[0]?.current_status == "in") {
                                                isCheckedin = "out"
                                            } else {
                                                isCheckedin = "in"
                                            }
                                        }
                                        return (
                                            <PostComponent
                                                i={{ ...i, groups: [groupDetail] }}
                                                j={j}
                                                containerStyle={{ marginTop: "20px" }}
                                                onPressProfile={() => {
                                                    viewPost(i?._id)
                                                    if (userDetails?._id == i?.user?._id) {
                                                        // navigation?.navigate("Profile")
                                                        return
                                                    }
                                                    // navigation?.navigate("FriendsProfile", { user: i?.user?._id, team: "noteam" })
                                                }}
                                                postLiked={liked => { likePost(liked); viewPost(i?._id) }}
                                                onPressDelete={id => {
                                                    viewPost(i?._id)
                                                    deleteF(id)
                                                }}
                                                onPressComment={(groupId) => {
                                                    viewPost(i?._id)
                                                    navigate("/user/comments", { state: { id: i?._id, from: 'home' ,groupId} })
                                                }}
                                                onPressPost={(data) => {
                                                    viewPost(i?._id)
                                                    if (data?.type == "photo") {
                                                        window.open(image_url + data?.img, "_blank")
                                                    } else {
                                                        let ind = i?.thumbnails?.indexOf(data?.img)
                                                        let vid = i?.videos[ind]
                                                        window.open(image_url + vid, "_blank")
                                                    }
                                                }}
                                                selectPoll={(selected) => {
                                                    viewPost(i?._id)
                                                    votePoll(selected, i?._id)
                                                }}
                                                onPressApprove={() => {
                                                    viewPost(i?._id)
                                                    approve(i?._id, 1)
                                                }}
                                                onPressReject={() => {
                                                    approve(i?._id, 2, i)
                                                }}
                                                onPressCheck={() => {
                                                    viewPost(i?._id)
                                                    componentDidMount(i?._id)
                                                }}
                                                onPressInteraction={() => {
                                                    viewPost(i?._id)
                                                    if (i?.verifyBy?.includes(userDetails?._id)) {
                                                        navigate("/user/interaction", { state: { postid: i?._id } })
                                                    }
                                                }}
                                                isCheckedin={isCheckedin}
                                                groupDetail={groupDetail}
                                                onPressViewed={(members, grpId) => {
                                                    viewPost(i?._id)
                                                    setSelectedGroup(grpId)
                                                    setTimeout(() => {
                                                        setShowViewdBy(true)
                                                        setViewedByDetail({ viewed: i?.viewsBy, memb: members })
                                                    }, 400);
                                                }}
                                            />
                                        )
                                    })}
                                    {totalPage > count && <Button onClick={() => {
                                        refreshGroupFeeds(selectedTeam?._id, count + 1)
                                    }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                                        Load More
                                    </Button>}
                                </>
                            }
                            {currentTab == 1 &&
                                <FriendsComp onChatClick={(user) => {
                                    dispatch(setSelectedTab(0))
                                    dispatch(setSingleDataStore({
                                        group: groupDetail?._id,
                                        groupDetails: groupDetail,
                                        user: user
                                    }))
                                    navigate("/user/chat")
                                }} members={groupDetail?.members} removeMem={removeMem} groupDetail={groupDetail} admins={groupDetail?.admins} />
                            }
                            {currentTab == 2 &&
                                <Box sx={{ boxShadow: "0px 5px 20px #26262626", p: "10px", borderRadius: "5px", background: "white", marginTop: "20px" }}>
                                    <TeamCalendarComp group={groupDetail} />
                                </Box>
                            }
                            <Box sx={{ height: "20px" }} />
                        </>}
                    </Box>

                </Box>
            }
            <DeleteModal
                title={"You want to delete this group?"}
                open={deleteSGroupModal}
                handleClose={() => {
                    setDeleteSGroupModal(false)
                }}
                onPressYes={() => deleteGroup(selectedTeam?._id)}
                onPressNo={() => setDeleteSGroupModal(false)}
                onPressOut={() => setDeleteSGroupModal(false)}
            />
            <AddMemberModal selectedTeam={selectedTeam} open={open} setOpen={setOpen} />
            <DeleteModal
                open={showDeleteModal}
                handleClose={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressNo={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressYes={() => {
                    deletePost(deleteId)
                }}
            />

            <DeleteModal
                open={showDeleteModal}
                handleClose={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressNo={() => {
                    setShowDeleteModal(false)
                    setDeleteId("")
                }}
                onPressYes={() => {
                    deletePost(deleteId)
                }}
            />

            <ViewedByModal
                open={showViewdBy}
                arr={viewedByDetail}
                setOpen={setShowViewdBy}
                selectedGroup={selectedGroup}
                onPressCancel={() => {
                    setShowViewdBy(false)
                }}

            />
            <PostDeniedModal
                open={showRejectModal}
                setOpen={setShowRejectModal}
                outPressed={() => setShowRejectModal(false)}
                onPressOk={(t) => {
                    setShowRejectModal(false)
                    rejectPost(rejectDetail, t)
                }}
            />
        </Box>
    )
}

const SubComponent = ({ title, points, subGroup }) => {
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    return (
        <Box onClick={() => {
            navigate("/user/subGroup", { state: { subGroup } })
        }} sx={{ width: "47%", padding: "10px", cursor: "pointer", borderRadius: "5px", textAlign: "left", background: "white", boxShadow: "0px 5px 20px #26262626" }}>
            <Typography sx={{ fontSize: pxToRem(18), fontWeight: "600" }}>{title}</Typography>
            <Typography sx={{ fontSize: pxToRem(14), marginY: "7px", fontWeight: "400" }}>Average Points</Typography>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography color={"primary"} sx={{ fontSize: pxToRem(22), fontWeight: "600" }}>{show ? points : "****"}</Typography>
                <Box onClick={(e) => {
                    setShow(!show)
                    e.stopPropagation()
                }} sx={{ height: "20px", width: "20px", cursor: "pointer" }} component="img" src={show ? "assets/login/eye.svg" : "assets/signup/eye-off.svg"} />
            </Box>
        </Box>
    )
}