import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import hit from "../services/apiManager";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";

export default function ChangePassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [newp, setNewp] = useState("")
    const [conp, setConp] = useState("")

    const submit = async () => {
        if (newp != conp) {
            showToast("Password does not match.","warning")
            return
        }
        try {
            dispatch(setLoading(true))
            const res = await hit("/users/self", "patch", { password: newp })
            if (res?.err) {
                showToast(res?.msg,"error")
            } else {
                showToast("Password reset successfully")
                navigate(-1)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <Typography sx={{textAlign:"left",fontWeight:"600"}}>Change Password</Typography>
                <Box sx={{ display: "flex", px: "10px", py: "20px",marginTop:"10px", flexDirection: "column", background: "white", boxShadow: "0px 5px 20px #26262626", borderRadius: "5px", minHeight: "100px" }}>
                <Typography sx={{textAlign:"left",fontSize:pxToRem(14)}}>Please enter your new password below</Typography>
                <br />
                   <Input  isPass={true}  value={newp} onChange={v=>setNewp(v)} titleStyle={{ textAlign: "left" }} title="New Password"   img={"assets/login/eye.svg"}  />
                    <br />
                    <Input  isPass={true} value={conp} onChange={v=>setConp(v)}   titleStyle={{ textAlign: "left" }} title="Confirm Password"   img={"assets/login/eye.svg"}  />
                    <br />
                    <br />
                    <Button onClick={() => {
                        submit()
                    }} sx={{ fontSize: pxToRem(16), borderRadius: "5px",marginX:"auto", height: "45px", width: "200px" }} variant="contained">
                        Confirm
                    </Button>
                    <br />
                </Box>
            </Box>
            <Box sx={{ flex: 1, marginLeft: "5%", }}>

            </Box>
        </Box>
    )
}