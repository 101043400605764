import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Input from "../components/Login/Input";
import SocialButton from "../components/Login/SocialButton";
import UserTypeSelect from "../components/Login/UserTypeSelect";
import { pxToRem } from "../theme/typography";
import '../css/signup.css';
import hit from "../services/apiManager";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/load";
import showToast from "../utils/showToast";
import { authorize } from "../store/auth";
import { storeData } from "../services/asyncStore";
import { auth as authapi } from '../services/endpoints'
import { useState } from "react";
import { firebaseAuth } from "../utils/firebaseInit";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [userType, setUserType] = useState("User")
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")

    const submit = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit(authapi.login, "post", { "email": email, "password": pass, "role": userType.toLowerCase(), "os": "web" })
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                if ((res?.data?.user)?.isEmailVerified == false) {
                    storeData("@tokens", res?.data?.tokens)
                    navigate("/otp", { state: { from: "Login", user: res?.data?.user } })
                    return
                } else {
                    storeData("@tokens", res?.data?.tokens)
                    dispatch(authorize({ user: res?.data?.user }))
                    res?.data?.user?.role == "user" ? navigate("/user/home") : navigate("/user/home")
                }
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const googleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            signInWithPopup(firebaseAuth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    let f_name = user?.displayName?.split(" ")[0] ?? ""
                    let l_name = user?.displayName?.split(" ")[1] ?? ""
                    if (user?.email) {
                        socialLogin(user?.email, "google", token, f_name, l_name)
                    }
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    showToast(error.message, "error")
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });

        } catch (err) {

        } finally {

        }

    }
    const appleLogin = async () => {
        try {
            const provider = new OAuthProvider("apple.com");
            provider.addScope('email');
            provider.addScope('name');
            signInWithPopup(firebaseAuth, provider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = OAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    const idToken = credential.idToken;
                    // The signed-in user info.
                    const user = result.user;
                    let f_name = user?.displayName?.split(" ")[0] ?? ""
                    let l_name = user?.displayName?.split(" ")[1] ?? ""
                    if (user?.email) {
                        socialLogin(user?.email, "apple", token, f_name, l_name)
                    }
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    showToast(error.message, "error")
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });

        } catch (err) {

        } finally {

        }

    }

    const socialLogin = async (email, loginType, token, firstName, lastName) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(authapi.socialLogin, "post", { email, loginType, token, firstName, lastName, "role": userType.toLowerCase(), "os": "web" })
            if (res?.err) {
                showToast(res?.msg, "erroe")
            } else {
                storeData("@tokens", res?.data?.tokens)
                dispatch(authorize({ user: res?.data?.user }))
                res?.data?.user?.role == "user" ? navigate("/user/home") : navigate("/user/home")
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <Box sx={{ width: "100vw", position: "relative", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Box component={"img"} src="assets/login/fullback.png" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box component={"img"} src="assets/login/fullback1.svg" sx={{ width: "100vw", position: "absolute", top: 0, height: "100vh", objectFit: "cover" }} />
            <Box className="noScrollBar" sx={{ width: { xs: "90vw", md: "40vw" }, overflowY: "auto", minWidth: { md: "550px" }, borderRadius: "10px", position: "relative", backgroundImage: "linear-gradient(150deg,rgb(212,241,247), white ,rgb(254,243,214))" }}>
                <Box sx={{ height: "100%", width: "100%", paddingY: "7%", paddingX: { xs: "5%", md: "12%" }, position: "relative" }}>
                    <Box component={"img"} src="assets/logo.png" sx={{ width: { md: "200px", sm: "200px" }, mx: "auto", height: "60px", objectFit: "contain" }} />
                    <Typography sx={{ fontSize: pxToRem(26), fontWeight: "700", marginTop: "10px" }}>Welcome!</Typography>
                    <Typography sx={{ fontSize: pxToRem(16), fontWeight: "500", marginTop: "4px" }}>Please enter the email address & password to login into your account</Typography>
                    <br />
                    <UserTypeSelect type={0} onSelect={v => {
                        setUserType(v)
                    }} />
                    <br />
                    <Box sx={{ display: "flex", alignItems: "center", px: "10%" }}>
                        <SocialButton title={"Login with Google"} onPress={() => {
                            googleSignIn()
                        }} img={"assets/login/google.png"} />
                        <SocialButton onPress={()=>{
                            appleLogin()
                        }} img={"assets/login/apple.png"} title={"Login with Apple"} textStyle={{ color: "#F5BA16" }} style={{ background: "#FEBE2D17", marginLeft: "10px" }} />
                    </Box>
                    <Typography sx={{ fontSize: pxToRem(12), fontWeight: "600", mb: "10px", mt: "20px" }}>Or Log In Using Email</Typography>
                    <Box sx={{ width: "90%", marginX: "auto" }}>
                        <Input value={email} onChange={v => setEmail(v)} titleStyle={{ textAlign: "left" }} title="Email" img={"assets/login/mail.svg"} />
                        <br />
                        <Input value={pass} onChange={v => setPass(v)} isPass={true} titleStyle={{ textAlign: "left" }} title="Password" img={"assets/login/eye.svg"} />
                        <Typography onClick={() => {
                            navigate("/forgot")
                        }} sx={{ fontSize: "12px", fontStyle: "italic", textAlign: "right", marginTop: "5px", fontWeight: "600" }}>Forgot password?</Typography>
                        <Button onClick={() => {

                            submit()
                        }} sx={{ borderRadius: "5px", height: "50px", marginTop: "16px", width: "80%" }} variant="contained" >
                            Login
                        </Button>
                        <Typography onClick={e => {
                            navigate("/signup")
                        }} sx={{ fontSize: "12px", fontWeight: "600", my: "5px" }}>Don't have an account? <Typography component={"span"} color="primary" sx={{ fontSize: "14px", fontWeight: "600" ,cursor:"pointer"}}>Sign Up</Typography></Typography>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

