import './App.css';
import { Provider } from 'react-redux'
import ThemeProvider from './theme';
import MainRoute from './routes/main';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import CustomToast from './components/toast';
import Loader from './components/Loader';
import { getFirebaseToken, messaging } from './utils/firebaseInit';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box ,Typography} from '@mui/material';
import { onMessage } from 'firebase/messaging';
import { getAllNotifications } from './store/notification';
import 'rsuite/dist/rsuite.min.css'
import ScrollToTop from './components/ScrollToTop';

// const isSupported=()=>'Notification' in 

function App() {
  const [showNotificationBanner, setShowNotificationBanner] = useState(window?.Notification?.permission === 'default');

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log('Firebase token: ', firebaseToken);
        if (firebaseToken) {
          setShowNotificationBanner(false);
        }
      })
      .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
  }
  const ToastifyNotification = ({ title, body }) => (
    <Box>
      <Box component={"img"} src="assets/logo.png" sx={{height:"30px"}}></Box>
      <Typography sx={{fontSize:"18px",fontWeight:"600",textAlign:"left"}}>{title}</Typography>
      <Typography sx={{fontSize:"14px",fontWeight:"500",textAlign:"left"}}>{body}</Typography>
    </Box>
  );

  useEffect(() => {
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log('Firebase token: ', firebaseToken);
      })
      .catch((err) => console.error('An error occured while retrieving firebase token. ', err))

    onMessage(messaging, (payload) => {
      store.dispatch(getAllNotifications())
      const { notification: { title, body } } = payload;
      toast(<ToastifyNotification title={title} body={body} />);
    })
  }, [])

  return (
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store} >
        <ThemeProvider>
          <ScrollToTop/>
          <div className="App">
            {showNotificationBanner && <Box sx={{ background: "#E1F1F7" }} className="notification-banner">
              <span>The app needs permission to </span>
              <a
                href="#"
                className="notification-banner-link"
                onClick={handleGetFirebaseToken}
              >
                enable push notifications.
              </a>
            </Box>}
            <MainRoute />
            <ToastContainer hideProgressBar />
          </div>
        </ThemeProvider>
        <CustomToast />
        <Loader />
      </Provider>


    </PersistGate>
  );
}

export default App;
